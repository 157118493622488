/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：用户信息管理
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */

import api from '@/network/user.js';//用户相关接口
import customerApi from '@/network/crm/crm.js';//客户相关接口
import supplierApi from '@/network/datum/supplier.js';//供应商相关接口

export default {
  //用户相关
  getUserInfo({//获取登录的用户信息
    commit
  }) {
    return new Promise((resolve, reject) => {
      api.user.getUserInfo().then(res => {
        if (res.code == 200) {
          commit("USERINFO", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },

  //用户权限
  getUserPowerInfo({//获取当前登录的用户权限
    state,
    commit
  }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: "POST",
        url: backUrl + "power_getSysPowerControlById.action",
        async: false,
        data: {
          token: token,
          upid: state.UserInfo.user_id
        },
        success: function(res) {
          let modelIdsArr = []
          let powerArr = []
          if (res) {
            res.forEach(item => {
              if (item.model_ids) {
                modelIdsArr = item.model_ids.split("@@")
                modelIdsArr.forEach(power => {
                  if (item.model_ids) {
                    power.split("##")[1].split(",").forEach(operation => {
                      powerArr.push({
                        parentLevel: item.function_type, //一级
                        childrenLevel: power.split("##")[0], //二级
                        power: operation, //增删改查等权限
                        departmentType: power.split("##")[2], //部门类型
                        departmentId: power.split("##")[3] //部门id
                      })
                    })
                  }
                })
              }
            })
            commit("USER_POWER_INFO", powerArr);
            resolve(powerArr);
          } else {
            console.log("权限信息获取失败!")
          }
        },
      })
    })
  },


  //登录信息相关
  getLoginCustomerInfo({//获取登录客户信息
    state,commit
  }) {
    return new Promise((resolve, reject) => {
      //定义请求数据
      let data = {
        customerId:state.LoginCustomerId
      }
      //发送请求
      customerApi.findCustomerById(data).then(res => {
        if (res.code == 200) {
          commit("LOGIN_CUSTOMER_INFO", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },

  getLoginSupplierInfo({//获取登录供应商信息
    state,commit
  }) {
    return new Promise((resolve, reject) => {
      //定义请求数据
      let data = {
        supplierId:state.LoginSupplierId
      }
      //发送请求
      supplierApi.findSupplierById(data).then(res => {
        if (res.code == 200) {
          commit("LOGIN_SUPPLIER_INFO", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },

}
