// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：icon图标公用页面倒出
// 开始时间：2021-1-23
// 开发人员：刘巍骏
// 最后修改：2021-1-23
// 备注说明：如需修改请联系开发人员

import IconFont from './icons.vue'
export default IconFont