// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：生产相关vuex信息
// 开始时间：2021-05-18
// 开发人员：刘巍骏
// 最后修改：2021-05-18
// 备注说明：如需修改请联系开发人员

import mutations from "./mutations.js"
import actions from "./actions.js"

const state={
  // 生产计划单相关
  pro_detail_tab_index:1,//生产单详情tab页下标
}


export default {
  state,
  mutations,
  actions
}
