/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：系统管理·mutation
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */
const mutation = {
  /* 数据字典左侧导航栏下标*/
  DICTIONARY_LEFT_INDEX(state, value) {
    state.DictionaryLeftIndex = value;
  },
  // 客户相关
  CUSTOMER_CLASSIFY(state, value) { //客户分类
    state.CustomerClassfiy = value;
  },
  CUSTOMER_STATE(state, value) { //客户状态
    state.CustomerState = value;
  },
  CUSTOMER_SOURCE(state, value) { //客户来源
    state.CustomerSource = value;
  },
  CUSTOMER_RELATION(state, value) { //客户关系
    state.CustomerRelation = value;
  },
  CUSTOMER_PROCESS(state, value) { //销售进程
    state.CustomerProcess = value;
  },
  CUSTOMER_MEMBER(state, value) { //会员等级
    state.CustomerMember = value;
  },
  CUSTOMER_FOLLOWUPWAY(state, value) { //跟进方式
    state.CustomerFollowupWay = value;
  },
  CUSTOMER_TAB_INDEX(state, value) { //客户分类选项卡选中下标
    state.CustomerTabIndex = value;
  },

  //仓储相关
  LOGISTICS_COMPANY(state, value) { //物流公司
    state.LogisticsCompany = value;
  },
  LOGISTICS_MODE(state, value) { //物流方式
    state.LogisticsMode = value
  },
  WAREHOUSE_CONFIG(state, value) { //仓库配置
    state.WarehouseConfig = value
  },
  LOGISTICS_DELICERYTYPE(state, value) { //送货方式
    state.DeliveryType = value;
  },
  LOGISTICS_QUALITYTYPE(state, value) { //质检类型
    state.QualityType = value;
  },
  LOGISTICS_TESTWAY(state, value) { //检验方式
    state.TestWay = value;
  },
  DYPEOFDOCUMENT(state, value) { //单据类型
    state.DypeofDocument = value;
  },
  STORAGE_TAB_INDEX(state, value) { //仓储相关选项卡选中下标
    state.StorageTabIndex = value;
  },

  //财务相关
  FINANCE_CURRENCY(state, value) { //交易币种
    state.FinanceCurrency = value
  },
  FINANCE_PAYMENTTYPE(state, value) { //收款类型
    state.PaymentType = value
  },
  FINANCE_COSTBANKINFO(state, value) { //收款账号
    state.SysCostBankInfo = value
  },
  FINANCE_COSTTYPE(state, value) { //费用分类
    state.FinanceCostType = value
  },
  FINANCE_COSTINVOICETYPE(state, value) { //发票类型
    state.CostInvoiceType = value
  },
  FINANCE_TAB_INDEX(state, value) { //财务相关选项卡选中下标
    state.FinanceTabIndex = value
  },

  //生产相关
  PRODUCTION_BOMTYPE(state, value) { //BOM分类
    state.ProductionBomType = value
  },
  PRODUCTION_PROCESSTYPE(state, value) { //工序类型
    state.ProcessType = value
  },
  PRODUCTION_COSTTYPE(state, value) { //计费方式
    state.CostType = value
  },
  PRODUCTION_MANUFACTURETYPE(state, value) { //生产类型
    state.ManufactureType = value
  },
  PROCESS_UNIT(state, value) { //加工单位
    state.ProcessUnit = value
  },
  PRODUCTION_TAB_INDEX(state, value) { //生产相关选项卡下标
    state.ProductionTabIndex = value
  },

  //供应商
  SUPPLIER_SUPPLIERTYPE(state, value) { //分类管理
    state.SupplierType = value
  },
  SUPPLIER_MEMBERSHIP(state, value) { //会员等级
    state.MemberShip = value
  },
  SUPPLIER_TAB_INDEX(state, value) { //供应商相关选项卡下标
    state.SupplierTabIndex = value
  },

  //货币相关
  PRODUCT_CLASSFIY(state, value) { //货品分类
    state.ProductClassfiy = value
  },
  PRODUCT_UNIT(state, value) { //计量单位
    state.ProductUnit = value
  },
  PRODUCT_BRAND(state, value) { //品牌管理
    state.Brand = value
  },
  PRODUCT_PROPERTIES(state, value) { //货品属性
    state.ProductProperty = value
  },
  PRODUCT_SOURCE(state, value) { //货品来源
    state.ProductSource = value
  },
  PRODUCT_TAB_INDEX(state, value) { //货币相关选项卡选中下标
    state.ProductTabIndex = value
  },
  PRODUCT_SPECIFICATION(state, value) { //货品规格名称
    state.ProductSpecification = value
  },

  /* 销售相关*/
  SALES_ORDERDOCUMENTTYPE(state, value) { //单据类型
    state.OrderDocumentType = value
  },
  SALES_ORDERTYPE(state, value) { //报价类型
    state.OrderType = value
  },
  SALES_SETTLEMENT(state, value) { //结算方式
    state.OrderSettlement = value
  },
  PURCHASETYPE(state, value) { //采购类型
    state.PurchaseType = value
  },
  PRICECLAUSE(state, value) { //价格条款
    state.PriceClause = value
  },
  PAYMENTMODE(state, value) { //付汇方式
    state.PaymentMode = value
  },
  SALES_TAB_INDEX(state, value) { //销售相关选项卡选中下标
    state.SalesTabIndex = value
  },

  /* 单据类型相关 */
  BILLS_TEMPLATE_DATA(state, value) {
    state.billTemplateData = value
  },
  SALE_BILL_TYPE(state, value) { //销售单据类型
    state.saleBillType = value
  },
  PURCHASE_BILL_TYPE(state, value) { //采购单据类型
    state.purchaseBillType = value
  },
  PRODUCT_BILL_TYPE(state, value) { //生产单据类型
    state.productBillType = value
  },
  DEPOT_BILL_TYPE(state, value) { //库存单据类型
    state.depotBillType = value
  },
  COST_BILL_TYPE(state, value) { //资金单据类型
    state.costBillType = value
  },

  /* 节点设置相关 */
  NODE_CLASSFIY_DATA(state, value) { //节点分类相关信息
    state.NodeClassfiyData = value
  },
  NODECLASSFIY(state, value) { //节点分类所有数据
    state.NodeClassfiy = value
  },
  CONTROL_COMMIT_NODE(state, value) { //控制是否提交节点界面数据(新增节点时用)
    state.ControlCommitNode = value
  },

  // 流程相关 */
  WORKFLOWSETUP(state, value) {
    state.WorkFlowSetup = value
  },
  // /* 表格排序下标
  SORTINDEX(state, value) {
    state.SortIndex = value
  },
}

export default mutation;
