<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：顶部导航界面
	开始时间：2021-01-15
	开发人员：刘巍骏
	最后修改：2021-01-15
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="topNavAll">
    <!-- 面包屑导航 -->
    <div class="curmbs">
      <!-- 收缩图标 -->
      <span class="icon" @click="ShowNavbar_Left(!showNavbar_left)" v-if="loginType == 0">
        <i class="el-icon-s-fold" v-show="showNavbar_left"></i>
        <i class="el-icon-s-unfold" v-show="!showNavbar_left"></i>
      </span>
      <!-- 面包屑内容 -->
      <span class="title">
        <el-breadcrumb>
          <el-breadcrumb-item v-for="item in crumbs_name">{{item}}</el-breadcrumb-item>
        </el-breadcrumb>
      </span>
    </div>
    <!-- 用户信息 -->
    <div class="appUserLoginMsg">
      <ul>
        <li class="languageLi">
          <span class="languageTitle">{{$t("title.language")}}:</span>
          <el-select class="languageInput" v-model="languageType" size="mini" placeholder="请选择">
            <el-option v-for="elem in languageData" :key="elem.id" :label="elem.name" :value="elem.name"></el-option>
          </el-select>
        </li>
        <li @click="toGuideIndex">
          新手
        </li>
        <li @click="toBiIndex">
          面板
        </li>
        <li>
          <i class="iconfont icon-icquestioncircleo"></i>
        </li>
        <li>
          <el-badge :value="'20'" class="item">
            <i class="iconfont icon-bell"></i>
          </el-badge>
        </li>
        <li @mouseenter="show_userInfo=true" @mouseleave="show_userInfo=false">
          <i class="iconfont icon-user1"></i>
        </li>
      </ul>

      <!-- 登录用户信息 -->
      <div class="appUserInfo" v-show="show_userInfo" @mouseenter="show_userInfo=true"
        @mouseleave="show_userInfo=false" v-if="loginType == 0">
        <!-- 信息 -->
        <div class="info">
          <div class="img">
            <img src="../../assets/img/7.jpg">
          </div>
          <div class="nameEmail">
            <p class="name">{{UserInfo.user_name}}</p>
            <p class="email">{{UserInfo.mobile}}</p>
          </div>
        </div>

        <!-- 上次登录时间 -->
        <div class="lastTime">
          <span>上次登录</span>
          <span>{{UserInfo.last_login_time}}</span>
        </div>

        <!-- 安全退出 -->
        <div class="exit">
          <span @click="safeExit">安全退出</span>
        </div>
      </div>

      <!-- 客户登录信息 -->
      <div class="appUserInfo" v-show="show_userInfo" @mouseenter="show_userInfo=true"
        @mouseleave="show_userInfo=false" v-if="loginType == 1">
        <!-- 信息 -->
        <div class="info">
          <div class="img">
            <img src="../../assets/img/7.jpg">
          </div>
          <div class="nameEmail">
            <p class="name">{{LoginCustomerInfo.customer_name}}</p>
            <p class="email">{{LoginCustomerInfo.email}}</p>
          </div>
        </div>

        <!-- 合作开始时间 -->
        <div class="lastTime">
          <span>开始合作</span>
          <span>{{LoginCustomerInfo.enrol_time}}</span>
        </div>

        <!-- 安全退出 -->
        <div class="exit">
          <span @click="safeExit">安全退出</span>
        </div>
      </div>

      <!-- 供应商登录信息 -->
      <div class="appUserInfo" v-show="show_userInfo" @mouseenter="show_userInfo=true"
        @mouseleave="show_userInfo=false" v-if="loginType == 2">
        <!-- 信息 -->
        <div class="info">
          <div class="img">
            <img src="../../assets/img/7.jpg">
          </div>
          <div class="nameEmail">
            <p class="name">{{LoginSupplierInfo.fullName}}</p>
            <p class="email">{{LoginSupplierInfo.email}}</p>
          </div>
        </div>

        <!-- 合作开始时间 -->
        <div class="lastTime">
          <span>开始合作</span>
          <span>{{LoginSupplierInfo.createTime}}</span>
        </div>

        <!-- 安全退出 -->
        <div class="exit">
          <span @click="safeExit">安全退出</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  export default {
    name: "topNav",
    data() {
      return {
        // 控制开关
        show_userInfo: false,

        //语言选择
        languageData: [{
            id: 0,
            name: '中文',
          },
          {
            id: 1,
            name: '英文',
          },
          {
            id: 2,
            name: '韩文',
          }
        ],

        languageType: '中文',
      }
    },
    created() {
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        loginType: state => state.user.loginType, //当前系统登录方式
        LoginCustomerInfo: state => state.user.LoginCustomerInfo, //当前系统登录的客户信息
        LoginSupplierInfo: state => state.user.LoginSupplierInfo, //当前系统登录供应商信息
        showNavbar_left: state => state.mainNavbar.showNavbar_left, //是否显示展开左侧导航栏
        crumbs_name: state => state.mainNavbar.crumbs_name, //头部导航面包屑名称
      }),
    },
    watch: {
      languageType(newVal) {
        if (newVal == "中文") {
          this.$i18n.locale = 'zh_CN';
        } else if (newVal == "英文") {
          this.$i18n.locale = 'en_US';
        }
      }
    },
    methods: {
      ...mapMutations([
        'ShowNavbar_Left', //控制左侧导航栏展开
      ]),
      ...mapActions([
        'getUserInfo', //获取用户信息
      ]),

      /* 跳转到面板界面 */
      toBiIndex(){
        window.location.href = baseWebUrl + "/Bi/index.html";
      },

      /* 跳转到新手界面 */
      toGuideIndex(){
        this.$router.push({
          path: '/guideindex',
        })
      },

      safeExit() {
        //清空cookie中的token
        setCookie('token', '');
        //重置15天内免登录
        setCookie('auto', 0);
        //清空系统登录类型
        setCookie('login_type', -1);
        //清空登录的合作伙伴id
        setCookie('partnerLoginId', "");
        //清空首次登录
        setCookie('initLogin', "");
        //跳转到登录页面
        if(this.loginType == 0){
          window.location.href = loginUrl;
        }else{
          window.location.href = partnerLoginUrl;
        }
      },
    }
  }
</script>

<style lang="less">
  .topNavAll {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-bottom: 2px solid @theme;

    // 面包屑导航
    .curmbs {
      cursor: pointer;
      display: flex;
      align-items: center;

      // border: 1px solid red;
      .icon {
        padding: 0 10px;
        font-size: 24px;
      }

      .icon:hover {
        color: @theme;
      }

      .title {
        font-size: 14px;
        padding: 0 10px;

        &:hover {
          cursor: hover !important;
        }
      }
    }

    // 用户登录信息
    .appUserLoginMsg {
      // width: 200px;/* /no */
      position: relative;

      // border: 1px solid blue;
      ul {
        // width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;

        // border: 1px solid red;
        li {
          // width: 20%;
          height: 100%;
          padding: 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          // border: 1px solid black;
        }

        li:hover {
          color: @theme;
        }

        //语言选择样式
        .languageLi {
          .languageTitle {
            //语言标题
            padding: 0 10px;
          }

          .languageInput {
            //语言选择框
            width: 100px;
          }
        }

        .languageLi:hover {
          //鼠标移入
          color: black;
        }
      }

      // 用户信息
      .appUserInfo {
        width: 200px;
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        z-index: 100;
        position: absolute;
        right: 0;
        cursor: pointer;
        border-radius: 5px;

        // border: 1px solid black;
        // 信息
        .info {
          display: flex;
          justify-content: space-between;

          // 图片
          .img {
            padding: 5px;

            img {
              width: 50px;
              height: 50px;
            }
          }

          // 姓名邮箱
          .nameEmail {
            // width: 200px;
            padding: 5px;

            // border: 1px solid black;
            p {
              width: 120px;
              height: 25px;
              line-height: 25px;
              overflow: hidden;
              /*超出部分隐藏*/
              white-space: nowrap;
              /*不换行*/
              text-overflow: ellipsis;
              /*超出部分文字以...显示*/
            }

            .name {
              font-size: 14px;
            }

            .email {
              font-size: 12px;
            }
          }
        }

        // 上次登录时间
        .lastTime {
          font-size: 12px;
          padding: 10px 0;
          border-bottom: 1px solid white;

          span {
            padding-left: 10px;
          }
        }

        // 安全退出
        .exit {
          font-size: 12px;
          padding: 10px 0;
          text-align: center;
          // border: 1px solid black;
        }

        .exit:hover {
          color: @theme;
        }
      }
    }
  }
</style>
