// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：公共组件.单据变量
// 开始时间：2021-01-19
// 开发人员：刘巍骏
// 最后修改：2021-01-19
// 备注说明：如需修改请联系开发人员

import mutations from "./mutations.js"

const state={
  //单据模板
  // 控制显示新增或修改单据弹框
  show_billsCreateBox:false,//控制显示新增或修改单据弹框
  OrderTemplate:[],//单据模板
  CommodityInfo:[],//货品编码
  BillingTabIndex:'0',//单据模板下标
}


export default {
  state,
  mutations
}
