/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：公共组件·同步公共组件方法js
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */
const mutation = {
  // 单据相关
  CUSTOMER_DATA(state, value) { //获取单据选择客户
    state.customer_data = value;
  },
  SUPPLIER_DATA(state, value) { //获取单据选择客户
    state.supplier_data = value;
  },
  PRODUCT_DATA(state, value) { //获取单据基本信息所选产品
    state.product_data = value;
  },
  COST_TYPE(state,value){ //获取单据基本信息所选费用分类
    state.cost_type = value;
  },
  BILLS_TYPE_DATA(state, value) { //单据所选单据类型数据
    state.bills_type_data = value;
  },
  BILLS_BASE_DATA(state, value) { //单据所填基本信息数据
    state.bills_base_data = value;
  },
  BILLS_SEL_DETAIL(state, value) {//单据所选关联单据详情信息
    state.bills_sel_detail = value;
  },
  BILLS_RELVENCE_SIGN(state, value){//当前关联单据的标签
    state.bills_relvence_sign = value;
  },
  BILLS_OPERATE_BTN(state,value){//单据操作按钮(用于判断各单据列表是否需要刷新)
    state.bills_operate_btn = value;
  },
  BILLS_PRODUCT_DATA(state,value){//单据货品列表选择的数据
    state.bills_product_data = value;
  },
  BILLS_PRODUCT_TYPE(state,value){//单据货品列表信息操作方式(0:新增 1:修改 2:删除)
    state.bills_product_type = value;
  },
  BILLS_EXPENSE_DATA(state,value){//单据其它费用列表信息
    state.bills_expense_data = value;
  },
  BILLS_MES_OFFER_DATA(state,value){//单据生产工艺报价物料以及工序列表信息
    state.bills_mes_offer_data = value;
  },
  IS_LOAD_CONDITION_BILLS(state,value){//是否立即加载指定条件单据
    state.is_load_condition_bills = value;
  },
  IS_LOAD_CHECKBILL_DATA(state,value){//是否加载对账单信息(收付款单引用对账单)
    state.is_load_checkbill_data = value;
  },
  RELVANCE_BILLID_ARR(state,value){//对账单单据明细id数组
    state.relvance_billid_arr = value;
  },
  RELVANCE_BILLID_SIGN(state,value){//对账单单据明细模块分类
    state.relvance_billid_sign = value;
  },
  DEPOT_SEL_ID_ARR(state,value){//已加载的出入库单id数组(根据周期内查询出的,非指定id)
    state.depot_sel_id_arr = value;
  },
  INIT_BILLS_RELEVNCE_DATA(state,value){//清空公共单据相关vuex数据
    state.customer_data = {};//清空客户
    state.supplier_data = {};//清空供应商
    state.product_data = {};//清空所选货品
    state.cost_type = {};//清空所选费用分类
    state.bills_type_data = {};//清空所选单据类型
    state.bills_base_data = {};//清空所填基本信息数据
    state.bills_sel_detail = {};//清空当前所选关联单据详情
    state.bills_relvence_sign = -1;//清空单据所选单据分类
    state.bills_operate_btn = -1;//清空单据操作按钮
    state.bills_product_data = [];//清空单据货品列表选择的数据
    state.bills_product_type = -1;//清空货品操作方式
    state.bills_expense_data = [],//清空其它费用信息列表
    state.bills_mes_offer_data = [],//清空生产工艺报价明细列表
    state.is_load_condition_bills = false;//清空立即加载单据
    state.is_load_checkbill_data = false;//清空是否加载对账单信息(收付款单引用对账单)
    state.relvance_billid_arr = [];//清空对账单单据明细id数组
    state.relvance_billid_sign = -1;//清空对账单单据明细模块分类
    state.depot_sel_id_arr = [];//清空已加载的出入库单id数组(根据周期内查询出的,非指定id)
    state.totalCostPrice=0;//清空bom总成本
  },

  BILLS_MSG_BAK(state,value){//单据vuex数据备份
    state.billsMsgBak.customer_data = state.customer_data;//客户
    state.billsMsgBak.supplier_data = state.supplier_data;//供应商
    state.billsMsgBak.product_data = state.product_data;//所选货品
    state.billsMsgBak.cost_type = state.cost_type;//所选费用分类
    state.billsMsgBak.bills_type_data = state.bills_type_data;//所选单据类型
    state.billsMsgBak.bills_base_data = state.bills_base_data;//所填基本信息数据
    state.billsMsgBak.bills_sel_detail = state.bills_sel_detail;//当前所选关联单据详情
    state.billsMsgBak.bills_relvence_sign = state.bills_relvence_sign;//单据所选单据分类
    state.billsMsgBak.bills_operate_btn = state.bills_operate_btn;//单据操作按钮
    state.billsMsgBak.bills_product_data = state.bills_product_data;//单据货品列表选择的数据
    state.billsMsgBak.bills_product_type = state.bills_product_type;//货品操作方式
    state.billsMsgBak.bills_expense_data = state.bills_expense_data,//其它费用信息列表
    state.billsMsgBak.bills_mes_offer_data = state.bills_mes_offer_data,//生产工艺报价明细列表
    state.billsMsgBak.is_load_condition_bills = state.is_load_condition_bills;//立即加载单据
    state.billsMsgBak.is_load_checkbill_data = state.is_load_checkbill_data;//是否加载对账单信息(收付款单引用对账单)
    state.billsMsgBak.relvance_billid_arr = state.relvance_billid_arr;//对账单单据明细id数组
    state.billsMsgBak.relvance_billid_sign = state.relvance_billid_sign;//对账单单据明细模块分类
    state.billsMsgBak.depot_sel_id_arr = state.depot_sel_id_arr;//已加载的出入库单id数组(根据周期内查询出的,非指定id)
    state.billsMsgBak.totalCostPrice = state.totalCostPrice;//bom总成本
  },

  RESTORE_BILLS_MSG(state,value){//恢复单据vuex数据
    state.customer_data = state.billsMsgBak.customer_data;//恢复客户
    state.supplier_data = state.billsMsgBak.supplier_data;//恢复供应商
    state.product_data = state.billsMsgBak.product_data;//恢复所选货品
    state.cost_type = state.billsMsgBak.cost_type;//恢复所选费用分类
    state.bills_type_data = state.billsMsgBak.bills_type_data;//恢复所选单据类型
    state.bills_base_data = state.billsMsgBak.bills_base_data;//恢复所填基本信息数据
    state.bills_sel_detail = state.billsMsgBak.bills_sel_detail;//恢复当前所选关联单据详情
    state.bills_relvence_sign = state.billsMsgBak.bills_relvence_sign;//恢复单据所选单据分类
    state.bills_operate_btn = state.billsMsgBak.bills_operate_btn;//恢复单据操作按钮
    state.bills_product_data = state.billsMsgBak.bills_product_data;//恢复单据货品列表选择的数据
    state.bills_product_type = state.billsMsgBak.bills_product_type;//恢复货品操作方式
    state.bills_expense_data = state.billsMsgBak.bills_expense_data,//恢复其它费用信息列表
    state.bills_mes_offer_data = state.billsMsgBak.bills_mes_offer_data,//恢复生产工艺报价明细列表
    state.is_load_condition_bills = state.billsMsgBak.is_load_condition_bills;//恢复立即加载单据
    state.is_load_checkbill_data = state.billsMsgBak.is_load_checkbill_data;//恢复是否加载对账单信息(收付款单引用对账单)
    state.relvance_billid_arr = state.billsMsgBak.relvance_billid_arr;//恢复对账单单据明细id数组
    state.relvance_billid_sign = state.billsMsgBak.relvance_billid_sign;//恢复对账单单据明细模块分类
    state.depot_sel_id_arr = state.billsMsgBak.depot_sel_id_arr;//恢复已加载的出入库单id数组(根据周期内查询出的,非指定id)
   state.totalCostPrice=   state.billsMsgBak.totalCostPrice ;//bom总成本
  },

  // 公共客户选择组件
  SHOW_CUSTOMERBOX(state, value) { //控制显示客户选择组件
    state.show_customerBox = value;
  },

  //公共产品选择组件
  SHOW_PRODUCTBOX(state, value) { //控制显示产品选择弹框
    state.show_productBox = value;
  },

  //公共供应商选择组件
  SHOW_SUPPLIERBOX(state, value) { //控制显示供应商选择弹框
    state.show_supplierBox = value
  },

  //公共仓库选择组件
  SHOW_WAREHOUSEBOX(state, value) { //控制显示仓库组件弹框
    state.show_warehouesBox = value;
  },
  //公共部门选择组件
  SHOW_DEPARTMENTBOX(state, value) { //控制显示部门组件弹框
    state.show_departmentBox = value;
  },

  //公用员工选择组件
  SHOW_PERSONNELBOX(state, value) { //控制显示员工选择组件弹框
    state.show_personnelBox = value;
  },

  //公用员工选择组件
  SHOW_ALLPERSONNELBOX(state, value) { //控制显示员工选择组件弹框
    state.show_AllpersonnelBox = value;
  },

  //公共产品分类选择组件
  SHOW_PRODUCTTYPEBOX(state, value) { //控制显示货品分类选择组件弹框
    state.show_productTypeBox = value
  },
  //公用关联单据组件
  SHOW_BILLINGBOX(state, value) { //公用关联单据组件
    state.show_billingBox = value;
  },

  //公共工艺选择组件
  SHOW_TECHNOLOGYBOX(state, value) { //控制显示公共工艺选择组件
    state.show_technologyBox = value;
  },

  //公共客户分类选择组件
  SHOW_CUSTOMERCLASSFIYBOX(state, value) {
    state.show_customerClassfiyBox = value
  },
  //公共模板选择组件
  SHOW_PRINTTMEPLATEBOX(state, value) {
    state.show_printTemplateBox = value; //控制显示公共打印模板选择组件
  },
  SHOW_ELECTRONICTMEPLATEBOX(state, value) {
    state.show_electronicTemplateBox = value; //控制显示公共电子模板选择组件
  },

  //控制显示公共费用分类模板选择组件
  SHOW_COSTTYPEBOX(state, value) {
    state.show_costTypeBox = value;
  },
  //控制显示仓位号选择组件
  SHOW_WAREHOUSEPLACE(state,value){
    state.show_warehousePlace = value;
  },
  //控制显示附件预览弹框
  SHOW_FILEPREVIEW(state,value){
    state.show_filePreview = value;
  },

  //保存相关总成本信息
  TOTALCOSTPRICE(state,value){
    state.totalCostPrice = value;
  },

}

export default mutation;
