<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：用户登录项目架构
	开始时间：2022-03-30
	开发人员：刘巍骏
	最后修改：2022-03-30
	备注说明：如需修改请联系开发人员
-->
<template>
  <div id="app" class="app">
    <!-- 左侧导航栏 -->
    <div class="appLeftNav" id="appLeftNav">
      <!-- 侧边栏主导航 -->
      <mainNav></mainNav>
    </div>

    <!-- 右侧内容 -->
    <div class="appRightContent" id="appRightContent">
      <!-- 头部面包屑导航 -->
      <div class="right_head" id="appright_head" v-show="showNavbar_top" @mouseleave="show_headFold=false"
        @mouseenter="show_headFold=true">
        <!-- 顶部导航 -->
        <top-nav></top-nav>
      </div>
      <!-- 内容 -->
      <div class="right_content scollStyle">
        <router-view v-if="show_projectContent"></router-view>
      </div>

      <!-- 收缩图标 -->
      <div class="icon_head_shrink" v-show="showNavbar_top & show_headFold" @mouseenter="show_headFold=true"
        @mouseleave="show_headFold=false" @click="ShowNavbar_Top(false)">
        <icon-font :class="'icon-xiangshangjiantou'" :size="12" :color="'#847575'"></icon-font>
      </div>
      <!-- 展开图标 -->
      <div class="icon_head_unfold" v-show="!showNavbar_top" @click="ShowNavbar_Top(true)">
        <icon-font :class="'icon-xiangxia'" :size="12" :color="'#847575'"></icon-font>
      </div>
    </div>

    <!--token过期弹出层-->
    <div class="tokenExpiredBox" v-if="!showBody">
      <div class="tokenExpiredTip">
        <br />
        <i class="icon iconfont icon-zanwushuju" style="font-size: 50px;"></i>
        <br /><br /><br />
        <p style="font-size: 22px;color:black">您的身份已过期或未登录!</p>
        <br /><br />
        <a :href="loginPath">立即登录</a>
        <span style="font-size: 15px;">
          <span id="fiveSpan" style="color: red;font-weight: bold;"></span>&nbsp;后登录</span>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
  // 组件导入
  import mainNav from "@/components/commNavbar/mainNav.vue"; //主栏目(erp)
  import topNav from "@/components/commNavbar/topNav.vue"; //顶部导航(erp)

  // 导入请求
  import Api from '../api/api'; //(crm)
  import UserApi from '@/network/user.js'; //用户相关接口(erp)

  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions,
    mapGetters
  } from 'vuex'

  export default {
    name: 'userLoginApp',
    data() {
      return {
        // 控制开关
        show_headFold: false, //控制显示头部导航栏收缩
        show_projectContent:false,//控制是否加载项目内容

        //数据
        showBody: false,
        loginPath: "", //登录跳转路径
        tokenType: 0,

        //loading框
        loading: '', //loading框内容
      }
    },
    watch: {
      // 侧边栏主导航展开收缩
      showNavbar_left(newVal) {
        this.$nextTick(() => {
          this.getAppWidth();
        })
      },
      // 顶部导航栏展开收缩
      showNavbar_top(newVal) {
        this.$nextTick(() => {
          this.getAppHeight();
        })
      },
    },
    computed: {
      ...mapState([
        'token', 'userparamlist', 'companyparamlist', 'daySurplusCusCounts', 'allSurplusCusCounts',
        'routerLoading', 'mylinkmanlist', 'userinfo', 'userlistall'
      ]),
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        showNavbar_left: state => state.mainNavbar.showNavbar_left, //是否显示展开左侧导航栏
        showNavbar_top: state => state.mainNavbar.showNavbar_top, //是否显示展开顶部导航栏(面包屑)
        menu_config: state => state.mainNavbar.menu_config, //是否显示展开顶部导航栏(面包屑)
      }),
    },
    mounted() {
      // 当浏览器宽度发生改变时
      window.onresize = () => {
        this.getAppWidth();
        this.getAppHeight();
      }

      //判断token是否有效
      this.judgeTokenInvalid();

      // 获取国家数据
      this.getCountryInfo();

      loginUrl = Api.loginUrl;
      backUrl = Api.teamUrl;
      yxyUrl = Api.yxyUrl;
      diskUrl = Api.diskUrl;
      this.loginPath = loginUrl;
    },
    methods: {
      ...mapMutations([
        'ShowNavbar_Top', //控制顶部导航栏展开
        'getUserParamList',//获取用户权限参数
        'getCompanyParamList',//获取企业权限参数
        'getUserlistAll',
        'CURMBS_NAME', //存储面包屑名称
      ]),
      ...mapActions([
        'getCountryInfo',//获取国家
        'getUserInfo', //用户信息
        'getUserPowerInfo',//用户操作权限
      ]),

      //动态获取内容div大小
      getAppWidth() { // 获取内容div动态宽度
        var appdivWidth = $(".app").width();
        var windowWidth = $(window).width();
        var leftdivWidth = $(".appLeftNav").width();
        var rightdivWidth = windowWidth - leftdivWidth;
        $(".appRightContent").width(rightdivWidth)
      },
      getAppHeight() { //获取内容div高度
        var appHeight = $(".app").height();
        var topdivHeight = $("#appright_head").height();
        var appContHeight = appHeight - (topdivHeight + 2);
        if (!this.showNavbar_top) {
          $(".right_content").height(appHeight)
        } else {
          $(".right_content").height(appContHeight)
        }
      },

      //10s后跳转 定时器
      five_goTimer() {
        var five = 10;
        setInterval(function() {
          if (five == 0) {
            window.location.href = loginUrl;
          } else {
            five = five - 1;
            $("#fiveSpan").html(five + "s");
          }
        }, 1000);
      },

      /* 判断token是否有效(erp) */
      async judgeTokenInvalid() {
        //默认不显示token弹框
        this.showBody = true;
        //显示loading框
        this.loading = this.commonJsExtend.customLoading("#app", 4, '系统初始化中,请稍候...');
        let cookieToken = getCookie('token');
        //判断cookie中的token是否存在
        if (cookieToken) { //存在
          //刷新获取token
          let validResult = "";
          validResult = await this.refreshToken();
          //cookie中的token有效
          if (validResult != 'token invalid') {
            //调用token有效执行的方法
            this.tokenValid(validResult);
          } else { //cookie中的token无效
            this.loading.close();
            this.five_goTimer();
            this.tokenInValid();
          }
        } else { //不存在
          this.loading.close();
          if (shareLink) {
            this.$store.state.routerLoading = false;
          } else {
            this.five_goTimer();
            this.tokenInValid();
          }
        }
      },

      //刷新token失败后,一分钟后再次刷新，重复五次后仍失败重新登录(erp)
      refreshError() {
        let self = this;
        self.tokenType += 1
        if (self.tokenType <= 3 && self.showBody) {
          setTimeout(() => {
            self.refreshToken()
          }, 60000)
        } else {
          self.tokenType = 0;
          self.five_goTimer();
          self.tokenInValid();
        }
      },

      //token有效执行
      async tokenValid(validResult) {
        //关闭token过期弹框
        this.showBody = true;
        //设置全局token
        token = validResult;
        //将token设置到cookie
        setCookie('token', validResult);
        //将token存入vuex
        this.$store.state.token = validResult;
        //显示loading框
        // $("#routerLoading").show();
        // this.loading = this.commonJsExtend.customLoading("#app", 4, '系统初始化中,请稍候...');
        var lef = this;
        await setTimeout(function() {
          //关闭loading框
          // $("#routerLoading").hide();
          // lef.loading.close();
        }, 0);
        // 获取用户信息
        await lef.getUserInfo();
        // 获取企业权限数据
        await lef.getUserParamList(); //用户权限
        await lef.getUserPowerInfo();//用户操作权限
        await lef.getCompanyParamList(); //企业权限
        await lef.refreshTokenTime();
        await lef.getUserlistAll();

        //显示内容数据
        this.show_projectContent = true;

        //判断是否为首次登录(1:已经登录过)
        let initLogin = getCookie("initLogin");
        if(initLogin != "1"){
          //判断当前登录用户权限
          if(this.UserInfo.user_role != 1){//非系统管理员(跳转到新手界面)
            this.$router.push({
              path: '/guideindex',
            })
          }
          
          //设置为非初次登录
          setCookie("initLogin","1");
        }

        //关闭loading框
        this.loading.close();
      },

      /* 刷新token(erp) */
      async refreshToken() {
        let result = "token invalid";
        await UserApi.user.refreshToken().then(res => {
          //判断接口返回参数
          if (res.code == 200) {
            //判断返回token是否有效
            if (!!res.data && res.data != 'token invalid') { //有效
              result = res.data;
              setCookie('token', result)
            } else { //无效
              //无效重新请求
              this.refreshError();
            }
          }
        })
        return result;
      },

      /* token无效执行 */
      tokenInValid() {
        this.$store.state.routerLoading = false;
        this.showBody = false;
      },

      //刷新token定时器,30分钟执行一次
      refreshTokenTime() {
        var lef = this;
        setInterval(() => {
          if (lef.showBody) {
            let validResult = lef.refreshToken(token);
            // console.log(2)
            // console.log(validResult)
            // if (validResult != 'token invalid') {
            //   token = validResult;
            //   console.log(validResult)
            //   setCookie('token', validResult);
            //   console.log("执行完一次了>>>>>>")
            // }
          }
        }, 1000 * 60 * 30);
        // }, 1000 * 3);
      },

      //判断token有效定时器,6分钟执行一次
      checkTokenTime() {
        var lef = this;
        setInterval(() => {
          $.ajax({
            type: "POST",
            url: Api.teamUrl + "xuser_checkToken.action",
            async: false,
            cache: false,
            dataType: "json",
            data: {
              token: getCookie('token')
            },
            success: function(msg) {
              //token无效了
              if (msg == 'token invalid') {
                lef.showBody = false;
                lef.$store.state.routerLoading = false;
              } else {
                lef.showBody = true;
              }
            },
            error: function() {
              lef.showBody = false;
            }
          });
        }, 1000 * 60 * 6);
      },
    },
    components: {
      mainNav,
      topNav
    }
  }
</script>

<!-- 引入公共样式 -->
<style lang="less" src="@/less/Comm/comm_app.less"></style>

<!-- less写的新样式，弄完上面样式可删 -->
<style lang="less" scoped="scoped">
  /* token过期*/
  .tokenExpiredBox {
    //提示信息总框
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    // border: 1px solid black;
    .tokenExpiredTip {

      //提示信息
      a {
        margin-left: 0px;
        margin-right: 25px;
        color: #fff;
        font-size: 15px;
        height: 50px;
        background: #20a0ff;
        padding: 8px;
        border-radius: 5px;
      }

      a:hover {
        background-color: #4db3ff;
        text-decoration: none;
      }
    }
  }
</style>
