/**
 * 程序版本：V3.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：用户管理·mutation
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */
const mutation = {
  //系统相关
  LOGIN_TYPE(state, value){//系统登录方式(0:用户登录  1:客户登录  2:供应商登录)
    state.loginType = value;
  },

  //用户相关
  USERINFO(state, value) { //当前登录用户信息
    state.UserInfo = value;
  },
  USER_POWER_INFO(state, value) { //当前登录用户的权限信息
    state.userPowerInfo = value;
  },

  //登录信息相关
  LOGIN_CUSTOMER_ID(state, value){//登录系统的客户id
    state.LoginCustomerId = value;
  },
  LOGIN_CUSTOMER_INFO(state, value){//登录系统的客户
    state.LoginCustomerInfo = value;
  },
  LOGIN_SUPPLIER_ID(state, value){//登录系统的供应商id
    state.LoginSupplierId = value;
  },
  LOGIN_SUPPLIER_INFO(state, value){//登录系统的供应商
    state.LoginSupplierInfo = value;
  },
}

export default mutation;
