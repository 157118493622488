import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 首页路由
  {
    path: '/',
    name: 'home',
    // component: resolve => require(['@/views/index'], resolve),
    component: resolve => require(['@/views/newIndex'], resolve),
  },

  // 首页路由
  {
    path: '/guideindex',
    name: 'guideindex',
    // component: resolve => require(['@/views/index'], resolve),
    component: resolve => require(['@/components/index/guideIndex'], resolve),
  },

  // 客户供应商登录路由
  {
    path: '/customerlogin',
    name: 'customerlogin',
    component: resolve => require(['@/components/login/customerLogin.vue'], resolve),
  },

  // 客户供应商主界面路由
  {
    path: '/customerindex',
    name: 'customerindex',
    component: resolve => require(['@/components/login/customerLogin.vue'], resolve),
  },

  // 工作模块路由配置
  {
    path: '/billing',
    name: 'billing',
    component: resolve => require(['@/components/billing/index.vue'], resolve),
    redirect: '/billing_list',
    children: [{
        path: '/billing_list', //开单列表主页
        name: 'billing_list',
        component: resolve => require(['@/components/billing/billingList.vue'], resolve)
      },
      {
        path: '/billing_menu', //开单列表主页
        name: 'billing_menu',
        component: resolve => require(['@/components/billing/billingMenu.vue'], resolve)
      },
    ]
  },

  //销售模块路由配置
  {
    path: '/sell',
    name: 'sell',
    component: resolve => require(['@/components/sell/index.vue'], resolve),
    children: [{
        path: '/sell_view', //销售单据(报价单,订单)
        name: 'sell_view',
        component: resolve => require(['@/components/sell/billing/sell_view.vue'], resolve)
      },
      {
        path: '/invoice_view', //发票
        name: 'invoice_view',
        component: resolve => require(['@/components/cost/invoice/invoice_view.vue'], resolve)
      },
    ]
  },


  //生产路由配置
  {
    path: '/production',
    name: 'production',
    component: resolve => require(['@/components/production/index.vue'], resolve),
    redirect: '/bom_manage',
    children: [
      /* ***********生产管理分类*********** */
      // {
      //   path: '/bom_manage',//bom管理主页
      //   name:'bom_manage',
      //   component:  resolve => require(['@/components/production/components/BOM_manage.vue'], resolve)
      // },
      {
        path: '/process_manage', //工序管理主页
        name: 'process_manage',
        component: resolve => require([
          '@/components/production/productionManage/processManage/processManage_view.vue'
        ], resolve)
      },
      {
        path: '/bom_manage', //bom管理主页
        name: 'bom_manage',
        component: resolve => require(['@/components/production/productionManage/bomManage/bomManage_view.vue'],
          resolve)
      },
      /* ***********单据分类*********** */
      {
        path: '/productionplan_view', //生产计划单视图
        name: 'productionplan_view',
        component: resolve => require(['@/components/production/billing/productionPlan/productionplan_view.vue'],
          resolve)
      },
      {
        path: '/productionplan_details', //生产计划单详情
        name: 'productionplan_details',
        component: resolve => require(['@/components/production/billing/productionPlan/productionplan_details'],
          resolve)
      },
      {
        path: '/productionplan_process_view', //生产工序单合并界面
        name: 'productionplan_process_view',
        component: resolve => require([
          '@/components/production/billing/productionPlan/productionplan_process_view'],
          resolve)
      },
      {
        path: '/requisitionmaterial_view', //领料出库单
        name: 'requisitionmaterial_view',
        component: resolve => require([
          '@/components/production/billing/requisitionMaterial/requisitionmaterial_view.vue'
        ], resolve)
      },
      {
        path: '/replenishmaterial_view', //补料单
        name: 'replenishmaterial_view',
        component: resolve => require([
          '@/components/production/billing/replenishMaterial/replenishmaterial_view.vue'
        ], resolve)
      },
      {
        path: '/cost_statement', //成本明细
        name: 'cost_statement',
        component: resolve => require([
          '@/components/production/billing/statistics/cost_statement.vue'
        ], resolve)
      },
      // {
      //   path: '/returnmaterial_view', //退料入库单
      //   name: 'returnmaterial_view',
      //   component: resolve => require(['@/components/production/billing/returnMaterial/returnmaterial_view.vue'],
      //     resolve)
      // },
    ]
  },

  //库存路由配置
  {
    path: '/inventory',
    name: 'inventory',
    component: resolve => require(['@/components/inventory/index.vue'], resolve),
    children: [
      /* ***********采购相关*********** */
      {
        path: '/purchase_view', //采购单
        name: 'purchase_view',
        component: resolve => require(['@/components/inventory/purchase/purchase_view.vue'],
          resolve)
      },

      /* ***********仓库分类*********** */
      {
        path: '/warehouse_view', //出入库单(包括组装单,拆卸单,调拨单等与仓库有关的单)
        name: 'warehouse_view',
        component: resolve => require(['@/components/inventory/warehouse/warehouse_view.vue'],
          resolve)
      }
    ]
  },

  //资金路由配置
  {
    path: '/cost',
    name: 'cost',
    component: resolve => require(['@/components/cost/index.vue'], resolve),
    children: [
      /* ***********往来账相关*********** */
      {
        path: '/billing_putpay', //收款单
        name: 'billing_putpay',
        component: resolve => require(['@/components/cost/account/putpay_view.vue'], resolve)
      },
      {
        path: '/check_view', //对账单
        name: 'check_view',
        component: resolve => require(['@/components/cost/check/check_view.vue'],resolve)
      },
      /* ***********报销相关*********** */
      {
        path: '/expense_view', //报销单(包括报销单,报销申请单)
        name: 'expense_view',
        component: resolve => require(['@/components/cost/expense/expense_view.vue'],
          resolve)
      }
    ]
  },

  //资料路由配置
  {
    path: '/datum',
    name: 'datum',
    component: resolve => require(['@/components/datum/index.vue'], resolve),
    redirect: '/commodityIndex',
    children: [{
        path: '/companyProfile', //公司资料
        name: 'companyProfile',
        component: resolve => require(['@/components/datum/basicInformation/companyProfile.vue'], resolve),
      },
      {
        path: '/licenseInformation', //授权信息
        name: 'licenseInformation',
        component: resolve => require(['@/components/datum/basicInformation/licenseInformation.vue'], resolve),
      },
      {
        path: '/licenseModule', //授权模块
        name: 'licenseModule',
        component: resolve => require(['@/components/datum/basicInformation/licenseModule.vue'], resolve),
      },
      {
        path: '/customerPrincipal', //客户期初
        name: 'customerPrincipal',
        component: resolve => require(['@/components/datum/crmManage/crmIndex.vue'], resolve),
      },
      /* ***********基本信息*********** */
      // {
      //   path:'/basicInformationIndex',
      //   name:'basciInformationIndex',
      //   redirect:'/companyProfile',
      //   children:[
      //     {
      //       path:'/companyProfile',//公司资料
      //       name:'companyProfile',
      //       component:  resolve => require(['@/components/datum/basicInformation/companyProfile.vue'], resolve),
      //     },
      //     {
      //       path:'/licenseInformation',//授权信息
      //       name:'licenseInformation',
      //       component:  resolve => require(['@/components/datum/basicInformation/licenseInformation.vue'], resolve),
      //     },
      //     {
      //       path:'/licenseModule',//授权模块
      //       name:'licenseModule',
      //       component:  resolve => require(['@/components/datum/basicInformation/licenseModule.vue'], resolve),
      //     },
      //   ]
      // },
      /* ***********货品管理*********** */
      {
        path: '/commodityIndex', //货品管理主页
        name: 'commodityIndex',
        component: resolve => require(['@/components/datum/commodityManage/commodityIndex.vue'], resolve),
        redirect: '/commodity_view',
        children: [
          /* ***********货品管理分类*********** */
          {
            path: '/commodity_view', //货品管理
            name: 'commodity_view',
            component: resolve => require(['@/components/datum/commodityManage/commodity/commodity_view.vue'],
              resolve)
          },
        ]
      },
      {
        path: 'supplierManage', //供应商
        name: 'supplierManage',
        component: resolve => require(['@/components/datum/supplierManage/supplierIndex.vue'], resolve)
      }
    ]
  },

  //系统路由配置
  {
    path: '/system',
    name: 'system',
    component: resolve => require(['@/components/system/index.vue'], resolve),
    redirect: '/system/controlsParameter',
    children: [
      /* ***********基本设置*********** */
      {
        path: 'WeChatSys_view', //微信配置
        name: 'WeChatSys_view',
        component: resolve => require(['@/components/system/basicSetup/WeChatSys_view.vue'], resolve)
      },
      {
        path: 'apiConfig_view', //API配置
        name: 'apiConfig_view',
        component: resolve => require(['@/components/system/basicSetup/apiConfig_view.vue'], resolve)
      },
      /* ***********控制参数*********** */
      {
        path: 'controlsParameter', //控制参数主页
        name: 'controlsParameter',
        component: resolve => require(['@/components/system/controlsParameter/index.vue'], resolve)
      },
      /* ***********个性化配置*********** */
      {
        path: 'individuation', //个性化配置主页
        name: 'individuation',
        component: resolve => require(['@/components/system/individuation/index.vue'], resolve)
      },
      /* ***********高级设置分类*********** */
      {
        path: 'dataDictionary_view', //数据字典
        name: 'dataDictionary_view',
        component: resolve => require(['@/components/system/advancedSetup/dataDictionary_view.vue'], resolve)
      },
      {
        path: 'nodeSetup_view', //节点设置
        name: 'nodeSetup_view',
        component: resolve => require(['@/components/system/advancedSetup/nodeSetup/nodeSetup_view.vue'], resolve)
      },
      {
        path: 'workflowSetup_view', //流程设置
        name: 'workflowSetup_view',
        component: resolve => require(['@/components/system/advancedSetup/workflowSetup_view.vue'], resolve)
      },
    ]
  },

  //邮件路由配置
  // {
  //   path: '/mail',
  //   component: resolve => require(['@/components/mail/mail'], resolve),
  //   children://邮件子路由配置
  //     [
  //       {
  //         path: '/',
  //         component: resolve => require(['@/components/mail/inbox/inbox'], resolve)
  //       },
  //       {
  //         path: 'writeLetter',
  //         name: 'writeLetter',
  //         component: resolve => require(['@/components/mail/writeLetter'], resolve)
  //       },
  //       {
  //         path: 'searchMail',
  //         name: 'searchMail',
  //         component: resolve => require(['@/components/mail/searchMail/searchMail'], resolve)
  //       },
  //       {
  //         path: 'draftMail',
  //         name: 'draftMail',
  //         component: resolve => require(['@/components/mail/draftMail/draftMail'], resolve)
  //       },
  //       {
  //         path: 'deleteMail',
  //         name: 'deleteMail',
  //         component: resolve => require(['@/components/mail/deleteMail/deleteMail'], resolve)
  //       },
  //       {
  //         path: 'sentMail',
  //         name: 'sentMail',
  //         component: resolve => require(['@/components/mail/sentMail/sentMail'], resolve)
  //       },
  //       {
  //         path: 'abnormalMail',
  //         name: 'abnormalMail',
  //         component: resolve => require(['@/components/mail/abnormalMail/abnormalMail'], resolve)
  //       },
  //       {
  //         path: 'readyMail',
  //         name: 'readyMail',
  //         component:resolve => require(['@/components/mail/readyMail/readyMail'], resolve)
  //       },
  //       {
  //         path: 'timingMail',
  //         name: 'timingMail',
  //         component: resolve => require(['@/components/mail/timingMail/timingMail'], resolve)
  //       },
  //       {
  //         path: 'inboxConfig',
  //         name: 'inboxConfig',
  //         component: resolve => require(['@/components/mail/mailConfig/inboxConfig'], resolve),
  //       },
  //       {
  //         path: 'otherConfig',
  //         name: 'otherConfig',
  //         component: resolve => require(['@/components/mail/mailConfig/otherConfig'], resolve)
  //       }
  //     ]
  // },


  //客户路由配置
  {
    path: '/crm',
    name: 'crm',
    component: resolve => require(['../components/crm/index.vue'], resolve),
    children: //客户子路由配置
      [{
          path: 'commonClient', //公海库路由配置
          name: 'commonClient',
          component: resolve => require(['../components/crm/CommonSea.vue'], resolve)
        },
        {
          path: 'recycle', //回收站路由配置
          name: 'crmRecycle',
          component: resolve => require(['../components/crm/Recycle.vue'], resolve)
        },
        {
          path: 'allClient', //我的客户路由配置
          name: 'allClient',
          component: resolve => require(['../components/crm/AllClient/allClient.vue'], resolve)
        },
        {
          path: 'allClientOrigin_myShare', //我的共享客户路由配置
          name: 'allClientOrigin_myShare',
          component: resolve => require([
            '../components/crm/AllClient/allClient_origin/allClientOrigin_myShareClient.vue'
          ], resolve)
        },
        {
          path: 'allClientOrigin_colleagueShare', //别人共享给我的客户路由配置
          name: 'allClientOrigin_colleagueShare',
          component: resolve => require([
            '../components/crm/AllClient/allClient_origin/allClientOrigin_colleagueShareClient.vue'
          ], resolve)
        },
        {
          path: 'allClientOrigin_inWarehouse', //已入库联系人路由配置
          name: 'allClientOrigin_inWarehouse',
          component: resolve => require([
            '../components/crm/AllClient/allClient_origin/allClientOrigin_InWarehouse.vue'
          ], resolve)
        },
        {
          path: 'allClientOrigin_unInWarehouse', //未入库联系人路由配置
          name: 'allClientOrigin_unInWarehouse',
          component: resolve => require([
            '../components/crm/AllClient/allClient_origin/allClientOrigin_unInWarehouse.vue'
          ], resolve)
        },
      ]
  },

  //新窗口的路径
  {
    path: '/newWindow',
    name: 'newWindow',
    component: resolve => require(['../components/new_window/index.vue'], resolve),
    children: [{ //新开的打印预览页面
        path: 'newPriview',
        name: 'newPriview',
        component: resolve => require(['@/components/new_window/newPrintView'], resolve),
      },
      // {//新开的写信页面
      //   path:'newWrite',
      //   name:'newWrite',
      //   component:resolve=>require(['@/components/new_window/newWrite.vue'],resolve),
      // },
      //  {//新开的写信页面
      //   path:'newLookEmail',
      //   name:'newLookEmail',
      //   component:resolve=>require(['@/components/new_window/newLookEmail.vue'],resolve),
      // },
    ]
  },

  //测试路由配置
  {
    path: '/test',
    name: 'test',
    component: resolve => require(['@/components/test/test.vue'], resolve),
  },
  {
    path: '/priview',
    name: 'priview',
    component: resolve => require(['@/components/test/test1.vue'], resolve),
  },
  {
    path: '/priview1',
    name: 'priview1',
    component: resolve => require(['@/components/test/test2.vue'], resolve),
  },
  {
    path: '/testbillingform',
    name: 'testbillingform',
    component: resolve => require(['@/components/test/billingform.vue'], resolve),
  },
  {
    path: '/processTest',
    name: 'processTest',
    component: resolve => require(['@/components/test/processTest.vue'], resolve),
  },


  //销售报表
  {
    path: '/statement/market',
    name: 'market',
    component: resolve => require(['@/components/statement/index.vue'], resolve),
    children: [
      /* ***********销售业绩报表*********** */
      {
        path: '/achievements',
        name: 'achievements',
        component: resolve => require(['@/components/statement/market/achievements/index.vue'],
          resolve)
      },

      /* ***********销售毛利统计*********** */
      {
        path: '/grossMargin',
        name: 'grossMargin',
        component: resolve => require(['@/components/statement/market/grossMargin/index.vue'],
          resolve)
      },
      /* ***********销售订单收款统计表*********** */
      {
        path: '/orderProceeds',
        name: 'orderProceeds',
        component: resolve => require(['@/components/statement/market/orderProceeds/index.vue'],
          resolve)
      },

      /* ***********商品销量分析表*********** */
      {
        path: '/sales',
        name: 'sales',
        component: resolve => require(['@/components/statement/market/sales/index.vue'],
          resolve)
      },
      /* ***********销售总表*********** */
      {
        path: '/summary',
        name: 'summary',
        component: resolve => require(['@/components/statement/market/summary/index.vue'],
          resolve)
      }
    ]
  },

  //生产报表
  {
    path: '/statement/production',
    name: 'production',
    component: resolve => require(['@/components/statement/index.vue'], resolve),
    children: [
      /* ***********订单统计报表*********** */
      {
        path: '/orderStatistics',
        name: 'orderStatistics',
        component: resolve => require(['@/components/statement/production/orderStatistics/index.vue'],
          resolve)
      }
    ]
  },

  //库存报表
  {
    path: '/statement/repertory',
    name: 'repertory',
    component: resolve => require(['@/components/statement/index.vue'], resolve),
    children: [
      /* ***********商品出入库统计表*********** */
      {
        path: '/commodityOutAndIn',
        name: 'commodityOutAndIn',
        component: resolve => require(['@/components/statement/repertory/commodityOutAndIn/index.vue'],
          resolve)
      },
      /* ***********采购汇总表*********** */
      {
        path: '/purchase',
        name: 'purchase',
        component: resolve => require(['@/components/statement/repertory/purchase/index.vue'],
          resolve)
      },
      /* ***********仓库汇总表*********** */
      {
        path: '/warehouse',
        name: 'warehouse',
        component: resolve => require(['@/components/statement/repertory/warehouse/index.vue'],
          resolve)
      }
    ]
  },

  //资金报表
  {
    path: '/statement/capital',
    name: 'capital',
    component: resolve => require(['@/components/statement/index.vue'], resolve),
    children: [
      /* ***********客户对帐单*********** */
      {
        path: '/client',
        name: 'client',
        component: resolve => require(['@/components/statement/capital/client/index.vue'],
          resolve)
      },
      /* ***********供应商对账单*********** */
      {
        path: '/supplier',
        name: 'supplier',
        component: resolve => require(['@/components/statement/capital/supplier/index.vue'],
          resolve)
      }
    ]
  },
]


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

export default router
