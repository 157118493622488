<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：客户供应商登录项目架构
	开始时间：2022-03-30
	开发人员：刘巍骏
	最后修改：2022-03-30
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="customerAppBox">
    <!-- 左侧导航栏 -->
    <div class="leftNav" id="customerAppleftNav">
      <!-- 侧边栏主导航 -->
      <el-menu default-active="1" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff"
        active-text-color="#ffd04b">
        <el-menu-item index="0" class="headBox">
          <span slot="title" class="headTitle">SRCM平台</span>
        </el-menu-item>
        <el-menu-item index="1" @click="toTargetUrl(1)">
          <!-- <i class="el-icon-menu"></i> -->
          <icon-font :class="'icon-dingdan'" :size="18"></icon-font>
          <span class="menu_item">我的订单</span>
        </el-menu-item>
        <el-menu-item index="2" @click="toTargetUrl(2)" v-if="loginType == 1">
          <icon-font :class="'icon-chanpin1'" :size="18"></icon-font>
          <span class="menu_item">新品预览</span>
        </el-menu-item>
        <el-menu-item index="3" @click="toTargetUrl(3)" v-if="loginType == 2">
          <icon-font :class="'icon-baojia'" :size="18"></icon-font>
          <span class="menu_item">采购报价</span>
        </el-menu-item>
        <el-menu-item index="4" @click="toTargetUrl(4)">
          <icon-font :class="'icon-duizhangdanbill'" :size="18"></icon-font>
          <span class="menu_item">我的对账</span>
        </el-menu-item>
        <el-menu-item index="5" @click="toTargetUrl(5)">
          <icon-font :class="'icon-fapiao'" :size="18"></icon-font>
          <span class="menu_item">发票管理</span>
        </el-menu-item>
        <el-menu-item index="6" @click="toTargetUrl(6)">
          <icon-font :class="'icon-feiyongjiesuandan'" :size="18"></icon-font>
          <span class="menu_item">费用结算</span>
        </el-menu-item>
        <el-menu-item index="7" @click="toTargetUrl(7)">
          <icon-font :class="'icon-user1'" :size="18"></icon-font>
          <span class="menu_item">我的信息</span>
        </el-menu-item>
      </el-menu>
    </div>

    <!-- 右侧内容 -->
    <div class="rightNav">
      <!-- 头部面包屑导航 -->
      <div class="right_head" id="right_head">
        <!-- 顶部导航 -->
        <top-nav></top-nav>
      </div>
      <!-- 内容 -->
      <div class="right_content scollStyle">
        <router-view v-if="show_project"></router-view>
      </div>
    </div>

    <!--token过期弹出层-->
    <div class="tokenExpiredBox" v-if="showBody">
      <div class="tokenExpiredTip">
        <br />
        <i class="icon iconfont icon-zanwushuju" style="font-size: 50px;"></i>
        <br /><br /><br />
        <p style="font-size: 22px;color:black">您的身份已过期或未登录!</p>
        <br /><br />
        <a :href="partnerLoginUrl">立即登录</a>
        <span style="font-size: 15px;">
          <span id="fiveSpan" style="color: red;font-weight: bold;"></span>&nbsp;后登录</span>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
  //导入接口
  import UserApi from '@/network/user.js'; //用户相关接口(erp)

  //导入组件
  import topNav from "@/components/commNavbar/topNav.vue"; //顶部导航(erp)

  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions,
    mapGetters
  } from 'vuex'

  export default {
    name: 'customerLoginApp',
    data() {
      return {
        //组件数据
        tokenType: 0, //token刷新次数
        partnerLoginUrl: '', //合作伙伴登录地址

        //控制开关
        showNavbar_top: true, //控制顶部导航栏是否显示
        showBody: false, //控制token过期层是否显示
        show_project: false, //控制是否加载项目
      }
    },
    mounted() {
      // 当浏览器宽度发生改变时
      window.onresize = () => {
        this.getAppHeight();
      }

      //初始化组件所需数据
      this.initComponentData();
    },
    computed: {
      ...mapState({
        loginType: state => state.user.loginType, //当前系统登录方式
        crumbs_name: state => state.mainNavbar.crumbs_name, //头部导航面包屑名称
      }),
    },
    methods: {
      ...mapMutations([
        'LOGIN_CUSTOMER_ID', //存储登录的客户id
        'LOGIN_SUPPLIER_ID', //存储登录的供应商id
        'getUserParamList', //获取用户权限参数
        'getCompanyParamList', //获取企业权限参数
      ]),
      ...mapActions([
        'getLoginCustomerInfo', //获取登录的客户信息
        'getLoginSupplierInfo', //获取登录的供应商信息
        'getUserPowerInfo', //用户操作权限
        'getUserInfo', //用户信息
      ]),
      //获取内容div高度
      getAppHeight() {
        var appHeight = $(".customerAppBox").height();
        var topdivHeight = $("#right_head").height();
        var appContHeight = appHeight - (topdivHeight + 2);
        if (!this.showNavbar_top) {
          $(".right_content").height(appHeight)
        } else {
          $(".right_content").height(appContHeight)
        }
      },

      /* 初始化组件所需数据 */
      initComponentData() {
        //判断cookie是否有合作伙伴id
        let partnerLoginId = getCookie("partnerLoginId");
        //判断是否有值
        if (!!partnerLoginId) { //有值
          //判断登录类型
          if (this.loginType == 1) { //客户登录
            this.LOGIN_CUSTOMER_ID(partnerLoginId);
          } else if (this.loginType == 2) { //供应商登录
            this.LOGIN_SUPPLIER_ID(partnerLoginId);
          }
          //判断token有效期
          this.judgeTokenInvalid();
        } else { //无值
          //显示过期层
          this.showBody = true;
          //开始倒计时
          this.five_goTimer();
        }
        //获取合作伙伴登录地址
        this.partnerLoginUrl = partnerLoginUrl;
      },

      /* 判断token是否有效 */
      async judgeTokenInvalid() {
        //默认不显示token弹框
        this.showBody = false;
        //显示loading框
        this.loading = this.commonJsExtend.customLoading("#app", 4, '系统初始化中,请稍候...');
        let cookieToken = getCookie('token');
        //判断cookie中的token是否存在
        if (cookieToken) { //存在
          //刷新获取token
          let validResult = "";
          validResult = await this.refreshToken();
          //判断cookie中的token是否有效
          if (validResult != 'token invalid') {
            //调用token有效执行的方法
            this.tokenValid(validResult);
          } else { //cookie中的token无效
            //关闭弹框
            this.loading.close();
            //开始倒计时
            this.five_goTimer();
            //token无效触发
            this.tokenInValid();
          }
        } else { //不存在
          //关闭弹框
          this.loading.close();
          //开始倒计时
          this.five_goTimer();
          //token无效触发
          this.tokenInValid();
        }
      },

      /* 刷新token(erp) */
      async refreshToken() {
        let result = "token invalid";
        await UserApi.user.refreshToken().then(res => {
          //判断接口返回参数
          if (res.code == 200) {
            //判断返回token是否有效
            if (!!res.data && res.data != 'token invalid') { //有效
              result = res.data;
              setCookie('token', result)
            } else { //无效
              //无效重新请求
              this.refreshError();
            }
          }
        })
        return result;
      },

      //刷新token失败后,一分钟后再次刷新，重复五次后仍失败重新登录(erp)
      refreshError() {
        let self = this;
        self.tokenType += 1
        if (self.tokenType <= 3 && !self.showBody) {
          setTimeout(() => {
            self.refreshToken()
          }, 60000)
        } else {
          self.tokenType = 0;
          self.five_goTimer();
          self.tokenInValid();
        }
      },

      //token有效执行
      async tokenValid(validResult) {
        //关闭token过期弹框
        this.showBody = false;
        //设置全局token
        token = validResult;
        //将token设置到cookie
        setCookie('token', validResult);
        //将token存入vuex
        this.$store.state.token = validResult;
        // 根据当前登录的类型获取登录信息
        if (this.loginType == 1) { //客户登录
          this.getLoginCustomerInfo();
        } else if (this.loginType == 2) { //供应商登录
          this.getLoginSupplierInfo();
        }
        // 获取用户信息
        await this.getUserInfo();
        // 获取企业权限数据
        await this.getUserParamList(); //用户权限
        await this.getUserPowerInfo(); //用户操作权限
        await this.getCompanyParamList(); //企业权限
        //加载项目
        this.show_project = true;
        //关闭loading框
        this.loading.close();
      },

      /* token无效执行 */
      tokenInValid() {
        this.$store.state.routerLoading = false;
        //显示token过期弹框
        this.showBody = true;
      },

      /* 10s后跳转 定时器 */
      five_goTimer() {
        var five = 10;
        setInterval(function() {
          if (five == 0) {
            window.location.href = this.partnerLoginUrl;
          } else {
            five = five - 1;
            $("#fiveSpan").html(five + "s");
          }
        }, 1000);
      },

      /* 跳转指定url */
      toTargetUrl(type) {
        switch (type) {
          case 1: //我的订单
            if (this.loginType == 1) { //客户登录
              this.$router.push({
                path: '/sell_view',
                query: {
                  orderType: 1,
                  orderBillCode: 'DOC-S-006'
                }
              })
            } else if (this.loginType == 2) { //供应商登录
              this.$router.push({
                path: '/purchase_view',
                query: {
                  purchaseType: 1,
                  purchaseBillCode: 'DOC-P-002'
                }
              })
            }
            break;
          case 2: //新品预览
            this.$message({
              type:'warning',
              message:"该功能暂未开放",
              duration:this.elDuration
            })
            break;
          case 3: //采购报价
            this.$message({
              type:'warning',
              message:"该功能暂未开放",
              duration:this.elDuration
            })
            break;
          case 4: //我的对账
            if (this.loginType == 1) { //客户登录
              this.$router.push({
                path: '/check_view',
                query: {
                  checkType: 5,
                  orderBillCode: 'DOC-C-018'
                }
              })
            } else if (this.loginType == 2) { //供应商登录
              this.$router.push({
                path: '/check_view',
                query: {
                  checkType: 6,
                  purchaseBillCode: 'DOC-C-013'
                }
              })
            }
            break;
          case 5: //发票管理
            if (this.loginType == 1) { //客户登录
              this.$router.push({
                path: '/invoice_view',
                query: {
                  invoiceType: 3,
                  invoiceBillCode: 'DOC-C-009'
                }
              })
            } else if (this.loginType == 2) { //供应商登录
              this.$router.push({
                path: '/invoice_view',
                query: {
                  invoiceType: 4,
                  invoiceBillCode: 'DOC-C-011'
                }
              })
            }
            break;
          case 6: //费用结算
            if (this.loginType == 1) { //客户登录
              this.$router.push({
                path: '/billing_putpay',
                query: {
                  costType: 0,
                  costBillCode: 'DOC-C-004'
                }
              })
            } else if (this.loginType == 2) { //供应商登录
              this.$router.push({
                path: '/billing_putpay',
                query: {
                  costType: 1,
                  costBillCode: 'DOC-C-005'
                }
              })
            }
            break;
          case 7: //我的信息
            this.$message({
              type:'warning',
              message:"该功能暂未开放",
              duration:this.elDuration
            })
            break;
          default:
            break;
        }
      },
    },
    components: {
      topNav
    },
  }
</script>

<style lang="less">
  // 客户主页框
  .customerAppBox {
    display: flex;
    justify-content: space-between;

    // 左边内容
    .leftNav {

      // 头部框
      .headBox {
        height: 52px;
        border-bottom: 0.5px solid #7e7a7a;
      }

      // 头部标题
      .headTitle {
        font-size: 20px;
        display: block;
        text-align: center;
        // border: 1px solid black;
      }
    }

    // 右边内容
    .rightNav {
      width: calc(100% - 208px);

      // border: 1px solid black;
      //顶部样式
      .right_head {
        height: @navbarTop_height;
      }

      .right_content {
        height: calc(100vh - calc(@navbarTop_height + 2px));
        min-height: calc(100vh - calc(@navbarTop_height + 2px));
        overflow: auto;
      }
    }

    //链接字体样式
    a {
      color: #fff;
      text-decoration: none;
    }

    //选中时的a标签样式
    .a_checked {
      color: #ffd04b;
    }

    // 菜单item显示
    .menu_item {
      padding-left: 15px;
    }
  }
</style>
<style lang="less" scoped="scoped">
  /* token过期*/
  .tokenExpiredBox {
    //提示信息总框
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    // border: 1px solid black;
    .tokenExpiredTip {

      //提示信息
      a {
        margin-left: 0px;
        margin-right: 25px;
        color: #fff;
        font-size: 15px;
        height: 50px;
        background: #20a0ff;
        padding: 8px;
        border-radius: 5px;
      }

      a:hover {
        background-color: #4db3ff;
        text-decoration: none;
      }
    }
  }
</style>
