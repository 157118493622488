// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：销售相关信息
// 开始时间：2021-01-19
// 开发人员：刘巍骏
// 最后修改：2021-01-19
// 备注说明：如需修改请联系开发人员

import mutations from "./mutations.js"
import actions from "./actions.js"

const state={

  //常量
}


export default {
  state,
  mutations,
  actions
}
