/**
 * Created by Administrator on 2017/7/26.
 */
import commonJs from '@/lib/common_js.js'
export default {
  //获取国家的信息
  getCountryInfo({
    state
  }) {
    $.ajax({
      type: "get",
      url: "https://plugin.yingyanso.net/area",
      async: false,
      timeout: 5000,
      success: function(res) {
        state.countryArr = []
        if (res.code == 200) {
          $.each(res.data, (i, row) => {
            row.class = row.clazz
            state.countryArr.push(row)
          })
        }
      },
    });
  }
}