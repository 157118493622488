/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：人力模块.数据组件action
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */

import api from '@/network/hr/organization.js'

export default {
  //组织架构
  getDepartmentTree({commit}){//获取部门分类
    return new Promise((resolve,reject)=>{
      api.getDepartmentTree().then(res=>{
        if(res.length>0){
          commit("DEPARTMENTDATA",res);
        }
        resolve(res);
      }).catch(err=>{
        reject(err);
      })
    })
  },
  getAllUserData({commit}){//获取企业所有员工
    return new Promise((resolve,reject)=>{
      api.getAllUserData().then(res=>{
        if(res.length>0){
          commit("PERSONNELDATA",res);
        }
        resolve(res);
      }).catch(err=>{
        reject(err);
      })
    })
  },

  getFirstLevelDeptUser({commit}){//获取一级部门及该部门下的所有员工
    return new Promise((resolve,reject)=>{
      api.findFirstLevelDeptUser().then(res=>{
        if(res.length>0){
          commit("FIRSTLEVELPERSONNELDATA",res);
        }
        resolve(res);
      }).catch(err=>{
        reject(err);
      })
    })
  },

}
