/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：公共方法(同步)js
 * 开始时间：2017-7-26
 * 开发人员：陈立珍
 * 最后修改：2019-7-30
 * 备注说明：如需修改请联系开发人员
 */

export default {
	//加载国家信息
	getCountryList(state) {
		$.ajax({
			type: "POST",
			url: backUrl + "country_getAllCountry.action",
			dataType: "json",
			cache: false,
			async: false,
			data: {
				token: token
			},
			success: function (msg) {
				//判断返回值
				if (msg == null || msg == "" || msg == undefined) {
					state.countrylist = {};
				} else {
					state.countrylist = msg;
				}
			},
			error: function (e) {
				//alert("加载国家信息失败");
			}
		});
	},
	//加载企业控制参数转为map
	getCompanyParamList(state) {
		$.ajax({
			type: "POST",
			url: backUrl + "para_getcompanyparametersmap.action",
			dataType: "json",
			cache: false,
			async: false,
			data: {
				token: token
			},
			success: function (msg) {
				state.companyparamlist = msg;
			},
			error: function (e) {
				//alert("企业控制参数加载失败,请刷新重试!多次失败请联系客服协助处理！");
			}
		});
	},
	//加载企业控制参数
	getCompanyParamLists(state) {
		$.ajax({
			type: "POST",
			url: backUrl + "para_getCompanyParameters.action",
			dataType: "json",
			cache: false,
			async: false,
			data: {
				token: token
			},
			success: function (msg) {
				//判断返回值
				if (msg == null || msg == "" || msg == undefined) {
					state.companyparamlists = {};
				} else {
					state.companyparamlists = msg;
				}
			},
			error: function (e) {
				//alert("企业控制参数加载失败,请刷新重试!多次失败请联系客服协助处理！");
			}
		});
	},
	//加载用户控制参数
	getUserParamList(state) {
		$.ajax({
			type: "POST",
			url: backUrl + "para_getuserparametersmap.action",
			dataType: "json",
			cache: false,
			async: false,
			data: {
				token: token
			},
			success: function (msg) {
				state.userparamlist = msg;
			},
			error: function (e) {
				//alert("用户控制参数加载失败,请刷新重试!多次失败请联系客服协助处理!");
			}
		});
	},
	//加载系统控制参数
	getSystemParamList(state) {
		$.ajax({
			url: backUrl + "xp_findSysParam.action",
			type: "POST",
			cache: false,
			async: false,
			data: {
				token: token
			},
			success: function (msg) {
				//判断返回值
				if (msg == null || msg == "" || msg == undefined) {
					state.systemparamlist = {};
				} else {
					state.systemparamlist = msg;
				}
			},
			error: function (e) {
				//alert("企业控制参数加载失败,请刷新重试!多次失败请联系客服协助处理.");
			}
		});
	},
	//获取系统当前时间
	getCurrentTime(state) {
		//获取当前日期
		var mydate = new Date();
		state.currentyear = mydate.getFullYear().toString();
		state.currentmonth = (mydate.getMonth() + 1).toString();
		state.currentday = mydate.getDate().toString();
		if (state.currentmonth.length == 1) {
			state.currentmonth = "0" + state.currentmonth;
		}
		if (state.currentday.length == 1) {
			state.currentday = "0" + state.currentday;
		}
	},
	//加载用户信息
	getUserInfo(state) {
		$.ajax({
			type: "POST",
			url: backUrl + "xuser_getUserInfo.action",
			dataType: "json",
			cache: false,
			async: false,
			data: {
				token: token
			},
			success: function (msg) {
				state.userinfo = msg;
			},
			error: function (e) {
				//alert("加载用户信息信息,多次失败请联系客服协助处理!");
			}
		});
	},
	//加载企业信息
	getCompanyInfo(state) {
		$.ajax({
			type: "POST",
			url: backUrl + "xcom_findCompanyInfo.action",
			dataType: "json",
			cache: false,
			async: false,
			data: {
				token: token
			},
			success: function (msg) {
				//判断返回值
				if (msg == null || msg == "" || msg == undefined) {
					state.companyinfo = {};
				} else {
					state.companyinfo = msg;
				}
			},
			error: function (e) {
				//alert("加载企业信息失败,请刷新重试!");
			}
		});
	},
	//加载所有用户
	getUserlistAll(state) {
		$.ajax({
			type: "POST",
			url: backUrl + "xuser_getAllUser.action",
			dataType: "json",
			cache: false,
			async: false,
			data: {
				token: token
			},
			success: function (msg) {
				//判断返回值
				if (msg == null || msg == "" || msg == undefined) {
					state.userlistall = {};
				} else {
					state.userlistall = msg;
				}
			},
			error: function (e) {
				//alert("加载所有用户失败,请刷新重试!");
			}
		});
	},
	//加载部门下所有用户
	getUserdeptlistAll(state) {
		$.ajax({
			type: "POST",
			url: backUrl + "xuser_getAllUserByDeptId.action",
			dataType: "json",
			cache: false,
			async: false,
			data: {
				token: token
			},
			success: function (msg) {
				//判断返回值
				if (msg == null || msg == "" || msg == undefined) {
					state.userdeptlistall = {};
				} else {
					state.userdeptlistall = msg;
				}
			},
			error: function (e) {
				//alert("加载部门下所有用户失败,请刷新重试!");
			}
		});
	},
  //当前获取的值
  CUR_COUNTRY(state, value) {
  	state.curCountry = value
  },
  //当前国家的值能否进行编辑
  CUR_COUNTRY_EDIT(state, value) {
  	state.curCountryEdit = value
  },
  //当前从编辑弹框进入】
  FROM_COUNTRY_EDIT(state, value) {
  	state.fromCountryEdit = value
  },
}

