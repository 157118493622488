/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：公共变量
 * 开始时间：2017-7-25
 * 开发人员：陈立珍、陈熙存
 * 最后修改：2019-7-30
 * 备注说明：如需修改请联系开发人员
 */

import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations';
import actions from './action';
import getters from './getters';

// 模块
import user from './user/state.js';//用户相关信息
import mainNavbar from './mainNavbar/state.js';//栏目导航模块
import commComponent from './commComponent/state.js';//公共组件模块
import sell from './sell/state.js';//销售公共数据模块
import billing from './billing/state.js';//单据公共数据模块
import hr from './hr/state.js';//人力模块
import production from './production/state.js';//生产模块
import system from './system/state.js';//系统控制模块
import datum from './datum/state.js';//资料控制模块
import bill_list_config from './config/bill_list_state.js';//单据列表配置模块
import bill_state from './config/bill_state.js';//单据状态配置模块

Vue.use(Vuex);

const state = {
  userparamlist:'',//用户参数集合
  companyparamlist:'',//企业参数集合转为map
  companyparamlists:'',//企业参数集合
  systemparamlist: '', //系统参数集合
  //国家
  countrylist:'',
  countryArr:[],
  curCountry:'',//当前获取的所在国家的值
  curCountryEdit:false,//当前能否编辑
  fromCountryEdit:false,//当期从编辑弹框总进入
}

export default new Vuex.Store({
  modules: {
    'mainNavbar':mainNavbar,
    'user':user,
    'commComponent':commComponent,
    'sell':sell,
    'billing':billing,
    'hr':hr,
    'production':production,
    'system':system,
    'datum':datum,
    'bill_list_config':bill_list_config,
    'bill_state':bill_state,
  },
  state,
  actions,
  mutations,
  getters: {
  }
})
