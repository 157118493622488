// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：配置文件.单据列表配置字段
// 开始时间：2021-01-19
// 开发人员：刘巍骏
// 最后修改：2021-01-19
// 备注说明：如需修改请联系开发人员

const state = {
  //销售列表可显示字段
  saleListFileData: [{
      name: '单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据类型',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据状态',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '收款状态',
      value: 4,
      is_show: false,
      classify: 1,
    },
    {
      name: '发票状态',
      value: 5,
      is_show: false,
      classify: 1,
    },
    {
      name: '出库状态',
      value: 6,
      is_show: false,
      classify: 1,
    },
    {
      name: '客户名称',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据金额',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '优惠金额',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '交货日期',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单人',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '打印次数',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属部门',
      value: 15,
      is_show: false,
      classify: -1,
    },
    {
      name: '业务员',
      value: 16,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单日期',
      value: 17,
      is_show: false,
      classify: -1,
    },
    {
      name: '采购状态',
      value: 18,
      is_show: false,
      classify: 1,
    },
    {
      name: '生产状态',
      value: 19,
      is_show: false,
      classify: 1,
    },
    {
      name: '订单状态',
      value: 20,
      is_show: false,
      classify: 0,
    },
    {
      name: '退货状态',
      value: 21,
      is_show: false,
      classify: 1,
    },
    {
      name: '交易金额',
      value: 22,
      is_show: false,
      classify: 1,
    },
    {
      name: '数量',
      value: 23,
      is_show: false,
      classify: 1,
    },
    {
      name: '源单号',
      value: 24,
      is_show: false,
      classify: 1,
    },
  ],

  //采购列表可显示字段
  purchaseListFileData: [{
      name: '单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据类型',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据状态',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '付款状态',
      value: 4,
      is_show: false,
      classify: 1,
    },
    {
      name: '发票状态',
      value: 5,
      is_show: false,
      classify: 1,
    },
    {
      name: '入库状态',
      value: 6,
      is_show: false,
      classify: 1,
    },
    {
      name: '供应商名称',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据金额',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '入库仓库',
      value: 9,
      is_show: false,
      classify: -1,
    },
    // {
    //   name:'到货日期',
    //   value:10,
    //   is_show:false,
    //   classify:-1,
    // },
    {
      name: '制单人',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '打印次数',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属部门',
      value: 15,
      is_show: false,
      classify: -1,
    },
    {
      name: '业务员',
      value: 16,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单时间',
      value: 17,
      is_show: false,
      classify: -1,
    },
    {
      name: '订单状态',
      value: 18,
      is_show: false,
      classify: 0,
    },
    {
      name: '申请事由',
      value: 19,
      is_show: false,
      classify: 0,
    },
    {
      name: '源单据号',
      value: 20,
      is_show: false,
      classify: -1,
    },
    {
      name: '导出次数',
      value: 21,
      is_show: false,
      classify: -1,
    },
    {
      name: '退货状态',
      value: 22,
      is_show: false,
      classify: 1,
    },
    {
      name: '交易金额',
      value: 23,
      is_show: false,
      classify: 1,
    },
    {
      name: '询价',
      value: 24,
      is_show: false,
      classify: 0,
    },
    {
      name: '数量',
      value: 25,
      is_show: false,
      classify: -1,
    },
  ],

  //出入库列表可显示字段
  depotListFileData: [{
      name: '单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据类型',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据状态',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '客户名称',
      value: 4,
      is_show: false,
      classify: 1,
    },
    {
      name: '供应商名称',
      value: 5,
      is_show: false,
      classify: 0,
    },
    // {
    //   name: '单据销售金额',
    //   value: 6,
    //   is_show: false,
    //   classify: -1,
    // },
    // {
    //   name: '单据成本金额',
    //   value: 7,
    //   is_show: false,
    //   classify: -1,
    // },
    {
      name: '出入库仓',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单人',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '打印次数',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属部门',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '业务员',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单时间',
      value: 15,
      is_show: false,
      classify: -1,
    },
    {
      name: '源单据号',
      value: 16,
      is_show: false,
      classify: -1,
    },
    {
      name: '导出次数',
      value: 17,
      is_show: false,
      classify: -1,
    },
    {
      name: '运输方式',
      value: 18,
      is_show: false,
      classify: -1,
    },
    {
      name: '物流公司',
      value: 19,
      is_show: false,
      classify: -1,
    },
    {
      name: '物流费用',
      value: 20,
      is_show: false,
      classify: -1,
    },
    {
      name: '质检状态',
      value: 21,
      is_show: false,
      classify: -1,
    },
    {
      name: '退款状态',
      value: 22,
      is_show: false,
      classify: 9,
    },
    {
      name: '退货状态',
      value: 23,
      is_show: false,
      classify: 9,
    },
    {
      name: '归还状态',
      value: 24,
      is_show: false,
      classify: 11,
    },
    {
      name: '发票状态',
      value: 25,
      is_show: false,
      classify: 9,
    },
  ],

  //生产计划列表可显示字段
  mesListFileData: [{
      name: '单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    // {
    //   name: '单据类型',
    //   value: 2,
    //   is_show: false,
    //   classify: -1,
    // },
    {
      name: '单据状态',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '领料状态',
      value: 4,
      is_show: false,
      classify: -1,
    },
    {
      name: '入库状态',
      value: 5,
      is_show: false,
      classify: -1,
    },
    {
      name: '付款状态',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '采购状态',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品编号',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品名称',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '规格型号',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '客户名称',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '供应商名称',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '计划数量',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '生产数量',
      value: 14,
      is_show: false,
      classify: -1,
    },
    // {
    //   name: '单据金额',
    //   value: 15,
    //   is_show: false,
    //   classify: -1,
    // },
    {
      name: '源单据号',
      value: 16,
      is_show: false,
      classify: -1,
    },
    {
      name: '源单数量',
      value: 17,
      is_show: false,
      classify: -1,
    },
    {
      name: '计划完工日期',
      value: 18,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单人',
      value: 19,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单时间',
      value: 20,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 21,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 22,
      is_show: false,
      classify: -1,
    },
    {
      name: '完工日期',
      value: 23,
      is_show: false,
      classify: -1,
    },
    {
      name: '采购状态',
      value: 24,
      is_show: false,
      classify: -1,
    },
  ],

  //生产执行列表可显示字段
  mesProcessListFileData: [{
      name: '工序单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '工序名称',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '关联生产单',
      value: 15,
      is_show: false,
      classify: -1,
    },
    {
      name: '关联物料',
      value: 16,
      is_show: false,
      classify: -1,
    },
    {
      name: '派工数量',
      value: 17,
      is_show: false,
      classify: -1,
    },
    {
      name: '合格数量',
      value: 18,
      is_show: false,
      classify: -1,
    },
    {
      name: '剩余数量',
      value: 19,
      is_show: false,
      classify: -1,
    },
    {
      name: '报废数量',
      value: 20,
      is_show: false,
      classify: -1,
    },
    
    {
      name: '单据状态',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '工序状态',
      value: 4,
      is_show: false,
      classify: -1,
    },
    {
      name: '领料状态',
      value: 5,
      is_show: false,
      classify: -1,
    },
    {
      name: '付款状态',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '生产类型',
      value: 23,
      is_show: false,
      classify: -1,
    },
    {
      name: '合计用时',
      value: 21,
      is_show: false,
      classify: -1,
    },
    {
      name: '开始时间',
      value: 24,
      is_show: false,
      classify: -1,
    },
    {
      name: '结束时间',
      value: 25,
      is_show: false,
      classify: -1,
    },
    {
      name: '计费方式',
      value: 22,
      is_show: false,
      classify: -1,
    },
    {
      name: '负责人',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '负责部门',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单人',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单时间',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '采购状态',
      value: 7,
      is_show: false,
      classify: -1,
    },
    // {
    //   name: '工序金额',
    //   value: 8,
    //   is_show: false,
    //   classify: -1,
    // },

  ],

  //收付款列表可显示字段
  costListFileData: [{
      name: '单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据类型',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据状态',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '客户名称',
      value: 4,
      is_show: false,
      classify: 0,
    },
    {
      name: '供应商名称',
      value: 5,
      is_show: false,
      classify: 1,
    },
    {
      name: '单据金额',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单人',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单时间',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属部门',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属员工',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '打印次数',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '导出次数',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '备注',
      value: 15,
      is_show: false,
      classify: -1,
    },
  ],

  //费用报销列表可显示字段
  expenseListFileData: [
    {
      name: '单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据类型',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据状态',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '结算状态',
      value: 4,
      is_show: false,
      classify: 0,
    },
    {
      name: '客户名称',
      value: 5,
      is_show: false,
      classify: 1,
    },
    {
      name: '供应商名称',
      value: 6,
      is_show: false,
      classify: 1,
    },
    {
      name: '单据金额',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单人',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单时间',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属部门',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属员工',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '打印次数',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '导出次数',
      value: 15,
      is_show: false,
      classify: -1,
    },
    {
      name: '备注',
      value: 16,
      is_show: false,
      classify: -1,
    },
  ],

  //发票列表可显示字段
  invoiceListFileData: [
    {
      name: '单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据类型',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据状态',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '客户名称',
      value: 4,
      is_show: false,
      classify: 3,
    },
    {
      name: '供应商名称',
      value: 5,
      is_show: false,
      classify: 4,
    },
    {
      name: '单据金额',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单人',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单时间',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属部门',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属员工',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '打印次数',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '导出次数',
      value: 14,
      is_show: false,
      classify: -1,
    },
  ],

  //对账单列表可显示字段
  checkListFileData: [
    {
      name: '单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据类型',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '单据状态',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '发票状态',
      value: 16,
      is_show: false,
      classify: -1,
    },
    {
      name: '客户名称',
      value: 4,
      is_show: false,
      classify: 3,
    },
    {
      name: '供应商名称',
      value: 5,
      is_show: false,
      classify: 4,
    },
    {
      name: '单据金额',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单人',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '制单时间',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属部门',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '所属员工',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '打印次数',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '导出次数',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '备注',
      value: 15,
      is_show: false,
      classify: -1,
    },
    //最后一个非15,向上找
  ],

  //货品列表可显示字段
  productListFileData: [
    {
      name: '货品编号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品名称',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品规格',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品属性',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品来源',
      value: 4,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品品牌',
      value: 5,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核状态',
      value: 26,
      is_show: false,
      classify: -1,
    },
    {
      name: '库存数',
      value: 22,
      is_show: false,
      classify: -1,
    },
    {
      name: '成本单价',
      value: 30,
      is_show: false,
      classify: -1,
    },
    {
      name: '销售单价',
      value: 29,
      is_show: false,
      classify: -1,
    },
    {
      name: '起订量',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '单个重量',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '报价数',
      value: 20,
      is_show: false,
      classify: -1,
    },
    {
      name: '订单数',
      value: 21,
      is_show: false,
      classify: -1,
    },
    {
      name: '外购数',
      value: 23,
      is_show: false,
      classify: -1,
    },
    {
      name: '生产中',
      value: 24,
      is_show: false,
      classify: -1,
    },
    {
      name: '已生产',
      value: 25,
      is_show: false,
      classify: -1,
    },
    {
      name: '供应商名称',
      value: 6,
      is_show: false,
      classify: -1,
    },
    // {
    //   name: '销售单价',
    //   value: 7,
    //   is_show: false,
    //   classify: -1,
    // },
    // {
    //   name: '采购单价',
    //   value: 8,
    //   is_show: false,
    //   classify: -1,
    // },
    {
      name: '货品简介',
      value: 9,
      is_show: false,
      classify: -1,
    },
    // {
    //   name: '计量单位',
    //   value: 10,
    //   is_show: false,
    //   classify: -1,
    // },
    // {
    //   name: '价格单位',
    //   value: 11,
    //   is_show: false,
    //   classify: -1,
    // },

    // {
    //   name: '是否可组装',
    //   value: 14,
    //   is_show: false,
    //   classify: -1,
    // },
    // {
    //   name: '是否可拆卸',
    //   value: 15,
    //   is_show: false,
    //   classify: -1,
    // },
    {
      name: '下架状态',
      value: 28,
      is_show: false,
      classify: -1,
    },
    {
      name: '创建人',
      value: 16,
      is_show: false,
      classify: -1,
    },
    {
      name: '创建时间',
      value: 17,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 18,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 19,
      is_show: false,
      classify: -1,
    },
    // {
    //   name: '样单数',
    //   value: 20,
    //   is_show: false,
    //   classify: -1,
    // },


    {
      name: '原货品编码',
      value: 27,
      is_show: false,
      classify: -1,
    },


  ],

  //bom版本列表可显示字段
  bomListFileData: [
    {
      name: '审核状态',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品编号',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '旧货品编号',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品名称',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '规格型号',
      value: 4,
      is_show: false,
      classify: 1,
    },
    {
      name: '物料数量',
      value: 5,
      is_show: false,
      classify: 1,
    },
    {
      name: '版本号',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '版本日期',
      value: 7,
      is_show: false,
      classify: -1,
    },
    // {
    //   name: '版本属性',
    //   value: 8,
    //   is_show: false,
    //   classify: -1,
    // },
    {
      name: '创建人',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '创建时间',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核人',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '审核时间',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '最后修改时间',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '打印次数',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '导出次数',
      value: 15,
      is_show: false,
      classify: -1,
    },
  ],

  //bom新建物料列表可显示字段
  bomMaterialListFileData: [
    {
      name: '物料编码',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '物料名称',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '物料规格',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '计量单位',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '所需数量',
      value: 4,
      is_show: false,
      classify: 1,
    },
    {
      name: '未分配数量',
      value: 5,
      is_show: false,
      classify: 1,
    },
    {
      name: '已分配数量',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '成本价',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '所需总费用',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '报损率',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '位号',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '倒冲',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '可替换物料',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '备注',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '旧物料编码',
      value: 14,
      is_show: false,
      classify: -1,
    },
  ],

  //bom新建工序列表可显示字段
  bomProcessListFileData: [
    {
      name: '工序名称',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '物料设置',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '负责部门',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '负责人',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '生产类别',
      value: 4,
      is_show: false,
      classify: 1,
    },
    {
      name: '计费方式',
      value: 5,
      is_show: false,
      classify: 1,
    },
    {
      name: '工价',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '参考用时',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '工序图纸',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '备注',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '合成物料',
      value: 10,
      is_show: false,
      classify: -1,
    },
  ],

  //供应商列表可显示字段
  supplierListFileData: [
    {
      name: '供应商编号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '供应商名称',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '待付款总额',
      value: 2,
      is_show: false,
      classify: -1,
    },
    // {
    //   name: '供应商简称',
    //   value: 3,
    //   is_show: false,
    //   classify: -1,
    // },
    {
      name: '邮箱',
      value: 4,
      is_show: false,
      classify: 1,
    },
    {
      name: '所在国家',
      value: 5,
      is_show: false,
      classify: 1,
    },
    {
      name: '所在省份',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '所在城市',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '详细地址',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '法人姓名',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '公司网址',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '联系人姓名',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '税率(%)',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '汇率(%)',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '创建时间',
      value: 14,
      is_show: false,
      classify: -1,
    },
  ],

  //客户列表可显示字段
  customerListFileData: [
    {
      name: '客户编号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '客户名称',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '待收款总额',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '邮箱',
      value: 3,
      is_show: false,
      classify: 1,
    },
    {
      name: '所在国家',
      value: 4,
      is_show: false,
      classify: 1,
    },
    {
      name: '所在省份',
      value: 5,
      is_show: false,
      classify: -1,
    },
    {
      name: '所在城市',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '详细地址',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '公司网址',
      value: 8,
      is_show: false,
      classify: -1,
    },
    // {
    //   name: '联系人姓名',
    //   value: 9,
    //   is_show: false,
    //   classify: -1,
    // },
    {
      name: '税率(%)',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '汇率(%)',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '录入时间',
      value: 12,
      is_show: false,
      classify: -1,
    },
  ],

  //客户对账单源单明细
  checkCustomerListFileData: [
    {
      name: '销售单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '订单日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '客户订单号',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '送货单号',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '送货日期',
      value: 4,
      is_show: false,
      classify: -1,
    },
    {
      name: '物料编号',
      value: 5,
      is_show: false,
      classify: -1,
    },
    {
      name: '客户物料编号',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品名称',
      value: 17,
      is_show: false,
      classify: -1,
    },
    {
      name: '规格名称',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '计量单位',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '单价',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '订单数量',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '交易数量',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '订单金额',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '应收金额',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '实收金额',
      value: 18,
      is_show: false,
      classify: -1,
    },
    {
      name: '含税金额',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '付款状态',
      value: 15,
      is_show: false,
      classify: -1,
    },
    {
      name: '备注',
      value: 16,
      is_show: false,
      classify: -1,
    },
    //添加信息注意最大value
  ],

  //供应商对账单源单明细
  checkSupplierListFileData: [
    {
      name: '采购单号',
      value: 0,
      is_show: false,
      classify: -1,
    },
    {
      name: '订单日期',
      value: 1,
      is_show: false,
      classify: -1,
    },
    {
      name: '送货单号',
      value: 2,
      is_show: false,
      classify: -1,
    },
    {
      name: '送货日期',
      value: 3,
      is_show: false,
      classify: -1,
    },
    {
      name: '物料编号',
      value: 4,
      is_show: false,
      classify: -1,
    },
    {
      name: '供应商物料编号',
      value: 5,
      is_show: false,
      classify: -1,
    },
    {
      name: '货品名称',
      value: 16,
      is_show: false,
      classify: -1,
    },
    {
      name: '规格名称',
      value: 6,
      is_show: false,
      classify: -1,
    },
    {
      name: '计量单位',
      value: 7,
      is_show: false,
      classify: -1,
    },
    {
      name: '单价',
      value: 8,
      is_show: false,
      classify: -1,
    },
    {
      name: '订单数量',
      value: 9,
      is_show: false,
      classify: -1,
    },
    {
      name: '交易数量',
      value: 10,
      is_show: false,
      classify: -1,
    },
    {
      name: '订单金额',
      value: 11,
      is_show: false,
      classify: -1,
    },
    {
      name: '应付金额',
      value: 12,
      is_show: false,
      classify: -1,
    },
    {
      name: '实付金额',
      value: 17,
      is_show: false,
      classify: -1,
    },
    {
      name: '含税金额',
      value: 13,
      is_show: false,
      classify: -1,
    },
    {
      name: '付款状态',
      value: 14,
      is_show: false,
      classify: -1,
    },
    {
      name: '备注',
      value: 15,
      is_show: false,
      classify: -1,
    },
    //添加信息注意最大value
  ],
}


export default {
  state
}
