// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：个性化配置相关请求接口
// 开始时间：2021-03-29
// 开发人员：万攀，康泽鑫
// 最后修改：2021-08-03
// 备注说明：如需修改请联系开发人员


import {
  request
} from "../request.js";
import qs from 'qs';

export default {

  /* 单据模板相关 */
  getelectronicTemplate() { //查询当前用户下所有导出模板
    return request({
      method: 'POST',
      url: '/system/findAllTemplate'
    })
  },
  addBillTemplate(data) { //增加单据模板
    return request({
      method: 'POST',
      url: '/system/addBillTemplate',
      data
    })
  },
  deleteBillTemplateByid(data) { //删除单据模板
    return request({
      method: 'POST',
      url: '/system/deleteBillTemplateByid',
      data
    })
  },
  updateBillTemplate(data) { //修改单据模板
    return request({
      method: 'POST',
      url: '/system/updateBillTemplate',
      data
    })
  },
  ExportMouldupload(data) { //上传单据模板
    return request({
      method: 'POST',
      url: '/system/ExportMouldUploadEasyExcel',
      data
    })
  },
  findExportMouldById(data) { //根据上传模板主键id查询模板
    return request({
      method: 'POST',
      url: '/system/findBillTemplateById',
      data
    })
  },
  updateBillTemplate(data) { //修改单据模板
    return request({
      method: 'POST',
      url: '/system/updateBillTemplate',
      data
    })
  },
  findTemplateIdByCode(data) { //根据code查询单据类型数据
    return request({
      method: 'POST',
      url: '/system/findTemplateIdByCode',
      data
    })
  },
  findByElectronicTemplateId(data) { //根据code查询单据类型数据
    return request({
      method: 'POST',
      url: '/system/findByElectronicTemplateId',
      data
    })
  },
  findOrderTaskByOrderId(data) { //查询单据已生成文件
    return request({
      method: 'POST',
      url: '/orderCommon/findOrderTaskByOrderId',
      data
    })
  },


  //货品编码
  findAllProductTypeNoChild() { //查询货品分类(非父子级)
    return request({
      method: 'POST',
      url: '/system/dictionary/findAllProductTypeNoChild',
    })
  },
  updateProductCodeTemp(data) { //修改货品编码规则
    return request({
      method: 'POST',
      url: '/system/updateProductCodeTemp',
      data
    })
  },
  updateIsClassfiySet(data) { //货品编码规则是否分类设置
    return request({
      method: 'POST',
      url: '/system/updateIsClassfiySet',
      data
    })
  },

  /* 菜单配置 */
  findAllMenuConfig() { //查询所有菜单配置
    return request({
      method: 'POST',
      url: '/system/findAllMenuConfig',
    })
  },
  deleteSysMenuConfig(data) { //删除菜单配置
    return request({
      method: 'POST',
      url: '/system/deleteSysMenuConfig',
      data
    })
  },
  insertSysMenuConfig(data) { //添加菜单配置
    return request({
      method: 'POST',
      url: '/system/insertSysMenuConfig',
      data
    })
  },
  updateSort(data) { //修改菜单配置排序
    return request({
      method: 'POST',
      url: '/system/updateSort',
      data
    })
  },
  updateSysMenuConfig(data) { //修改菜单配置
    return request({
      method: 'POST',
      url: '/system/updateSysMenuConfig',
      data
    })
  },
  findByTypeId(data) { //根据类型查询单据模板信息
    return request({
      method: 'POST',
      url: '/system/findByTypeId',
      data
    })
  },
  deleteCrmOrderExportMould(data) { //删除单据模板
    return request({
      method: 'POST',
      url: '/system/deleteCrmOrderExportMould',
      data
    })
  },
}
