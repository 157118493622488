/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：系统·公共变量
 * 开始时间：2019-8-4
 * 开发人员：陈立珍
 * 最后修改：2019-8-4
 * 备注说明：如需修改请联系开发人员
 */

import mutations from "./mutations.js";


const state = {
  // 货品管理相关
  productType:false,//货品管理类型
  productTabIndex:0,//新增货品选择弹框页面下标
  productIsBillsUsed:false,//货品是否已被其它单据使用

  //货品信息弹框数据(新增,修改)
  productData:{
    basicData:{},//基本信息
    tableData_sellSection:[],//区间价格
    specificationsData:[],//规格信息
    stockData:[],//库存信息
    productImgData:[],//产品图片
    detailData:'',//详细描述
    otherInformationData:[],//其他信息
  },
  currentUpdateProduct:{},//当前修改组件数据

  //价格管理tab页相关
  productTypeMoreUtil:[],//货品分类设置的多计量数据(选择货品分类后获取)
  mainMeasureData:{},//货品主计量数据
  assisMeasureData:[],//货品辅计量数据
  deleteMeasureData:[],//修改界面删除的计量数据
  saleAssisUnit:'',//销售强制使用计量单位
  saleAssisUnitName:'',//销售强制使用计量单位(名称)
  purchaseAssisUnit:'',//采购强制使用计量单位
  purchaseAssisUnitName:'',//采购强制使用计量单位(名称)
  bomAssisUnit:'',//bom使用计量单位
  baseUnitData:{},//默认计量单位(根据货品设置多计量时使用)

  //货品库存相关
  addInventoryPlace:[],//修改界面新增的仓位号信息
  deleteInventoryPlace:[],//修改界面删除的仓位号信息

  //包装信息
  productPackage:{},//包装信息

  // 货品规格相关
  specsData:[],//数据规格

  show_addBox:false, //控制新增弹窗
  is_cancel:false,//关闭货品弹框时是否为取消
}


export default {
  state,
  mutations
}
