module.exports = {
    login:{
        'title' : '标题',
        'username' : '请输入用户名',
        'password' : '请输入密码',
    },
    title:{
      'language':'语言',
    },
    menu:{
      'ls':'工作'
    }
}