/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：系统管理action
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */

import api from '@/network/system/dictionary.js';
import workApi from '@/network/system/workflowSetup.js';
import indiApi from '@/network/system/individuation.js';

export default {
  //客户相关
  getCustomerClassify({
    commit
  }) { //获取客户分类
    return new Promise((resolve, reject) => {
      api.customerDict.getCustomerClassfiy().then(res => {
        if (res.code == 200) {
          commit("CUSTOMER_CLASSIFY", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getCustomerState({
    commit
  }) { //获取客户状态
    return new Promise((resolve, reject) => {
      api.customerDict.getCustomerState().then(res => {
        if (res.code == 200) {
          commit("CUSTOMER_STATE", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getCustomerSource({
    commit
  }) { //获取客户来源
    return new Promise((resolve, reject) => {
      api.customerDict.getCustomerSource().then(res => {
        if (res.code == 200) {
          commit("CUSTOMER_SOURCE", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getCustomerRelation({
    commit
  }) { //获取客户关系
    return new Promise((resolve, reject) => {
      api.customerDict.getCustomerRelation().then(res => {
        if (res.code == 200) {
          commit("CUSTOMER_RELATION", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getCustomerSell({
    commit
  }) { //获取销售进程
    return new Promise((resolve, reject) => {
      api.customerDict.getCustomerSell().then(res => {
        if (res.code == 200) {
          commit("CUSTOMER_PROCESS", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getCustomerFollowupWay({
    commit
  }) { //获取跟进方式
    return new Promise((resolve, reject) => {
      api.customerDict.getCustomerFollowupWay().then(res => {
        if (res.code == 200) {
          commit("CUSTOMER_FOLLOWUPWAY", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },

  //财务相关
  getFinanceCurrency({
    commit
  }) { //获取交易币种
    return new Promise((resolve, reject) => {
      api.financeDict.getFinanceCurrency().then(res => {
        if (res.code == 200) {
          commit("FINANCE_CURRENCY", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getSysCostBankInfo({
    commit
  }) { //获取收款账号
    return new Promise((resolve, reject) => {
      api.financeDict.findAllSysCostBankInfo().then(res => {
        if (res.code == 200) {
          let bankInfoData = [];
          // 循环获取可以在项目中显示的账号信息
          res.data.forEach((item, index) => {
            //判断数据类型
            if (item.account_type == 0 || item.account_type == 1) { //个人账号或企业账号
              //判断付款账号是否为空
              if (!!item.account_num) {
                item.name = item.company_bank + ":****" + item.account_num.substring((item.account_num
                  .length - 4), item.account_num.length)
              }
              //判断具体类型
              if (item.account_type == 0) { //个人
                item.name = "(个人)" + item.name;
              } else if (item.account_type == 1) { //公司
                item.name = "(公司)" + item.name;
              }
            } else if (item.account_type == 2) { //工具
              if (!!item.other_account_num) {
                item.name ="(工具)" + item.other_name + ":****" + item.other_account_num.substring((item.other_account_num
                  .length - 4), item.other_account_num.length)
              }
            }
            bankInfoData.push(item)
          })
          commit("FINANCE_COSTBANKINFO", bankInfoData);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllCostInvoiceType({
    commit
  }) { //获取发票分类
    return new Promise((resolve, reject) => {
      api.financeDict.findAllCostInvoiceType().then(res => {

        if (res.code == 200) {
          commit("FINANCE_COSTINVOICETYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllCostType({
    commit
  }) { //获取费用分类
    return new Promise((resolve, reject) => {
      api.financeDict.findAllCostType().then(res => {

        if (res.code == 200) {
          commit("FINANCE_COSTTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllPaymentType({
    commit
  }) { //获取收款类型
    return new Promise((resolve, reject) => {
      api.financeDict.findAllPaymentType().then(res => {

        if (res.code == 200) {
          commit("FINANCE_PAYMENTTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  //生产相关
  getAllBomType({
    commit
  }) { //获取bom分类
    return new Promise((resolve, reject) => {
      api.production.findAllBomType().then(res => {

        if (res.code == 200) {
          commit("PRODUCTION_BOMTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllMesCostType({
    commit
  }) { //获取计费方式
    return new Promise((resolve, reject) => {
      api.production.findAllMesCostType().then(res => {
        if (res.code == 200) {
          commit("PRODUCTION_COSTTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllProcessType({
    commit
  }) { //获取工序类型
    return new Promise((resolve, reject) => {
      api.production.findAllProcessType().then(res => {
        if (res.code == 200) {
          commit("PRODUCTION_PROCESSTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },

  getAllManufactureType({
    commit
  }) { //获取生产类型
    return new Promise((resolve, reject) => {
      api.production.findAllManufactureType().then(res => {
        if (res.code == 200) {
          commit("PRODUCTION_MANUFACTURETYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  //获取加工单位
  getAllMesProcessUnit({
    commit
  }) { 
    return new Promise((resolve, reject) => {
      api.production.findAllMesProcessUnit().then(res => {
        if (res.code == 200) {
          commit("PROCESS_UNIT", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },

  //供应商相关
  // 获取供应商分类
  getSupplierClassfiy({
    commit
  }) { //获取供应商类别
    return new Promise((resolve, reject) => {
      api.supplierDict.getSupplierClassfiy().then(res => {
        if (res.code == 200) {
          commit("SUPPLIER_SUPPLIERTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllSupplierMember({
    commit
  }) { //获取会员等级
    return new Promise((resolve, reject) => {
      api.supplierDict.findAllSupplierMember().then(res => {
        if (res.code == 200) {
          commit("SUPPLIER_MEMBERSHIP", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  //货品相关
  getProductUnit({
    commit
  }) { //获取计量单位
    return new Promise((resolve, reject) => {
      api.productDict.getProductUnit().then(res => {
        if (res.code == 200) {
          commit("PRODUCT_UNIT", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getProductProperty({
    commit
  }) { //获取货品属性
    return new Promise((resolve, reject) => {
      api.productDict.findAllProductProperty().then(res => {
        if (res.code == 200) {
          commit("PRODUCT_PROPERTIES", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllBrand({
    commit
  }) { //获取货品品牌
    return new Promise((resolve, reject) => {
      api.productDict.findAllBrand().then(res => {
        if (res.code == 200) {
          commit("PRODUCT_BRAND", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllProductSource({
    commit
  }) { //获取货品来源
    return new Promise((resolve, reject) => {
      api.productDict.findAllProductSource().then(res => {
        if (res.code == 200) {
          commit("PRODUCT_SOURCE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getProductSpecification({
    commit
  }) { //获取规格名称
    return new Promise((resolve, reject) => {
      api.productDict.getProductSpecification().then(res => {
        if (res.code == 200) {
          commit("PRODUCT_SPECIFICATION", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getProductClassfiy({
    commit
  }) { //获取货品分类
    return new Promise((resolve, reject) => {
      api.productDict.getProductClassfiy().then(res => {
        if (res.code == 200) {
          commit("PRODUCT_CLASSFIY", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },

  //销售相关
  getAllOrderDocumentType({
    commit
  }) { //获取单据类型
    return new Promise((resolve, reject) => {
      api.sales.findAllOrderDocumentType().then(res => {
        if (res.code == 200) {
          commit("SALES_ORDERDOCUMENTTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllOrderType({
    commit
  }) { //获取报价类型
    return new Promise((resolve, reject) => {
      api.sales.findAllOrderType().then(res => {
        if (res.code == 200) {
          commit("SALES_ORDERTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllSupplierSettlement({
    commit
  }) { //获取结算方式
    return new Promise((resolve, reject) => {
      api.sales.findAllSupplierSettlement().then(res => {
        if (res.code == 200) {
          commit("SALES_SETTLEMENT", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getPurchaseType({
    commit
  }) { //获取采购类型
    return new Promise((resolve, reject) => {
      api.sales.getPurchaseType().then(res => {
        if (res.code == 200) {
          commit("PURCHASETYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getPriceClause({
    commit
  }) { //获取价格条款
    return new Promise((resolve, reject) => {
      api.sales.getPriceClause().then(res => {
        if (res.code == 200) {
          commit("PRICECLAUSE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getPaymentMode({
    commit
  }) { //获取付汇方式
    return new Promise((resolve, reject) => {
      api.sales.getPaymentMode().then(res => {
        if (res.code == 200) {
          commit("PAYMENTMODE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  //仓储相关
  getQualityType({
    commit
  }) { //获取质检类型
    return new Promise((resolve, reject) => {
      api.storageDict.findAllQualityType().then(res => {
        if (res.code == 200) {
          commit("LOGISTICS_QUALITYTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getTestWay({
    commit
  }) { //获取检验方式
    return new Promise((resolve, reject) => {
      api.storageDict.findAllTestWay().then(res => {
        if (res.code == 200) {
          commit("LOGISTICS_TESTWAY", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getDeliveryType({
    commit
  }) { //获取送货方式
    return new Promise((resolve, reject) => {
      api.storageDict.findAllDeliveryType().then(res => {
        if (res.code == 200) {
          commit("LOGISTICS_DELICERYTYPE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getAllCompany({
    commit
  }) { //获取物流公司
    return new Promise((resolve, reject) => {
      api.storageDict.findAllSysDepotLogisticCompany().then(res => {
        if (res.code == 200) {
          commit("LOGISTICS_COMPANY", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getLogisticsMode({
    commit
  }) { //获取物流方式
    return new Promise((resolve, reject) => {
      api.storageDict.findAllSysDepotLogisticWayService().then(res => {
        if (res.code == 200) {
          commit("LOGISTICS_MODE", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
  getWarehouseConfig({
    commit
  }) { //获取仓库配置
    return new Promise((resolve, reject) => {
      api.storageDict.findAllWarehouse().then(res => {
        if (res.code == 200) {
          commit("WAREHOUSE_CONFIG", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },

  //节点流程相关
  getAllNodeType({
    commit
  }) { //获取所有节点分类数据
    return new Promise((resolve, reject) => {
      workApi.getNodeClassfiy().then(res => {
        if (res.code == 200) {
          commit("NODECLASSFIY", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },

  // 单据模板相关
  getelectronicTemplate({
    state,
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      indiApi.getelectronicTemplate().then(res => {
        if (res.code == 200) {
          commit("BILLS_TEMPLATE_DATA", res.data)
          // 循环获取单据模板分类数据
          // for(let i =0;i<data.length;i++){
          //   for(let j=0;j<res.data.length;j++){
          //     // 根据模板id获取分类数据
          //     if(data[i].id == res.data[j].temp_id){
          //       if(data[i].code == "SYS-NODE-TYPE-001"){//销售数据
          //         commit("SALE_BILL_TYPE", res.data[j].children);
          //       }else if(data[i].code == "SYS-NODE-TYPE-002"){//采购数据
          //         commit("PURCHASE_BILL_TYPE", res.data[j].children);
          //       }else if(data[i].code == "SYS-NODE-TYPE-003"){//生产数据
          //         commit("PRODUCT_BILL_TYPE", res.data[j].children);
          //       }else if(data[i].code == "SYS-NODE-TYPE-004"){//仓库数据
          //         commit("DEPOT_BILL_TYPE", res.data[j].children);
          //       }else if(data[i].code == "SYS-NODE-TYPE-005"){//资金数据
          //         commit("COST_BILL_TYPE", res.data[j].children);
          //       }else if(data[i].code == "SYS-NODE-TYPE-006"){//财务数据
          //         commit("COST_BILL_TYPE", res.data[j].children);
          //       }
          //     }else{
          //       continue
          //     }
          //   }
          // }
          // console.log(res.data);
          // commit("NODECLASSFIY", res.data);
        }
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  },
}
