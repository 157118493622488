/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：导航·同步公共方法js
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */
const mutation = {
  // 控制导航栏是否展开
  ShowNavbar_Left(state, value) { //左侧导航栏
    state.showNavbar_left = value;
  },
  ShowNavbar_Top(state, value) { //顶部导航栏
    state.showNavbar_top = value;
  },

  //顶部导航栏内容
  CURMBS_NAME(state, value) {
    state.crumbs_name = value;
  },

  //菜单配置
  MENU_CONFIG(state, value) {
    state.menu_config = value;
  },
}

export default mutation;
