// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：货品相关请求接口
// 开始时间：2021-5-3
// 开发人员：万攀
// 最后修改：2021-5-3
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default {
  //供应商相关
  findSupplierByCondition(data){
    return request({
      method: 'POST',
      url: '/datainfo/supplier/findSupplierByCondition',
      data,
    })
  },
  addSupplierAllData(data){//新增供应商信息(主信息,期初,信誉额)
    return request({
      method: 'POST',
      url: '/datainfo/supplier/addSupplierAllData',
      data,
    })
  },
  updateSupplierAllData(data){//新增供应商信息(主信息,期初,信誉额)
    return request({
      method: 'POST',
      url: '/datainfo/supplier/updateSupplierAllData',
      data,
    })
  },
  updateSupplierDeleteFlag(data){//作废供应商
    return request({
      method: 'POST',
      url: '/datainfo/supplier/updateSupplierDeleteFlag',
      data,
    })
  },
  loginBySupplier(data){//供应商账号密码登录系统
    return request({
      method: 'POST',
      url: '/datainfo/supplier/loginBySupplier',
      data,
    })
  },
  findSupplierById(data){//根据供应商id获取供应商信息
    return request({
      method: 'POST',
      url: '/datainfo/supplier/findSupplierById',
      data,
    })
  },
  findImportSupplierList(data) { //查询供应商导入任务
    return request({
      method: 'POST',
      url: '/datainfo/supplier/findImportSupplierList',
      data,
    })
  },
  addSupplierByImport(data) { //添加导入供应商任务
    return request({
      method: 'POST',
      url: '/datainfo/supplier/addSupplierByImport',
      data,
    })
  },
  findImportSupplierErrors(data){//查询供应商导入的记录详情
    return request({
      method: 'POST',
      url: '/datainfo/supplier/findImportSupplierErrors',
      data,
    })
  },
  getImportSupplierResult(data){//查询某个供应商导入任务的导入结果
    return request({
      method: 'POST',
      url: '/datainfo/supplier/getImportSupplierResult',
      data,
    })
  },
}
