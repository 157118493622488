module.exports = {
  login:{
      'title' : 'this title',
      'username' : 'Please enter the user name',
      'password' : 'Please enter your password',
  },
  title:{
    'language':'language',
  },
  menu:{
    'ls':'work'
  }
}