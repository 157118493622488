// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：配置文件.单据状态配置字段
// 开始时间：2022-02-19
// 开发人员：刘巍骏
// 最后修改：2022-02-19
// 备注说明：如需修改请联系开发人员

const state = {
  // 单据状态
  billStateList:[
    {
      name:'未提交',
      value:0,
      class:'info'
    },
    {
      name:'初审中',
      value:1,
      class:'warning'
    },
    {
      name:'已驳回',
      value:2,
      class:'danger'
    },
    {
      name:'复审中',
      value:3,
      class:'primary'
    },
    {
      name:'终审中',
      value:5,
      class:'primary'
    },
    {
      name:'已审核',
      value:4,
      class:'success'
    },
  ],

  //生成状态(报价单,采购申请单)
  generateState:[
    {
      name:'未生成',
      value:0,
      class:'danger'
    },
    {
      name:'待生成',
      value:3,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'生成中',
      value:1,
      class:'primary'
    },
    {
      name:'已生成',
      value:2,
      class:'success'
    },

  ],

  /* *************************生产 ************************* */
  //生产状态(订单)
  mesState:[
    {
      name:'未排单',
      value:0,
      class:'danger'
    },
    {
      name:'待排单',
      value:4,
      class:'warning',

    },
    {
      name:'排产中',
      value:5,
      class:'warning',
      color:'#f206cbba',

    },
    {
      name:'生产中',
      value:1,
      class:'primary',

    },
    {
      name:'已生产',
      value:2,
      class:'success'
    },
    {
      name:'不生产',
      value:3,
      class:'success',
      color:'#c0c4cc',
    },

  ],
  // 领料状态
  materialStateGet:[
    {
      name:'未领料',
      value:0,
      class:'danger'
    },
    {
      name:'待领料',
      value:3,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'领料中',
      value:1,
      class:'primary'
    },
    {
      name:'已领料',
      value:2,
      class:'success'
    },

  ],
  // 退料状态
  returnStateReturn:[
    {
      name:'未退料',
      value:0,
      class:'danger'
    },
    {
      name:'退料中',
      value:1,
      class:'primary'
    },
    {
      name:'已退料',
      value:2,
      class:'success'
    },
  ],

  /* *************************库存************************* */
  // 入库状态
  depotStateIn:[
    {
      name:'未入库',
      value:0,
      class:'danger'
    },
    {
      name:'待入库',
      value:3,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'入库中',
      value:1,
      class:'primary'
    },
    {
      name:'已入库',
      value:2,
      class:'success'
    },


  ],

  // 出库状态
  depotStateOut:[
    {
      name:'未出库',
      value:0,
      class:'danger'
    },
    {
      name:'待出库',
      value:3,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'出库中',
      value:1,
      class:'primary'
    },
    {
      name:'已出库',
      value:2,
      class:'success'
    },

  ],

  //退货状态(订单,采购)
  returnState:[
    {
      name:'无退货',
      value:0,
      class:'success',
      color:'#c0c4cc'
    },
    {
      name:'待退货',
      value:3,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'退货中',
      value:1,
      class:'primary'
    },
    {
      name:'已退货',
      value:2,
      class:'success'
    },

  ],

  //质检状态(库存)
  qualityState:[
    {
      name:'未质检',
      value:0,
      class:'danger'
    },
    {
      name:'待质检',
      value:3,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'质检中',
      value:1,
      class:'primary'
    },
    {
      name:'已质检',
      value:2,
      class:'success'
    },

    {
      name:'不质检',
      value:4,
      class:'success'
    }
  ],

  //归还状态(库存)
  backState:[
    {
      name:'无归还',
      value:0,
      class:'danger'
    },
    {
      name:'待归还',
      value:3,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'归还中',
      value:1,
      class:'primary'
    },
    {
      name:'已归还',
      value:2,
      class:'success'
    },

  ],

  /* *************************采购************************* */
  //采购状态(订单,生产)
  purchaseState:[
    {
      name:'未采购',
      value:0,
      class:'danger'
    },
    {
      name:'待采购',
      value:4,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'采购中',
      value:1,
      class:'primary'
    },
    {
      name:'已采购',
      value:2,
      class:'success'
    },
    {
      name:'不采购',
      value:3,
      class:'success',
      color:'#c0c4cc',
    },

  ],
//请购状态(订单,生产)
  purchaseMesState:[
    {
      name:'未请购',
      value:0,
      class:'danger'
    },
    {
      name:'待请购',
      value:4,
      class:'warning',
    },
    {
      name:'请购中',
      value:1,
      class:'primary'
    },
    {
      name:'已请购',
      value:2,
      class:'success'
    },
    {
      name:'不请购',
      value:3,
      class:'success',
      color:'#c0c4cc',
    },

  ],
  //询价状态(采购)
  enquiryState:[
    {
      name:'无询价',
      value:0,
      class:'danger'
    },
    {
      name:'有询价',
      value:1,
      class:'succes'
    },
  ],

  /* *************************收付款************************* */
  //收款状态(订单)
  costInStateIn:[
    {
      name:'未收款',
      value:0,
      class:'danger'
    },
    {
      name:'待收款',
      value:6,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'收款中',
      value:1,
      class:'primary'
    },
    {
      name:'结算周期',
      value:2,
      class:'warning'
    },
    {
      name:'已收款',
      value:3,
      class:'success'
    },
    {
      name:'对账中',
      value:4,
      class:'warning'
    },
    {
      name:'已对账',
      value:5,
      class:'success',
      color:'#f206cbba'
    },
  ],

  //付款状态(采购订单)
  costInStateOut:[
    {
      name:'未付款',
      value:0,
      class:'danger'
    },
    {
      name:'待付款',
      value:6,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'付款中',
      value:1,
      class:'primary'
    },
    {
      name:'结算周期',
      value:2,
      class:'warning'
    },
    {
      name:'已付款',
      value:3,
      class:'success'
    },
    {
      name:'对账中',
      value:4,
      class:'warning'
    },
    {
      name:'已对账',
      value:5,
      class:'success',
      color:'#f206cbba'
    },

  ],

  //退款状态(采购订单)
  costReturnStateOut:[
    {
      name:'未退款',
      value:0,
      class:'danger'
    },
    {
      name:'待退款',
      value:6,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'退款中',
      value:1,
      class:'primary'
    },
    {
      name:'已退款',
      value:2,
      class:'success'
    },
    {
      name:'不退款',
      value:3,
      class:'success'
    },

  ],

  /* *************************报销************************* */
  //结算状态(报销)
  expenseState:[
    {
      name:'未结算',
      value:0,
      class:'danger'
    },
    {
      name:'待结算',
      value:3,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'结算中',
      value:1,
      class:'primary'
    },
    {
      name:'已结算',
      value:2,
      class:'success'
    },

    {
      name:'对账中',
      value:4,
      class:'warning'
    },
    {
      name:'已对账',
      value:5,
      class:'success',
      color:'#f206cbba'
    },
  ],

  /* *************************发票************************* */
  //发票状态(订单,采购)
  invoiceState:[
    {
      name:'未开票',
      value:0,
      class:'danger'
    },
    {
      name:'待开票',
      value:4,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'开票中',
      value:1,
      class:'primary'
    },
    {
      name:'已开票',
      value:2,
      class:'success'
    },
    {
      name:'不开票',
      value:3,
      class:'success',
      color:'#c0c4cc'
    },
    {
      name:'对账中',
      value:5,
      class:'warning'
    },
    {
      name:'已对账',
      value:6,
      class:'success',
      color:'#f206cbba'
    },
  ],

  /* *************************对账************************* */
  // 对账状态
  costCheckState:[
    {
      name:'未提交',
      value:0,
      class:'info'
    },
    {
      name:'待对账',
      value:1,
      class:'warning'
    },
    {
      name:'已驳回',
      value:2,
      class:'danger'
    },
    {
      name:'复审中',
      value:3,
      class:'primary'
    },
    {
      name:'对账中',
      value:4,
      class:'primary'
    },
    {
      name:'已对账',
      value:5,
      class:'success',
      color:'#f206cbba'
    },
    {
      name:'结款中',
      value:6,
      class:'primary'
    },
    {
      name:'已结款',
      value:7,
      class:'success'
    },
  ],
  /* *************************工序************************* */
  //工序
  processStatus:[
    {
      name:'未开始',
      value:0,
      class:'info'
    },
    {
      name:'进行中',
      value:1,
      class:'warning'
    },
    {
      name:'质检中',
      value:2,
      class:'danger'
    },
    {
      name:'返工中',
      value:3,
      class:'primary'
    },
    {
      name:'已完成',
      value:4,
      class:'primary'
    },
  ]
}


export default {
  state
}
