/**
 * 程序版本：V3.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：生产管理·mutation
 * 开始时间：2021-04-25
 * 开发人员：刘巍骏
 * 最后修改：2021-04-25
 * 备注说明：如需修改请联系开发人员
 */
const mutation = {
  // 生产计划单相关
  PRO_DETAIL_TAB_INDEX(state,value){//生产单详情tab页下标
    state.pro_detail_tab_index = value;
  },
}

export default mutation;
