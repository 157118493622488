// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：公共组件.公共变量
// 开始时间：2021-01-19
// 开发人员：刘巍骏
// 最后修改：2021-01-19
// 备注说明：如需修改请联系开发人员

import mutations from "./mutations.js"

const state = {
  // 单据相关
  // 基本信息
  customer_data: {}, //单据所选客户信息(基本信息所选)
  supplier_data:{}, //单据所选供应商信息(基本信息所选)
  product_data:{},//单据所选产品信息(基本信息所选)
  cost_type:{},//单据所选费用分类信息(基本信息所选)
  bills_type_data: {}, //单据所选单据类型数据
  bills_base_data:{},//单据所填基本信息
  bills_sel_detail:{},//单据所选关联单据详情信息
  bills_relvence_sign:-1,//当前关联单据的标签
  bills_operate_btn:-1,//单据操作按钮(用于判断各单据列表是否需要刷新)
  //货品信息
  bills_product_data:[],//单据货品列表选择的数据
  bills_product_type:-1,//单据货品列表信息操作方式(0:新增 1:修改 2:删除)
  //生产相关
  totalCostPrice:0,//bom总成本价
  //其它费用信息
  bills_expense_data:[],//其它费用表格信息
  //生产工艺报价信息
  bills_mes_offer_data:[],//生产工艺报价明细
  //对账单相关
  is_load_condition_bills:false,//是否立即加载指定条件单据
  is_load_checkbill_data:false,//是否加载对账单信息(收付款单引用对账单)
  relvance_billid_arr:[],//对账单单据明细id数组
  relvance_billid_sign:-1,//对账单单据明细模块分类
  depot_sel_id_arr:[],//已加载的出入库单id数组(根据周期内查询出的,非指定id)
  
  //单据vuex数据备份(用于同一个界面打开多个公用单据)
  billsMsgBak:{},

  // 公共客户选择组件
  show_customerBox: false, //控制显示客户组件弹框

  //公共产品选择组件
  show_productBox: false, //控制显示产品选择弹框

  //公共供应商选择组件
  show_supplierBox: false, //控制显示供应商选择弹框

  //公共仓库选择组件
  show_warehouesBox: false, //控制显示仓库组件弹框

  //公共部门选择组件
  show_departmentBox: false, //控制显示部门组件弹框

  //公用员工选择组件
  show_personnelBox: false, //控制显示员工选择组件弹框

  //公共产品分类选择组件
  show_productTypeBox: false, //控制现在产品分类选择组件弹框

  //公用关联单据组件
  show_billingBox: false, //控制显示关联单据组件弹框

  //公共工艺选择组件
  show_technologyBox: false, //控制显示公共工艺选择组件

  //公共客户分类选择组件
  show_customerClassfiyBox: false, //控制显示客户分类选择组件

  //公共所有员工选择组件
  show_AllpersonnelBox: false, //控制显示所有员工分类选择组件

  //公共模板选择组件
  show_printTemplateBox: false, //控制显示公共打印模板选择组件
  show_electronicTemplateBox: false, //控制显示公共电子模板选择组件

  //公共费用分类选择组件
  show_costTypeBox:false,//控制显示费用分类选择组件

  //公共仓位号选择组件
  show_warehousePlace:false,//控制显示仓位号选择组件

  //公共附件预览选择弹框
  show_filePreview:false,//控制显示附件预览弹框
}


export default {
  state,
  mutations
}
