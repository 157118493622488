// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：人力模块.公共变量
// 开始时间：2021-01-19
// 开发人员：刘巍骏
// 最后修改：2021-01-19
// 备注说明：如需修改请联系开发人员

import mutations from "./mutations.js"
import actions from "./actions.js"

const state = {
  //组织架构
  departmentData: [], //企业部门数据
  personnelData: [], //企业所有员工信息
  firstLevelPersonnelData: [], //一级部门及其内部的所有员工
}


export default {
  state,
  mutations,
  actions
}
