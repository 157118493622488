/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：系统管理·mutation
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */
const mutation = {
  // 货品相关
  PRODUCT_TAB_INDEX(state, value) { //新增货品选择弹框页面下标
    state.productTabIndex = value;
  },
  PRODUCT_DATA(state, value) { //货品数据
    if (!!value.productBasicData) { //判断基本信息
      state.productData.basicData = value.productBasicData;
    }
    if (!!value.tableData_sellSection) { //判断价格信息
      state.productData.tableData_sellSection = value.tableData_sellSection;
    }
    if (!!value.specPriceVO) { //判断规格信息
      state.productData.specificationsData = value.specPriceVO;
    }
    if (!!value.stockData) { //库存信息
      state.productData.stockData = value.stockData;
    }
    if (!!value.productImgData) { //图片信息
      state.productData.productImgData = value.productImgData
    }
    if (!!value.productDetail) { //判断详细描述
      state.productData.detailData = value.productDetail;
    }
    if (!!value.productOtherInformationData) { //判断其他信息
      state.productData.otherInformationData = value.productOtherInformationData;
    }
  },
  INIT_PRODUCT_DATA(state) { //初始化新增货品信息
    //tab页信息
    state.productData = {
        basicData: {}, //基本信息
        tableData_sellSection: [], //区间价格
        specificationsData: [], //规格信息
        stockData: [], //库存信息
        productImgData: [], //产品图片
        detailData: '', //详细描述
        otherInformationData: [], //其他信息
      },
      //计量信息
      state.productTypeMoreUtil = [], //货品分类设置的多计量数据(选择货品分类后获取)
      state.mainMeasureData = {}, //主计量
      state.assisMeasureData = [], //辅计量
      state.deleteMeasureData = [], //删除的计量数据
      state.saleAssisUnit = '', //销售强制使用计量单位
      state.saleAssisUnitName = '', //销售强制使用计量单位(名称)
      state.purchaseAssisUnit = '', //采购强制使用计量单位
      state.purchaseAssisUnitName = '', //采购强制使用计量单位(名称)
      state.bomAssisUnit = '', //bom使用计量单位
      state.baseUnitData = [], //默认计量单位(根据货品设置多计量时使用)
      //货品库存
      state.addInventoryPlace = [], //新增的仓位号
      state.deleteInventoryPlace = [], //删除的仓位号
      //货品规格
      state.specsData = [] //规格数组
  },

  PRODUCT_IS_BILLS_USED(state, value){//货品是否已被其它单据使用
    state.productIsBillsUsed = value;
  },
  PRODUCT_TYPE(state, value) { //货品类型数据
    state.productType = value;
  },
  SHOW_ADDBOX(state, value) { //控制显示新增货品弹框
    state.show_addBox = value;
  },
  IS_CANCEL(state, value) { //关闭货品弹框时是否为取消
    state.is_cancel = value;
  },
  SPECS_DATA(state, value) { //保存规格数据
    state.specsData = value
  },
  CURRENT_UPDATEP_RODUCT(state, value) { //保存修改货品的数据
    state.currentUpdateProduct = value
  },

  // 价格管理相关
  PRODUCT_TYPE_MORE_UTIL(state, value) { //货品分类设置的多计量数据(选择货品分类后获取)
    state.productTypeMoreUtil = value;
  },
  MAIN_MEASURE_DATA(state, value) { //主计量数据
    state.mainMeasureData = value;
  },
  ASSIS_MEASURE_DATA(state, value) { //辅计量数据
    state.assisMeasureData = value;
  },
  DELETE_MEASURE_DATA(state, value) { //删除的计量数据
    state.deleteMeasureData.push(value);
  },
  SALE_ASSIS_UNIT(state, value) { //销售强制使用计量单位
    state.saleAssisUnit = value;
  },
  SALE_ASSIS_UNIT_NAME(state, value) { //销售强制使用计量单位(名称)
    state.saleAssisUnitName = value;
  },
  PURCHASE_ASSIS_UNIT(state, value) { //采购强制使用计量单位
    state.purchaseAssisUnit = value;
  },
  PURCHASE_ASSIS_UNIT_NAME(state, value) { //采购强制使用计量单位(名称)
    state.purchaseAssisUnitName = value;
  },
  BOM_ASSIS_UNIT(state, value) { //bom使用计量单位
    state.bomAssisUnit = value;
  },
  BASE_UNIT_DATA(state, value) { //默认计量单位(根据货品设置多计量时使用)
    state.baseUnitData = value;
  },

  //包装信息
  PRODUCT_PACKAGE(state,value){
    state.productPackage = value;
  },

  // 货品库存相关
  ADD_INVENTORY_PLACE(state, value) { //修改界面新增的仓位号信息
    state.addInventoryPlace.push(value);
  },
  DELETE_INVENTORY_PLACE(state, value) { //修改界面已删除的仓位号信息
    state.deleteInventoryPlace.push(value);
  }
}

export default mutation;
