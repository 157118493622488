/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：公共组件·同步单据方法js
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */
const mutation = {
  //单据模板
  // 控制显示新增或修改单据弹框
  SHOW_BILLSCREATEBOX(state, value) { //控制显示新增或修改单据弹框
    state.show_billsCreateBox = value;
  },
  BILLING_ORDERTEMPLATE(state, value) { //单据模板
    state.OrderTemplate = value;
  },
  BILLING_VARCODERULE(state,value){ //货品编码
    state.CommodityInfo=value;
  },
  SETTINGTABINDEX(state,value){//导航栏鼠标点击下标
   state.BillingTabIndex=value;
  },
}

export default mutation;
