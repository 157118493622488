// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：流程节点,审批权限相关请求接口
// 开始时间：2021-03-29
// 开发人员：万攀
// 最后修改：2021-03-29
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';


export default {
  /* 流程相关*/
  getWorkflowSetup() { //获取所有流程订单
    return request({
      method: 'POST',
      url: '/system/findAllOrderProcess'
    })
  },
  getWorkflowSetupById(data) { //根据分类获取流程
    return request({
      method: 'POST',
      url: '/system/findAllProcess',
      data
    })
  },
  addWorkFlow(data) { //新增流程
    return request({
      method: 'POST',
      url: '/system/addProcess',
      data
    })
  },
  deleteWorkFlow(data) { //删除订单流程
    return request({
      method: 'POST',
      url: '/system/deleteProcess',
      data
    })
  },
  updateWorkFlow(data) { //编辑订单信息
    return request({
      method: 'POST',
      url: '/system/updateCrmOrderProcessName',
      data
    })
  },
  updateWorkFlowStatus(data) { //更改流程节点
    return request({
      method: 'POST',
      url: '/system/updateProcess',
      data
    })
  },
  // setDefault(data) { //设置默认流程
  //   return request({
  //     method: 'POST',
  //     url: '/system/updateCrmOrderProcessDefault',
  //     data,
  //   })
  // },

  /* *************节点相关************* */
  //系统级
  getSystemFormBaseInfo(data) { //查询节点表单基本信息(系统级)
    return request({
      method: 'POST',
      url: '/system/findAllNodeInitialHead',
      data
    })
  },
  getSystemTableBaseInfo(data) { //查询节点表体基本信息(系统级)
    return request({
      method: 'POST',
      url: '/system/findAllNodeInitialBody',
      data
    })
  },

  // 节点列表相关
  getNodeClassfiy() { //获取所有节点分类
    return request({
      method: 'POST',
      url: '/system/findAllNodeType',
    })
  },
  getNodeInfoByClassfiyId(data) { //根据节点分类id查询数据列表
    return request({
      method: 'POST',
      url: '/system/findAllNodeInfo',
      data
    })
  },
  getWorkflowSetupNodeById(data) { //根据节点id查询节点信息
    return request({
      method: 'POST',
      url: '/system/getStatusInfo',
      data
    })
  },
  deleteWorkflowSetupNode(data) { //根据id删除节点
    return request({
      method: 'POST',
      url: '/system/deleteNodeInfoDefine',
      data
    })
  },
  getAllNodeFromAndTable(data) { //根据节点id查询节点所有表单(企业,自定义),表格(企业,自定义)数据
    return request({
      method: 'POST',
      url: '/system/findAllNodeFromAndTable',
      data
    })
  },
  updateSystemNodeByNodeId(data) { //根据节点id修改节点基本数据
    return request({
      method: 'POST',
      url: '/system/updateNodeInfoDefine',
      data
    })
  },
  addNodeData(data) { //新增节点数据
    return request({
      method: 'POST',
      url: '/system/addNodeInfoDefine',
      data
    })
  },

  // 节点企业级基本信息
  findNodeSysFormById(data) { //查询节点表单基本信息(企业)
    return request({
      method: 'POST',
      url: '/system/findNodeSysFormById',
      data
    })
  },
  findAllNodeSysForm(data) { //根据节点id查询节点表单信息
    return request({
      method: 'POST',
      url: '/system/findAllNodeSysForm',
      data
    })
  },
  getFirmFormBaseInfo(data) { //查询节点表单基本信息(企业)(可删)
    return request({
      method: 'POST',
      url: '/system/findAllNodeSysForm',
      data
    })
  },
  updateSysNodeSysFormSort(data) { //修改节点基本表单信息排序
    return request({
      method: 'POST',
      url: '/system/updateSysNodeSysFormSort',
      data
    })
  },
  getNodeFiledDataById(data) { //根据节点id获取自定义数据
    return request({
      method: 'POST',
      url: '/system/findAllOrderStatusCustomize',
      data
    })
  },
  addNodeSysForm(data) { //增阿基企业级表单基本信息数据(修改时用)
    return request({
      method: 'POST',
      url: 'system/addNodeSysForm',
      data
    })
  },
  deleteNodeSysForm(data) { //单个删除企业级表单基本信息数据(修改时用)
    return request({
      method: 'POST',
      url: '/system/deleteNodeSysForm',
      data
    })
  },
  updateNodeSysForm(data) { //单个修改企业级表单基本信息数据(修改时用)
    return request({
      method: 'POST',
      url: '/system/updateNodeSysForm',
      data
    })
  },

  //节点企业级表单自定义信息
  addNodeCustomForm(data) { //单个新增节点自定义信息
    return request({
      method: 'POST',
      url: '/system/addNodeCustomForm',
      data
    })
  },
  deleteNodeCustomForm(data) { //单个删除节点自定义信息
    return request({
      method: 'POST',
      url: '/system/deleteNodeCustomForm',
      data
    })
  },
  findAllNodeCustomForm(data) { //查询节点自定义信息
    return request({
      method: 'POST',
      url: '/system/findAllNodeCustomForm',
      data
    })
  },
  updateNodeCustomForm(data) { //单个修改节点自定义信息
    return request({
      method: 'POST',
      url: '/system/updateNodeCustomForm',
      data
    })
  },

  //节点企业级表格信息
  getFirmTableBaseInfo(data) { //查询节点表体基本信息(企业)
    return request({
      method: 'POST',
      url: '/system/findAllNodeSysTable',
      data
    })
  },
  getFirmTableDefineInfo(data) { //查询节点表体自定义信息(企业)
    return request({
      method: 'POST',
      url: '/system/findAllNodeCustomTable',
      data
    })
  },
  updateSysNodeSysTableSort(data) { //修改节点基本表单信息排序
    return request({
      method: 'POST',
      url: '/system/updateSysNodeSysTableSort',
      data
    })
  },
  addNodeSysTable(data) { //添加节点表格数据信息(修改时用)
    return request({
      method: 'POST',
      url: '/system/addNodeSysTable',
      data
    })
  },
  deleteNodeSysTable(data) { //删除节点表格数据信息(修改时用)
    return request({
      method: 'POST',
      url: '/system/deleteNodeSysTable',
      data
    })
  },
  updateNodeSysTable(data) { //修改节点表格数据信息(修改时用)
    return request({
      method: 'POST',
      url: '/system/updateNodeSysTable',
      data
    })
  },
  findAllNodeSysTable(data) { //根据节点id查询节点表格数据信息(修改时用)
    return request({
      method: 'POST',
      url: '/system/findAllNodeSysTable',
      data
    })
  },
  findNodeSysTableById(data) { //根据表单id查询详情(企业级)
    return request({
      method: 'POST',
      url: '/system/findNodeSysTableById',
      data
    })
  },

  //单据打印模板相关
  findOrderPrindMouldByCode(data) { //根据code值查询打印模板信息
    return request({
      method: 'POST',
      url: '/system/findOrderPrindMouldByCode',
      data
    })
  },
}
