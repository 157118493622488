import i18n from './util/i18n.js'//语言包,需要最先导入

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './vuex/store';
import VueRouter from 'vue-router';
import eleLoading from './util/elementui.js';//引入自定义公共element ui公共方法


//引入axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios);
Vue.prototype.$ajax = axios;

// 引入vuex
import vuex from 'vuex';
Vue.use(vuex);


//引入全局组件
import IconFont from '@/components/icons'//图标组件
Vue.component('icon-font', IconFont)

// 引用公共js
import commonJsExtend from './lib/common.js'//新增公共js
Vue.prototype.commonJsExtend = commonJsExtend;
//引入公共方法
import commonJs from '@/lib/common_js.js'//原公共js
Vue.prototype.commonJs=commonJs

// 第三方
import FullCalendar from 'vue-fullcalendar'  // 日程管理控件
Vue.use(FullCalendar);
// 引入Element ui
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'//element ui 2.x
Vue.prototype.elDuration=3000//element-ui提示信息显示时间
Vue.use(Element)
//引入阿里图标
import '@/assets/fonts/iconfont.css'
// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
//引入自适应屏幕分辨率插件
import 'lib-flexible'
//el-table表格自适应插件
import AFTableColumn from 'af-table-column'
Vue.use(AFTableColumn)

Vue.use(VueRouter)
Vue.config.productionTip = false


//路由守卫
router.beforeEach((to,from,next)=>{
  // console.log(to);
  // console.log(from);
  // console.log(next);
  //将登录方式存入vuex
  store.commit("LOGIN_TYPE",parseInt(getCookie("login_type")));
  //判断是否有登录状态
  if(store.state.user.loginType == -1 || isNaN(store.state.user.loginType)){
     store.commit("LOGIN_TYPE",0);
  }
  next();
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
