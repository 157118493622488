// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：超级管理请求接口(不需要token)
// 开始时间：2022-03-31
// 开发人员：刘巍骏
// 最后修改：2022-03-31
// 备注说明：如需修改请联系开发人员

import {request} from "../request.js";
import qs from 'qs';

export default{
  //用户相关
  generateTokenByUserId(data){//根据用户id获取token
    return request({
      method:'POST',
      url:'/powersystem/generateTokenByUserId',
      data
    })
  },
  generateTokenByConfigUser(data){//根据系统配置的用户生成token
    return request({
      method:'POST',
      url:'/powersystem/generateTokenByConfigUser',
      data
    })
  },
}
