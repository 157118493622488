import { render, staticRenderFns } from "./customerLoginApp.vue?vue&type=template&id=6d5bc0dd&scoped=true&"
import script from "./customerLoginApp.vue?vue&type=script&lang=js&"
export * from "./customerLoginApp.vue?vue&type=script&lang=js&"
import style0 from "./customerLoginApp.vue?vue&type=style&index=0&lang=less&"
import style1 from "./customerLoginApp.vue?vue&type=style&index=1&id=6d5bc0dd&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d5bc0dd",
  null
  
)

export default component.exports