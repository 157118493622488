<template>
  <div>
    <!-- 用户账号登录主界面 -->
    <div v-if="projectFrameworkType == 0 && loginType == 0">
      <userLoginApp></userLoginApp>
    </div>

    <!-- 合作伙伴登录主界面 -->
    <div v-if="projectFrameworkType == 0 && [1,2].includes(loginType)">
      <customerLoginApp></customerLoginApp>
    </div>

    <!-- 客户账号登录 -->
    <div v-if="projectFrameworkType == 1">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 组件导入
  import userLoginApp from "./views/userLoginApp.vue";//用户登录架构
  import customerLoginApp from "./views/customerLoginApp.vue";//客户登录架构

  //导入js
  import powerSystemApi from "@/network/powersystem/powersystem.js";//超级权限相关接口

  export default {
    name: 'App',
    data(){
      return{
        //项目架构显示方式
        projectFrameworkType:-1,//1:客户登录界面
      }
    },
    created(){
    },
    mounted(){
    },
    computed: {
      ...mapState({
        loginType: state => state.user.loginType, //当前系统登录方式
      }),
    },
    watch: {
      // 监听路由发生变化
      '$route': 'getFrameworkType',
    },
    methods:{
      /* 根据路由获取用户登录方式 */
      getFrameworkType(){
        //获取访问的路由
        let href = window.location.href;
        //判断登录身份
        if(href.indexOf("customerlogin") != -1){//客户登录界面
          //设置登录方式为合作伙伴登录
          this.projectFrameworkType = 1;
          //清空cookie
          setCookie("token","");
          //获取系统配置的默认用户
          powerSystemApi.generateTokenByConfigUser().then(res=>{
            if(res.code == 200){
              //将获取的token设置到cookie
              setCookie("token",res.data);
            }else{
              this.$message({
                type:'error',
                message:"合作伙伴登录身份初始化失败,请联系管理员!",
                duration:this.elDuration
              })
            }
          })
        }else{//用户登录
          this.projectFrameworkType = 0;
        }
      },
    },
    components:{
      userLoginApp,
      customerLoginApp
    }
  }
</script>
