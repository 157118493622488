import Qs from 'qs'
import axios from "axios"

//后端接口地址(crm)
var domain = backUrl;//crm后端
var loginDomain = loginUrl;//登录

var yxyDomain = "https://edm1422.yiwaixiao.com/";
var diskDomain = "https://ywxcloud.yiwaixiao.com/";

axios.defaults.baseURL = domain;
//axios.defaults.baseURL = Url.teamUrl;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//axios.defaults.timeout = 5000;

axios.defaults.transformRequest = [function(data) {
  data = Qs.stringify(data)
  return data
}]

axios.defaults.transformResponse = [function(data) {
  data = JSON.parse(data)
  return data
}]


// 请求拦截
axios.interceptors.request.use(function(config) {
  console.log(config);
  return config;
}, function(error) {
  return Promise.reject(error);
});
//响应拦截
axios.interceptors.response.use((res) => {
  return res;
}, (error) => {
  return Promise.reject(error);
});


// function fetch(url, params) {
//   return new Promise((resolve, reject) => {
//     axios.get(url, {
//         params: params
//       })
//       .then((res) => {
//         resolve(res.data)
//       }).catch(err => {
//         console.log("请求网络失败，请稍后重试！！！")
//       })
//   })
// }
export default {
  teamUrl: domain,
  yxyUrl: yxyDomain,
  loginUrl: loginDomain,
  diskUrl: diskDomain
}
