/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：人力模块·同步公共组件方法js
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */
const mutation = {
  // 组织架构
  DEPARTMENTDATA(state, value) { //企业部门信息
    state.departmentData = value;
  },
  PERSONNELDATA(state, value) { //企业所有员工信息
    state.personnelData = value;
  },
  FIRSTLEVELPERSONNELDATA(state, value) { //获取一级部门及其内部的所有员工
    state.firstLevelPersonnelData = value;
  },
}

export default mutation;
