/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：全局的公用函数js
 * 开始时间：2019-8-21
 * 开发人员：吴荣江
 * 最后修改：2019-9-26
 * 备注说明：如需修改请联系开发人员
 */
import {
  Message, MessageBox
} from 'element-ui'

import $ from 'jquery'
import store from '../vuex/store'
import router from '../router'
//根据文件类型,选择对应类名及图标路径
const judgeFileType = function (fileType1) {
  var file_mate = "",
    insertFile = 'https://webmail120117.yiwaixiao.com/esmail/static/img/cloudDiskFileType/';
  switch (fileType1) {
    case "txt":
      file_mate = "fl-txt";
      insertFile += 'txt.png';
      break;
    case "doc":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "docx":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "dotx":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "dotm":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "docm":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "dot":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "wps":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "wpt":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "vsd":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "rtf":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "jnt":
      file_mate = "fl-doc";
      insertFile += 'doc.png';
      break;
    case "pdf":
      file_mate = "fl-pdf";
      insertFile += 'pdf.png';
      break;
    case "ppt":
      file_mate = "fl-ppt";
      insertFile += 'ppt.png';
      break;
    case "pps":
      file_mate = "fl-ppt";
      insertFile += 'ppt.png';
      break;
    case "pptx":
      file_mate = "fl-ppt";
      insertFile += 'ppt.png';
      break;
    case "ppsx":
      file_mate = "fl-ppt";
      insertFile += 'ppt.png';
      break;
    case "ppsm":
      file_mate = "fl-ppt";
      insertFile += 'ppt.png';
      break;
    case "pptm":
      file_mate = "fl-ppt";
      insertFile += 'ppt.png';
      break;
    case "potx":
      file_mate = "fl-ppt";
      insertFile += 'ppt.png';
      break;
    case "potm":
      file_mate = "fl-ppt";
      insertFile += 'ppt.png';
      break;
    case "ppam":
      file_mate = "fl-ppt";
      insertFile += 'ppt.png';
      break;
    case "xls":
      file_mate = "fl-xls";
      insertFile += 'xls.png';
      break;
    case "xlt":
      file_mate = "fl-xls";
      insertFile += 'xls.png';
      break;
    case "xlsx":
      file_mate = "fl-xls";
      insertFile += 'xls.png';
      break;
    case "xlsm":
      file_mate = "fl-xls";
      insertFile += 'xls.png';
      break;
    case "xltx":
      file_mate = "fl-xls";
      insertFile += 'xls.png';
      break;
    case "xltm":
      file_mate = "fl-xls";
      insertFile += 'xls.png';
      break;
    case "xlsb":
      file_mate = "fl-xls";
      insertFile += 'xls.png';
      break;
    case "xlam":
      file_mate = "fl-xls";
      insertFile += 'xls.png';
      break;
    case "csv":
      file_mate = "fl-xls";
      insertFile += 'xls.png';
      break;
    case "jpg":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "gif":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "png":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "jpeg":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "bmp":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "tif":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "tiff":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "ico":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "fcx":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "tga":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "exif":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "fpx":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "svg":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "eps":
      file_mate = "fl-img";
      insertFile += 'img.png';
      break;
    case "psd":
      file_mate = "fl-ps";
      insertFile += 'ps.png';
      break;
    case "md":
      file_mate = "fl-md";
      insertFile += 'md.png';
      break;
    case "json":
      file_mate = "fl-md";
      insertFile += 'md.png';
      break;
    case "js":
      file_mate = "fl-md";
      insertFile += 'md.png';
      break;
    case "less":
      file_mate = "fl-md";
      insertFile += 'md.png';
      break;
    case "bat":
      file_mate = "fl-md";
      insertFile += 'md.png';
      break;
    case "css":
      file_mate = "fl-md";
      insertFile += 'md.png';
      break;
    case "java":
      file_mate = "fl-md";
      insertFile += 'md.png';
      break;
    case "jsp":
      file_mate = "fl-md";
      insertFile += 'md.png';
      break;
    case "class":
      file_mate = "fl-md";
      insertFile += 'md.png';
      break;
    case "vue":
      file_mate = "fl-v";
      insertFile += 'vue.png';
      break;
    case "exe":
      file_mate = "fl-html";
      insertFile += 'html.png';
      break;
    case "htm":
      file_mate = "fl-html";
      insertFile += 'html.png';
      break;
    case "html":
      file_mate = "fl-html";
      insertFile += 'html.png';
      break;
    case "mmap":
      file_mate = "fl-mmap"; //其他
      insertFile += 'other.png';
      break;
    case "dmg":
      file_mate = "fl-dmg";
      insertFile += 'dmg.png';
      break;
    case "ai":
      file_mate = "fl-ai";
      insertFile += 'ai.png';
      break;
    case "zip":
      file_mate = "fl-zip";
      insertFile += 'zip.png';
      break;
    case "rar":
      file_mate = "fl-zip";
      insertFile += 'zip.png';
      break;
    case "7z":
      file_mate = "fl-zip";
      insertFile += 'zip.png';
      break;
    case "mp4":
      file_mate = "fl-video";
      insertFile += 'video.png';
      break;
    case "wmv":
      file_mate = "fl-video";
      insertFile += 'video.png';
      break;
    case "avi":
      file_mate = "fl-video";
      insertFile += 'video.png';
      break;
    case "flv":
      file_mate = "fl-video";
      insertFile += 'video.png';
      break;
    case "rmvb":
      file_mate = "fl-video";
      insertFile += 'video.png';
      break;
    case "rm":
      file_mate = "fl-video";
      insertFile += 'video.png';
      break;
    case "flash":
      file_mate = "fl-video";
      insertFile += 'video.png';
      break;
    case "3gp":
      file_mate = "fl-video";
      insertFile += 'video.png';
      break;
    case "swf":
      file_mate = "fl-video";
      insertFile += 'video.png';
      break;
    case "mp3":
      file_mate = "fl-audio";
      insertFile += 'audio.png';
      break;
    case "amr":
      file_mate = "fl-audio";
      insertFile += 'audio.png';
      break;
    case "wma":
      file_mate = "fl-audio";
      insertFile += 'audio.png';
      break;
    case "wav":
      file_mate = "fl-audio";
      insertFile += 'audio.png';
      break;
    case "aac":
      file_mate = "fl-audio";
      insertFile += 'audio.png';
      break;
    case "flac":
      file_mate = "fl-audio";
      insertFile += 'audio.png';
      break;
    case "ape":
      file_mate = "fl-audio";
      insertFile += 'audio.png';
      break;
    case "mid":
      file_mate = "fl-audio";
      insertFile += 'audio.png';
      break;
    case "ogg":
      file_mate = "fl-audio";
      insertFile += 'audio.png';
      break;
    case "torrent":
      file_mate = "fl-bt";
      insertFile += 'bt.png';
      break;
    default:
      file_mate = "fl-other";
      insertFile += 'other.png';
  }
  return file_mate + '&&' + insertFile;
}

//判断日期是否需要添加
const getOrderDates = function (date) {
  return date < 10 ? date = "0" + date : date = date
}

//判断当前日期是否在指点日志之后
const verifyTime = function (rightTime, verifyTime, timeName) {
  let productDate = ''
  let nowDate = ''; //
  let month = null
  let date = null
  let year = null
  //判断当前选择的日期是否在改规定的日期后
  productDate = verifyTime
  if (verifyTime === null || verifyTime === '') {
    Message({
      message: `请选择${timeName}日期！`,
      type: 'warning'
    })
    return false
  }
  if ((typeof verifyTime) == 'string') {
    month = productDate.split("-")[1]
    year = productDate.split("-")[0]
    date = productDate.split("-")[2]
  } else {
    month = getOrderDates(productDate.getMonth() + 1)
    year = productDate.getFullYear()
    date = getOrderDates(productDate.getDate())
  }
  nowDate = rightTime.split("-")
  if (Number(nowDate[0]) > year) {
    Message({
      message: `${timeName}日期选择错误，应该选择在  ${nowDate[0]}-${nowDate[1]}-${nowDate[2].split(" ")[0]} 之后！`,
      type: 'warning'
    })
    return false
  } else if (Number(nowDate[0]) == year) {
    if (Number(nowDate[1]) > month) {
      Message({
        message: `${timeName}日期选择错误，应该选择在 ${nowDate[0]}-${nowDate[1]}-${nowDate[2].split(" ")[0]} 之后！`,
        type: 'warning'
      })
      return false
    } else if (Number(nowDate[1]) == month) {
      if (Number(nowDate[2].split(" ")[0]) > date) {
        Message({
          message: `${timeName}日期选择错误，应该选择在 ${nowDate[0]}-${nowDate[1]}-${nowDate[2].split(" ")[0]} 之后！`,
          type: 'warning'
        })
        return false
      }
    }
  }
  return `${year}-${month}-${date}`
}


//判断文件类型
const getFileType = function (value) {
  var fileType1 = value.split(".");
  let nui_pic = ''
  if (fileType1 != "undefined") {
    switch (fileType1[fileType1.length - 1].toLowerCase()) {
      case "ppt":
        nui_pic = "nui_file3_26";

        break;
      case "pptx":
        nui_pic = "nui_file3_26";

        break;
      case "pptm":
        nui_pic = "nui_file3_26";

        break;
      case "ppsx":
        nui_pic = "nui_file3_26";

        break;
      case "ppsm":
        nui_pic = "nui_file3_26";

        break;
      case "potx":
        nui_pic = "nui_file3_26";

        break;
      case "potm":
        nui_pic = "nui_file3_26";

        break;
      case "ppam":
        nui_pic = "nui_file3_26";

        break;
      case "xlsm":
        nui_pic = "nui_file3_2";

        break;
      case "xltx":
        nui_pic = "nui_file3_2";

        break;
      case "xltm":
        nui_pic = "nui_file3_2";

        break;
      case "xlsb":
        nui_pic = "nui_file3_2";

        break;
      case "xlam":
        nui_pic = "nui_file3_2";

        break;
      case "csv":
        nui_pic = "nui_file3_2";

        break;
      case "docm":
        nui_pic = "nui_file3_3";

        break;
      case "dot":
        nui_pic = "nui_file3_3";

        break;
      case "rtf":
        nui_pic = "nui_file3_3";

        break;
      case "dotx":
        nui_pic = "nui_file3_3";

        break;
      case "dotm":
        nui_pic = "nui_file3_3";

        break;
      case "wps":
        nui_pic = "nui_file3_3";

        break;
      case "wpt":
        nui_pic = "nui_file3_3";

        break;
      case "jpeg":
        nui_pic = "nui_file3_11";
        break;
      case "txt":
        nui_pic = "nui_file3_1";
        break;
      case "xls":
        nui_pic = "nui_file3_2";
        break;
      case "xlsx":
        nui_pic = "nui_file3_2";
        break;
      case "doc":
        nui_pic = "nui_file3_3";
        break;
      case "docx":
        nui_pic = "nui_file3_3";
        break;
      case "7z":
        nui_pic = "nui_file3_5";

        break;
      case "js":
        nui_pic = "nui_file3_7";

        break;
      case "css":
        nui_pic = "nui_file3_8";

        break;
      case "rmvb":
        nui_pic = "nui_file3_9";

        break;
      case "mp3":
        nui_pic = "nui_file3_10";

        break;
      case "jpg":
        nui_pic = "nui_file3_11";
        break;
      case "gif":
        nui_pic = "nui_file3_12";
        break;
      case "png":
        nui_pic = "nui_file3_13";
        break;
      case "bmp":
        nui_pic = "nui_file3_14";
        break;
      case "tif":
        nui_pic = "nui_file3_15";
        break;
      case "ico":
        nui_pic = "nui_file3_18";

        break;
      case "pdf":
        nui_pic = "nui_file3_19";
        break;
      case "psd":
        nui_pic = "nui_file3_20";

        break;
      case "ai":
        nui_pic = "nui_file3_21";

        break;
      case "fi":
        nui_pic = "nui_file3_22";

        break;
      case "swf":
        nui_pic = "nui_file3_23";

        break;
      case "rar":
        nui_pic = "nui_file3_24";

        break;
      case "zip":
        nui_pic = "nui_file3_24";

        break;
      case "exe":
        nui_pic = "nui_file3_25";

        break;
      default:
        nui_pic = "nui_file3_6";

    }
    return nui_pic;
  }
}

//文件的预览（判断文件类型来获取不同的预览方式）
const viewFileWay = function (msg) {
  let url = "";
  //判断当前文件是否从云盘上传的
  if (msg.indexOf("ywxcloud") != -1) {
    url = msg
  } else {
    //通过当前传递过来的路劲获取文件名称
    let index = 0; //下标的路径
    index = msg.indexOf("=")
    msg = msg.substr(index + 1, msg.length)
    let fileDate = msg
    let attrhz = ''
    //判断当前有无 &wdStartOn
    if (msg.indexOf("&wdStartOn=1") != -1) {
      index = fileDate.lastIndexOf(".")
      attrhz = fileDate.substr(index + 1, fileDate.length);
      if (attrhz !== undefined && attrhz !== null) {
        if (attrhz.indexOf("&wdStartOn=1") != -1) {
          let index3 = attrhz.split("&wdStartOn=1")
          attrhz = index3[0]
        }
      }
    } else {
      //判断当前获取的路径是否正确
      index = msg.indexOf("=")
      msg = msg.substr(index + 1, msg.length)
      index = fileDate.lastIndexOf(".")
      attrhz = fileDate.substr(index + 1, fileDate.length);

      if (attrhz !== undefined && attrhz !== null) {
        if (attrhz.indexOf("&wdStartOn=1") != -1) {
          let index3 = attrhz.split("&wdStartOn=1")
          attrhz = index3[0]
        }
      }

    }
    let hzm = $.trim(attrhz).toLowerCase();
    let link = "https://view.officeapps.live.com/op/embed.aspx?src=" + msg + "&wdStartOn=1";
    switch (hzm) {
      case "doc":
        url = link;
        break;
      case "docx":
        url = link;
        break;
      case "pptx":
        url = link;
        break;
      case "ppt":
        url = link;
        break;
      case "ppsx":
        url = link;
        break;
      case "potx":
        url = link;
        break;
      case "pps":
        url = link;
        break;
      case "dot":
        url = link;
        break;
      case "dotx":
        url = link;
        break;
      case "jpeg":
        url = msg;
        break;
      case "pdf":
        url = msg;
        break;
      case "psd":
        break;
      case "xls":
        url = link;
        break;
      case "xlsx":
        url = link;
        break;
      case "zip":
        break;
      case "rar":
        break;
      case "txt":
        url = msg;
        break;
      case "jpg":
        url = msg;
        break;
      case "gif":
        url = msg;
        break;
      case "png":
        url = msg;
        break;
      case "bmp":
        url = msg;
        break;
      case "tif":
        url = msg;
        break;
      default:
        break;
    }
  }
  return url
}

//文件可上传的格式类型
const uploadFileType = function (name) {
  let index = 0;
  let hzm = ''
  let isHas = false
  if (name !== undefined && name !== null && name != '') {
    if (name.lastIndexOf(".") != -1) {
      index = name.split(".")
      hzm = index[index.length - 1]
    }
    let value = $.trim(hzm).toLowerCase()
    switch (value) {
      case "doc":
        isHas = true;
        break;
      case "docx":
        isHas = true;
        break;
      case "pptx":
        isHas = true;
        break;
      case "ppt":
        isHas = true;
        break;
      case "ppsx":
        isHas = true;
        break;
      case "potx":
        isHas = true;
        break;
      case "pps":
        isHas = true;
        break;
      case "dot":
        isHas = true;
        break;
      case "dotx":
        isHas = true;
        break;
      case "jpeg":
        isHas = true;
        break;
      case "pdf":
        isHas = true;
        break;
      case "psd":
        break;
      case "xls":
        isHas = true;
        break;
      case "xlsx":
        isHas = true;
        break;
      case "zip":
        break;
      case "rar":
        break;
      case "txt":
        isHas = true;
        break;
      case "jpg":
        isHas = true;
        break;
      case "gif":
        isHas = true;
        break;
      case "png":
        isHas = true;
        break;
      case "bmp":
        isHas = true;
        break;
      case "tif":
        isHas = true;
        break;
      default:
        break;
    }
  }
  return isHas
}

//订单的导出替换字段(获取基本信息)
const orderExportInfo = function (statusName) {
  let exportCompany = [{
    id: 'ywx_company_name',
    name: '企业名称'
  },
  {
    id: 'ywx_company_logo',
    name: '企业logo',
  },
  {
    id: 'ywx_company_addr',
    name: '企业地址'
  },
  {
    id: 'ywx_company_tel',
    name: '企业电话'
  },
  {
    id: 'ywx_company_web',
    name: '企业官网'
  }
  ]; //企业信息
  let exportProduct = [
    {
      id: 'ywx_product_no',
      name: '产品编号(必须项,作为产品信息替换的标识)'
    },{
      id: 'ywx_product_xuhao',
      name: '产品序号(与ywx_product_no同一行)'
    }, {
      id: 'ywx_product_name',
      name: '产品名称(与ywx_product_no同一行)'
    },
    {
      id: 'ywx_product_price',
      name: '产品单价 (与ywx_product_no同一行)'
    },
    {
      id: 'ywx_product_costprice',
      name: '产品成本价 (与ywx_product_no同一行)'
    },
    {
      id: 'ywx_product_money',
      name: '产品总价 (与ywx_product_no同一行)'
    },
    {
      id: 'ywx_product_num',
      name: '产品数量(与ywx_product_no同一行)'
    },
    {
      id: 'ywx_product_unit',
      name: '产品单位(与ywx_product_no同一行)'
    },
    {
      id: 'ywx_product_resume',
      name: '产品简述(与ywx_product_no同一行)'
    },
    {
      id: 'ywx_product_remark',
      name: '产品备注(与ywx_product_no同一行)'
    },
    {
      id: 'ywx_product_img',
      name: '产品图片(与ywx_product_no同一行)'
    },{
      id: 'ywxcf_名称',
      name: '产品自定义字段值(与ywx_product_no同一行)'
    }, {
      id: 'ywx_product_specs_value',
      name: '规格值(与ywx_product_no同一行)'
    }, {
      id: 'ywx_product_specs_name',
      name: '规格名称'
    },{
      id: 'ywx_product_tnum',
      name: '产品总数量'
    },

  ]; //产品信息
  let exportCumstomer = [{
    id: 'ywx_customer_code',
    name: '客户编号'
  }, {
    id: 'ywx_customer_name',
    name: '客户名称'
  },
  {
    id: 'ywx_linkman_name',
    name: '收货人姓名'
  },
  {
    id: 'ywx_linkman_email',
    name: '收货人邮箱'
  },
  {
    id: 'ywx_linkman_mobile',
    name: '收货人手机'
  },
  {
    id: 'ywx_linkman_address',
    name: '收货人地址'
  },

  ]; //客户信息
  let exportUser = [{
    id: 'ywx_user_name',
    name: '个人姓名'
  },
  {
    id: 'ywx_user_email',
    name: '个人主邮箱'
  },
  {
    id: 'ywx_user_fax',
    name: '个人传真'
  },
  {
    id: 'ywx_user_mobile',
    name: '个人手机'
  }
  ]; //个人信息
  let exportOffer = [{
    id: 'ywx_house',
    name: '出货仓库',
    title: '报价'
  },
  {
    id: 'ywx_transport',
    name: '运输费用',
    title: '报价'
  },
  {
    id: 'ywx_date1',
    name: '发货日期',
    title: '报价'
  },
  {
    id: 'ywx_date2',
    name: '收货日期',
    title: '报价'
  },
  {
    id: 'ywx_invoice',
    name: '发票信息',
    title: '报价'
  },
  {
    id: 'ywx_remark',
    name: '备注说明',
    title: '报价'
  },
  {
    id: 'ywx_flag',
    name: '附件信息',
    title: '报价'
  },
  {
    id: 'ywx_signfor_name',
    name: '签收人姓名',
    title: '签收'
  },
  {
    id: 'ywx_signfor_date',
    name: '签收日期',
    title: '签收'
  },
  {
    id: 'ywx_signfor_remark',
    name: '备注',
    title: '签收'
  },
  {
    id: 'ywx_logistics_num',
    name: '物流单号',
    title: '出库'
  },
  {
    id: 'ywx_logistics_way',
    name: '物流方式',
    title: '出库'
  },
  {
    id: 'ywx_logistics_company',
    name: '物流公司',
    title: '出库'
  }, {
    id: 'ywx_logistics_remark',
    name: '备注',
    title: '出库'
  }, {
    id: 'ywx_logistics_date',
    name: '物流日期',
    title: '出库'
  },
  {
    id: 'ywx_pay_type',
    name: '付款类型',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_money',
    name: '付款金额',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_way',
    name: '付款方式',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_date',
    name: '付款日期',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_remark',
    name: '备注',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_flag',
    name: '附件信息',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_cname',
    name: '公司账号-公司名称(收款银行信息)',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_ctaxes',
    name: '公司账号-纳税编号(收款银行信息)',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_cbank',
    name: '公司账号-开户银行(收款银行信息)',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_caccount',
    name: '公司账号-开户账号(收款银行信息)',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_caddress',
    name: '公司账号-联系地址(收款银行信息)',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_cphone',
    name: '公司账号-联系电话(收款银行信息)',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_uname',
    name: '个人账号-开户人姓名(收款银行信息)',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_ubank',
    name: '个人账号-开户银行(收款银行信息)',
    title: '报价|付款'
  },
  {
    id: 'ywx_pay_uaccount',
    name: '个人账号-开户账号(收款银行信息)',
    title: '报价|付款'
  }
  ]; //报价
  let exportOrder = [
    {
      id: 'ywx_order_no',
      name: '订单编号'
    },
    {
      id: 'ywx_order_money',
      name: '订单总额'
    },
  ]//订单信息
  let exportData = []
  //判断当前需要什么类型的值
  switch (statusName) {
    case '企业信息':
      exportData = exportCompany
      break;
    case '产品信息':
      exportData = exportProduct
      break;
    case '个人信息':
      exportData = exportUser
      break;
    case '客户信息':
      exportData = exportCumstomer
      break;
    case '其他信息':
      exportData = exportOffer
      break;
    case '订单信息':
      exportData = exportOrder
      break;
  }
  return exportData
}


//设置国家城市等地址的样式
const getAddrInfo = function (value) {
  //判断当前有无数据
  if (value !== '' && value !== null && value !== undefined && value != 'null') {
    return value + "-"
  } else {
    return ''
  }
}


//验证当前输入的是否是(大于0)，并且显示的提示语需要传递
const modifyNumber = function (value, title) {
  let reg = /^[0-9]+$/
  if (!reg.test(value)) {
    Message({
      message: `[${title}] 请输入正整数，并且不能有空格！`,
      type: 'warning'
    })
    return false
  } else if (!(value % 1 == 0 && value > 0)) {
    Message({
      message: `[${title}] 请输入大于0的正整数！`,
      type: 'warning'
    })
    return false
  }
  return true
}
//验证是否是大于0的数字 ，有返回值
const modifyNumber2 = function (value, title) {
  if (value !== undefined && value !== null) {
    value += ''
    value = $.trim(value)
    let reg = /^[0-9]+$/;
    let modify = reg.test(value)
    if (!modify) {
      Message({
        message: `[${title}] 请输入正整数，系统将自动设置为1！`,
        type: 'warning'
      })
      return 1
    } else {
      if (value < 0) {
        Message({
          message: `[${title}] 请输入正数，系统将自动设置为1！`,
          type: 'warning'
        })
        return 1
      } else if (value % 1 != 0) {
        Message({
          message: `[${title}] 请输入正整数，系统将自动设置为整数！`,
          type: 'warning'
        })
        return parseInt(value)
      } else {
        return value
      }
    }
  } else {
    return 1
  }
}

//验证当前的输入框是否有值(带图标)
const verifyText = function (editInput, id, title) {
  let input = editInput.toString().replace(/^\s*|\s*$/g, '')
  if (input === '') {
    Message({
      message: title + "不能为空！",
      type: 'warning'
    })
    $("#" + id).addClass("error-span");
    return false
  }
  $("#" + id).removeClass("error-span");
  $("#" + id).addClass("right-span");
  return true
}

//验证当前的输入框中的值是否全部是数字
const verifyNumber = function (input, id, title) {
  //判断是否都是数字
  let reg = /^[\d]+$/;
  let s = reg.test(input)
  if (!s) {
    Message({
      message: title + "只能填入数字！",
      type: 'warning'
    })
    $("#" + id).addClass("error-span");
    return false
  }
  $("#" + id).removeClass("error-span");
  $("#" + id).addClass("right-span");
  return true
}
//验证当前的金额是否正确(带css样式)
const verifyProductPrice = function (value, id, title) {
  let price = $.trim(value)
  let reg = /^[0-9.]+$/;
  let modify = reg.test(price)
  if (price !== undefined || price !== null || price !== '') {
    if (!modify) {
      Message({
        message: title + "只能填入数字！",
        type: 'warning'
      })
      $("#" + id).addClass("error-span");
      return false
    } else if (price <= 0) {
      Message({
        message: title + "必须大于零！",
        type: 'warning'
      })
      $("#" + id).addClass("error-span");
      return false
    }
    $("#" + id).removeClass("error-span");
    $("#" + id).addClass("right-span");
    return true
  } else {
    Message({
      message: title + "不能为空！",
      type: 'warning'
    })
    $("#" + id).addClass("error-span");
    return false
  }
}
//验证两位数  两位小数价格（大于0）
const verifyPriceTo2 = (value, title) => {
  if (value !== undefined && value !== null) {
    value += ''
    value = value.trim()
    let reg = /^[0-9.]+$/;
    let modify = reg.test(value)
    if (!modify) {
      Message({
        message: `[${title}] 请输入数字！`,
        type: 'warning'
      })
      return value
    } else {
      if (parseFloat(value) < 0) {
        Message({
          message: `[${title}] 请输入正数！`,
          type: 'warning'
        })
        return value
      }
      if (value.indexOf(".") != -1) {
        let dotDex = value.indexOf(".") + 1
        let length = value.length
        //判断小数点的位数
        if (length - dotDex > 2) {
          Message({
            message: '当前小数超过两位，系统将把小数四舍五入为最接近的两位小数！',
            type: 'warning',
            duration: 3000
          })
          let money = parseFloat(value).toFixed(2)
          return money
        } else {
          return value
        }
      } else {
        return value
      }
    }
  } else {
    return 1
  }
}

//验证两位数  两位小数价格（无区间）
const verifyPriceTo3 = (value, title) => {
  if (value !== undefined && value !== null) {
    value += ''
    value = value.trim()
    if (isNaN(value)) {
      Message({
        message: `[${title}] 请输入数字！`,
        type: 'warning'
      })
      return value
    } else {
      if (value.indexOf(".") != -1) {
        let dotDex = value.indexOf(".") + 1
        let length = value.length
        //判断小数点的位数
        if (length - dotDex > 2) {
          Message({
            message: '当前小数超过两位，系统将把小数四舍五入为最接近的两位小数！',
            type: 'warning',
            duration: 3000
          })
          let money = parseFloat(value).toFixed(2)
          return money
        } else {
          return value
        }
      } else {
        return value
      }
    }
  } else {
    return 1
  }
}

//验证并修改价格 两位数(一定返回值,需要带验证参数、对比参数，验证值名称，对比值名称)
const verifyPriceReturn = (price, oldPrice, title, oldTitle) => {
  //判断是否是原始类型
  if (price != undefined && price != null) {
    //判断是否有值
    let value = $.trim(price)
    let reg = /^[0-9.]+$/;
    let modify = reg.test(value)
    if (value.length == 0) {
      Message({
        message: title + '不能为空,系统将自动设置为' + oldTitle + '！',
        type: 'warning'
      })
      return oldPrice
    }
    //判断是否是数字类型
    if (!modify) {
      Message({
        message: '请输入数字类型,系统将自动设置为' + oldTitle + '！',
        type: 'warning'
      })
      return oldPrice
    }
    //判断是否大于0 
    if (parseFloat(value) < 0) {
      Message({
        message: title + "请输入正数,系统将自动设置为正数！",
        type: 'warning'
      })
      let toPrice = Math.abs(value)
      return toPrice.toFixed(2)
    }
    let dotDex = 0
    if (value.indexOf(".") != -1) {
//    dotDex = value.indexOf(".") + 1
//    let length = value.length
//    //判断小数点的位数
//    if (length - dotDex > 2) {
//      Message({
//        message: '当前' + title + '小数超过两位，系统将把小数四舍五入为最接近的两位小数！',
//        type: 'warning',
//        duration: 3000
//      })
//      return parseFloat(value).toFixed(2)
//    }
	  return parseFloat(value).toFixed(2)
    } else {
      return value
    }
  } else {
    return 0
  }
}


//重新获取数字 返回数字类型(大于1)
const verifyReNumber = (value, type, numType) => {
  let returnNum = 0
  if (value !== undefined && value !== null && value != '') {
    if (typeof value == 'string') {
      value = value.trim()
      let reg = /^[0-9.]+$/;
      let modify = reg.test(value)
      if (!modify) {
        Message({
          message: '只能输入数字类型',
          type: 'warning'
        })
        return value
      }
    }
    if (type) {
      returnNum = parseFloat(value) - 1
    } else {
      returnNum = parseFloat(value) + 1
    }
    if (numType !== undefined) {
      return parseFloat(returnNum).toFixed(2)
    } else {
      return returnNum
    }
  } else {
    return 1
  }
}

//当前填写的数量是否正确（一定会返回值>0 参数1：要验证的值 参数2 对比值  参数3 提示语标题 参数4 对比的提示语）
const verifyProductNum = (num, oldNum, title, oldTitle) => {
  let input = 1
  if (num !== undefined && num !== null) {
    if (typeof num == 'string') {
      input = $.trim(num); //当前填写的数量
      input = parseInt(input)
    } else {
      input = parseInt(num); //当前填写的数量
    }
    let totalNum = oldNum; //当前的库存数量
    let reg = /^[0-9]+$/;
    let modify = reg.test(input)
    //判断当前的产品数量是否为正整数并且当前的数量小于等于库存数量
    if (input !== '' && input !== null && input !== undefined && input != 0) {
      if (!(modify && input > 0 && input % 1 === 0)) {
        if (!modify) {
          Message({
            message: title + '必须是数字，系统将自动设置数量为1！',
            type: 'warning'
          });
          return 1
        } else if (input < 0) {
          Message({
            message: title + '必须是正整数，系统将自动设置为正整数！',
            type: 'warning'
          });
          return Math.abs(input)
        } else if (input % 1 !== 0) {
          Message({
            message: title + '未输入正整数，系统将自动设置为正整数！',
            type: 'warning'
          });
          return Math.floor(input)
        }
      } else {
        if (input > totalNum) {
          Message({
            message: title + '不能超过' + oldTitle + ' [' + totalNum + '] ，系统将自动设置数量为最大' + oldTitle + '！',
            type: 'warning'
          });
          return totalNum
        } else {
          return input
        }
      }
    } else {
      Message({
        message: title + '最少填写一个数量，系统将自动设置数量为1！',
        type: 'warning'
      });
      return 1
    }
  } else {
    return 1
  }

}


//验证库存数量剩余可用值(参数1：最大数 参数2:当前填写的数 参数3：已被使用的数)
const getProductNum = (value1, value2, choiceNum, type) => {
  let selectNum = 0
  selectNum = parseInt(value1) - parseInt(choiceNum)
  if (selectNum <= 0) {
    selectNum = 0
    if (type === undefined) {
      Message({
        message: '当前产品数量已超过库存数量,可添加数量仅为' + selectNum,
        type: 'warning',
        duration: 2000
      })
    }
    return selectNum
  }
  //判断当前的数量是否大于库存数
  if ((parseInt(value2) + parseInt(choiceNum)) > parseInt(value1)) {
    if (parseInt(selectNum) > 0) {
      if (type === undefined) {
        Message({
          message: '当前产品数量已超过库存数量,可添加数量仅为 [' + selectNum + '] 系统将自动设置为最大数！',
          type: 'warning',
          duration: 2000
        })
      }
    }
    return selectNum //返回0 则禁止当前的勾选操作
  } else {
    return -1 //返回-1代表可以继续进行操作
  }
}




//团队模块 正则获取当前的元素价格
const verifyPrice = function (value) {
  //判断当前是否有值
  if (value !== '' && value !== null && value !== undefined) {
    //判断当前是否有连接符(-)
    if (value.indexOf("-") != -1) {
      let price = value.split("-")[0].trim()
      return price.replace(/[^0-9.]/ig, "")
    } else if (value.indexOf("$") != -1) {
      //判断是否有连接符($)
      return value.replace(/[^0-9.]/ig, "")
    } else {
      return value
    }
  } else {
    return 0
  }
}

//团队模块 获取权限下的可显示人员(展示部门类型)
const userShowInfo = function (id) {
  //获取当前用户的信息
  if (store.state.userinfo == '') {
    store.commit('getUserInfo');
  }
  //加载全部员工
  if (store.state.userlistall == "") {
    store.commit('getUserlistAll');
  }
  //所有部门
  if (store.state.deptlistall == "") {
    store.commit('getDeptlistAll');
  }
  //获取当前所有的用户
  if (store.state.userinfo.user_role == 2) {
    let curArr = []
    let curUser = []
    if (store.state.teamPowerInfo == '') {
      store.dispatch('getCurPower')
    }
    for (let i = 0, len = store.state.teamPowerInfo.length; i < len; i++) {
      //id代表当前判断的类型（1：组织架构 2：...）
      if (store.state.teamPowerInfo[i].id == id) {
        if (store.state.teamPowerInfo[i].type == 1) {
          return getUserClassName(store.state.userlistall)
        } else if (store.state.teamPowerInfo[i].type == 0) {
          //部门管理员中可查看本公司的员工
          for (let j = 0, jLen = store.state.deptlistall.length; j < jLen; j++) {
            if (store.state.userinfo.dept_id == store.state.deptlistall[j].dept_id) {
              curArr.push(store.state.deptlistall[j])
            }
          }
          $.each(store.state.userlistall, function (b, user) {
            $.each(curArr, function (index, dept) {
              if (dept.dept_id == user.dept_id) {
                if (dept.dept_name != '') {
                  user['dept_name'] = ' (' + dept.dept_name + ')'
                } else {
                  user['dept_name'] = '  '
                }
                curUser.push(user)
                return false;
              }
            });
          })
          return curUser
        } else if (store.state.teamPowerInfo[i].type == 2) {
          //选择对应的部门仅查看选择部门的用户信息
          let arr = store.state.teamPowerInfo[i].classArr.split(",")
          for (let f = 0, fLen = arr.length; f < fLen; f++) {
            for (let j = 0, jLen = store.state.deptlistall.length; j < jLen; j++) {
              if (Number(arr[f]) == store.state.deptlistall[j].dept_id) {
                curArr.push(store.state.deptlistall[j])
              }
            }
          }
          $.each(store.state.userlistall, function (b, user) {
            $.each(curArr, function (index, dept) {
              if (dept.dept_id == user.dept_id) {
                if (dept.dept_name != '') {
                  user['dept_name'] = ' (' + dept.dept_name + ')'
                } else {
                  user['dept_name'] = ''
                }
                curUser.push(user)
                return false;
              }
            });
          })
          return curUser
        }
        break;
      }
    }
  } else if (store.state.userinfo.user_role == 1) {
    return getUserClassName(store.state.userlistall)
  }
}


//团队模块 判断当前用户的操作权限
const getUserPower = function (type, id) {
  //获取当前用户的信息
  if (store.state.userinfo == '') {
    store.commit('getUserInfo');
  }
  let isfalse = false; //当前是否可以进行对员工的编辑（系统管理员直接进入）
  let title = ''; //提示语
  //判断当前是查看设么的权限
  if (type == 2) {
    title = '增加'
  } else if (type == 3) {
    title = '删除'
  } else if (type == 4) {
    title = '操作'
  }
  //判断当前有无操作权限(对部门管理员的判断)
  if (store.state.userinfo.user_role == 2) {
    if (store.state.teamPowerInfo.length == 0) {
      store.dispatch('getCurPower')
    }
    //判断当前有无编辑的权限（id）
    $.each(store.state.teamPowerInfo, function (i, row) {
      if (Number(row.id) == id) {
        if (type == 2) {
          if (row.add) {
            isfalse = true
          }
        } else if (type == 3) {
          if (row.dele) {
            isfalse = true
          }
        } else if (type == 4) {
          if (row.edit) {
            isfalse = true
          }
        }
      }
    })
  } else if (store.state.userinfo.user_role == 1) {
    isfalse = true
  } else {
    isfalse = false
  }
  if (!isfalse) {
    if (store.state.userinfo.user_role != 1 && store.state.userinfo.user_role != 2) {
      Message({
        message: '非管理员没有操作权限！',
        type: 'warning'
      })
    } else {
      Message({
        message: '暂无 [ ' + title + ' ] 权限,可联系系统管理员开启此权限 ( 团队 -> 权限控制 ) ！',
        type: 'warning'
      })
    }
    return false
  } else {
    return true
  }
}

//团队模块 判断当前用户的操作权限(无弹框)
const getUserPower2 = function (type, id) {
  //获取当前用户的信息
  if (store.state.userinfo == '') {
    store.commit('getUserInfo');
  }
  let isfalse = false; //当前是否可以进行对员工的编辑（系统管理员直接进入）
  //判断当前有无操作权限(对部门管理员的判断)
  if (store.state.userinfo.user_role == 2) {
    if (store.state.teamPowerInfo.length == 0) {
      store.dispatch('getCurPower')
    }
    //判断当前有无编辑的权限（id）
    $.each(store.state.teamPowerInfo, function (i, row) {
      if (Number(row.id) == id) {
        if (type == 2) {
          if (row.add) {
            isfalse = true
          }
        } else if (type == 3) {
          if (row.dele) {
            isfalse = true
          }
        } else if (type == 4) {
          if (row.edit) {
            isfalse = true
          }
        }
      }
    })
  } else {
    if (store.state.userinfo.user_role == 1) {
      isfalse = true
    }
  }
  if (!isfalse) {
    return false
  } else {
    return true
  }
}

//团队模块 根据当前的类型获取进行展示的部门信息
const getShowClassInfo = function (id) {
  //获取当前用户的信息
  if (store.state.userinfo == '') {
    store.commit('getUserInfo');
  }
  //所有部门
  if (store.state.deptlistall == "") {
    store.commit('getDeptlistAll');
  }
  //获取当前的所有部门
  if (store.state.userinfo.user_role == 2) {
    let curArr = []
    //获取当前的栏目权限
    if (store.state.teamPowerInfo == '') {
      store.dispatch('getCurPower')
    }
    for (let i = 0, len = store.state.teamPowerInfo.length; i < len; i++) {
      //id代表当前判断的类型（1：组织架构 2：...）
      if (store.state.teamPowerInfo[i].id == id) {
        if (store.state.teamPowerInfo[i].type == 1) {
          return store.state.deptlistall
        } else if (store.state.teamPowerInfo[i].type == 0) {
          //部门管理员中可查看本公司的员工
          for (let j = 0, jLen = store.state.deptlistall.length; j < jLen; j++) {
            if (store.state.userinfo.dept_id == store.state.deptlistall[j].dept_id) {
              curArr.push(store.state.deptlistall[j])
            }
          }
          return curArr
        } else if (store.state.teamPowerInfo[i].type == 2) {
          //选择对应的部门仅查看选择部门信息
          let arr = store.state.teamPowerInfo[i].classArr.split(",")
          for (let f = 0, fLen = arr.length; f < fLen; f++) {
            for (let j = 0, jLen = store.state.deptlistall.length; j < jLen; j++) {
              if (Number(arr[f]) == store.state.deptlistall[j].dept_id) {
                curArr.push(store.state.deptlistall[j])
              }
            }
          }
          return curArr
        }
        break;
      }
    }
  } else if (store.state.userinfo.user_role == 1) {
    //系统管理员返回当前的所有用户
    return store.state.deptlistall
  }
}

//团队模块中  给当前获取的用户添加部门显示
const getUserClassName = function (userList) {
  let curUser = []
  //所有部门
  if (store.state.deptlistall == "") {
    store.commit('getDeptlistAll');
  }
  //系统管理员返回当前的所有用户
  $.each(userList, function (b, user) {
    let hasClass = false; //当前用户是否有所属部门
    $.each(store.state.deptlistall, function (index, dept) {
      if (dept.dept_id == user.dept_id) {
        if (dept.dept_name != '') {
          user['dept_name'] = ' (' + dept.dept_name + ')'
        } else {
          user['dept_name'] = ' '
        }
        hasClass = true
        curUser.push(user)
        return false;
      }
    });
    if (!hasClass) {
      user['dept_name'] = ''
      curUser.push(user)
    }
  })
  return curUser
}

//供应商模块  通过用户id去获取用户的名称
const userName=(id)=>{
  //加载全部员工
  if (store.state.userlistall == "") {
    store.commit('getUserlistAll');
  }
  let username=''
  $.each(store.state.userlistall, function (index, user) {
    if (id == user.user_id) {
      username = user.user_name;
      return false;
    }
  });
  return username
}

//根据用户id判断当前客户的名称
const getUserName = (title, id) => {
  //加载全部员工
  if (store.state.userlistall == "") {
    store.commit('getUserlistAll');
  }
  var username = "其它同事";
  if (id == 0) {
    username = '我'
  } else if (id > 0) {
    $.each(store.state.userlistall, function (index, user) {
      if (id == user.user_id) {
        username = user.user_name;
        return false;
      }
    });
  }
  Message({
    message: '该 [' + title + '] 已存在于 [' + username + ' ]中',
    type: 'warning'
  })
}


//全局 去除所在国家的缩写，并判断当前国家是否是在给与的国家数据中
const verifyCountry = function (text) {
  if (text.indexOf("-") != -1) {
    return text.split(" - ")[0]
  } else {
    return text
  }
}

//邮件模块   邮件块每页显示的数量从配置页面中的使用偏好中获取
const mailPageValue = function () {
  //用户控制参数加载
  if (store.state.userparamlist == "") {
    store.commit('getUserParamList');
  }
  if (store.state.userparamlist.param120 !== undefined && store.state.userparamlist.param120 !== null && store.state.userparamlist.param120 > 0) {
    return Number(store.state.userparamlist.param120); //每页显示数
  } else {
    return 20; //每页显示数默认值
  }
}

//验证邮箱是否正确
const verifyEmail = function (value) {
  let mailreg = /^\w+([\.\'-]?[\w+]?)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
  let resultstr = ''
  if (value !== null && value !== undefined) {
    resultstr = $.trim(value);
    if (value == "") {
      Message({
        message: '请输入邮箱！',
        type: 'warning'
      })
      return false
    } else {
      if (!mailreg.test(resultstr)) {
        Message({
          message: '邮箱格式填写错误，请重新输入！',
          type: 'warning'
        })
        return false
      }
      return true
    }
  } else {
    return false
  }
}

//验证号码  只在有值的时候判断是否是纯数字
const verifyTel = function (value, title) {
  if (value !== undefined && value !== null) {
    let tel = $.trim(value)
    let reg = /^[0-9.]+$/;
    let modify = reg.test(tel)
    if (!modify) {
      Message({
        message: title + '必须输入数字',
        type: 'warning'
      })
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

//判断当前用户的ERP是否开通
const userErp = () => {
  let hasErp = false;
  if (store.state.plicencefun == "") {
    store.commit('fun_loadLicencefun');
  }
  $.each(store.state.plicencefun, function (i, elem) {
    if (elem.function_code == "erp_interface") {
      hasErp = true;
    }
  });
  return hasErp
}

//客户模块 某个分类
const hasErp = (id) => {
  let isOpen = false
  let hasErp = userErp()
  if (store.state.customerclassifyall == "") {
    store.commit('getCustomerClassifyAll');
  }
  //判断当前客户分类是否有ERP
  if (hasErp) {
    $.each(store.state.customerclassifyall, function (i, row) {
      if (id == row.id) {
        if (row.is_erp == 1) {
          isOpen = true
        } else {
          isOpen = false;
        }
      }
    });
  }
  return isOpen
}

//产品模块 判断一键ERP是否开通
const hasErpProduct = (id) => {
  let isOpen = false
  let hasErp = userErp()
  //加载所有意向产品信息
  if (store.state.majorproductall == "") {
    store.commit('getMajorProductAll');
  }
  //判断当前产品分类是否有ERP
  if (hasErp) {
    $.each(store.state.majorproductall, function (i, row) {
      if (id == row.id) {
        if (row.is_erp == 1) {
          isOpen = true
        } else {
          isOpen = false;
        }
      }
    });
  }
  return isOpen
}



//获取国家信息code
const countryCode = (country) => {
  let code = ''
  if (store.state.countryArr == '') {
    store.dispatch('getCountryInfo')
  }
  $.each(store.state.countryArr, (i, row) => {
    if (row.name == country) {
      code = row.short
      return
    }
  })
  return code
}


//获取时间时分秒
const time = () => {
  var date = new Date()
  let year = date.getFullYear().toString()
  let mon = date.getMonth() + 1
  mon = mon.toString().length === 2 ? mon : ('0' + mon)
  let day = date.getDate()
  day = day.toString().length === 2 ? day : ('0' + day)
  let hours = date.getHours()
  hours = hours.toString().length === 2 ? hours : ('0' + hours)
  let min = date.getMinutes()
  min = min.toString().length === 2 ? min : ('0' + min)
  let se = date.getSeconds()
  se = se.toString().length === 2 ? se : ('0' + se)
  return `${year}-${mon}-${day} ${hours}:${min}:${se}`
}

//获取转换格式的时间 参数1：string类型  
const modifyTime = (date) => {
  //判断当前的数是否正确
  if (date !== undefined && date !== null && date != '') {
    date = date.split(" ")
    let year = date[0].substr(0, 4)
    let mon = date[0].substr(5, 2)
    let day = date[0].substr(8, 2)
    let hours = date[1].substr(0, 2)
    let min = date[1].substr(3, 2)
    let se = '00'
    let returnTime=''
    returnTime=`${year}-${mon}-${day} ${hours}:${min}:${se}`
    return returnTime
  } else {
    return time()
  }
}




//跳转到新窗口写信页面 参数1：数据  参数2：0：打开写信新窗口 1：邮件详情直接回复
const toWrite = (type) => {
  //获取值
  let returnData = {
    pageTitle: store.getters.editableTabs2[store.getters.editableTabs2.length - 1].title,
    themeContent: '',
    arrIndex: store.getters.editableTabs2.length - 1,
    wlPagetype: store.state.mail.wlPagetype,
    other_senderMail: store.state.other_senderMail,
    popOrimap: store.getters.popOrimap,
    wlMailid: store.getters.wlMailid,
    editableTabs2: JSON.stringify(store.getters.editableTabs2), //对象
    wlThemeInput: store.getters.wlThemeInput,
    left_changeTimenum: store.getters.left_changeTimenum,
    addMailValue: store.getters.addMailValue,
    wlsdeEditAddressee: JSON.stringify(store.getters.wlsdeEditAddressee),
    mailMain1: JSON.stringify(store.getters.mailMain1),
    mailMain2: JSON.stringify(store.getters.mailMain2),
    mailMain3: JSON.stringify(store.getters.mailMain3),
    left_changeSendernum: store.getters.left_changeSendernum,
    content: JSON.stringify(store.getters.content),
    content1: JSON.stringify(store.getters.content1),
    inbox: JSON.stringify(store.getters.inbox),
    secret: store.getters.secret,
    myList: JSON.stringify(store.getters.myList),
    addMailsearch: store.getters.addMailsearch,
    addLinkManState: store.getters.addLinkManState,
    writeLetterCustomerAddr: JSON.stringify(store.getters.writeLetterCustomerAddr),
    copy: store.getters.copy,
  }
  //判断当前是否是新窗口写信
  if (type == 0) {
    let routeUrl = router.resolve({
      path: "/newWindow/newWrite",
      query: returnData
    });
    window.open(routeUrl.href, '_blank');
  } else if (type == 1) {
    router.replace({
      path: "/newWindow/newWrite",
      query: returnData
    })
  }
}



//跳转到新窗口的邮件详情页
const toLookEmail = (data) => {
  //自定义规则   falseValue:代表当前是boolean类型的false trueValue：代表boolean类型的true
  let routeUrl = router.resolve({
    path: "/newWindow/newLookEmail",
    query: {
      pageTitle: data.pageTitle,
      value: data.value,
      mailIndex: data.mailIndex,
      mailType: data.mailType ? 'trueValue' : 'falseValue',
      mmAbnormal: data.myAbnormal,
      mailmainEditor: store.state.mail.mailmainEditor,
      editableTabs2: JSON.stringify(store.getters.editableTabs2),
      gotoInbox: store.getters.gotoInbox,
      updatemailResult: store.getters.updatemailResult,
      content: JSON.stringify(store.getters.content),
      content1: JSON.stringify(store.getters.content1),
      conflictCustomer: store.getters.conflictCustomer,
      editableTabs2Value: JSON.stringify(store.getters.editableTabs2Value),
      popOrimap: store.getters.popOrimap,
      mailAccount: store.getters.mailAccount,
      sendMailStaticType: store.getters.sendMailStaticType,
      mailLeftType: store.state.mail.mailLeftType,
      nAttrMail: JSON.stringify(store.state.nAttrMail)
    }
  });
  window.open(routeUrl.href, '_blank');
}

//关闭当前窗口提示语
const closeWindow = () => {
  MessageBox.confirm("确定关闭当前窗口吗?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    cancelButtonClass: "cancelButtonClass"
  })
    .then(() => {
      closeWindowFun()
    })
    .catch(() => { });
}

//关闭窗口的执行函数
const closeWindowFun = () => {
  var browserName = navigator.appName;
  if (browserName == "Netscape") {
    window.open("", "_self", "");
    window.close();
  }
  if (browserName == "Microsoft Internet Explorer") {
    window.parent.opener = "whocares";
    window.parent.close();
  }
}


//返回数组格式的字符
const returnString = (arr) => {
  let ids = ''
  //判断当前是否为数组形式
  if (typeof arr == 'object') {
    ids = arr.join(",")
  } else {
    ids = arr
  }
  return ids
}

//共用的下载表头说明
const downloadInfo = () => {
  let tableData = [
    {
      name: "业务员 *",
      icon: "→",
      msg: "员工姓名"
    },
    {
      name: "企业全称 *",
      icon: "→",
      msg: "企业全称"
    },
    {
      name: "联系人姓名",
      icon: "→",
      msg: "联系人姓名"
    },
    {
      name: "所在国家",
      icon: "→",
      msg: "所在国家"
    },
    {
      name: "联系人邮箱 *",
      icon: "→",
      msg: "邮箱地址"
    },
    {
      name: "首次联系",
      icon: "→",
      msg: "首次联系"
    },
    {
      name: "联系人电话",
      icon: "→",
      msg: "电话号码"
    },
    {
      name: "客户分类",
      icon: "→",
      msg: "客户分类"
    },
    {
      name: "客户来源",
      icon: "→",
      msg: "客户来源"
    },
    {
      name: "客户关系",
      icon: "→",
      msg: "客户关系"
    },
    {
      name: "客户状态",
      icon: "→",
      msg: "客户状态"
    },
    {
      name: "所属行业",
      icon: "→",
      msg: "所属行业"
    },
    {
      name: "意向产品",
      icon: "→",
      msg: "意向产品"
    },
    {
      name: "省/州/区",
      icon: "→",
      msg: "省/州/区"
    },
    {
      name: "城市",
      icon: "→",
      msg: "城市"
    },
    {
      name: "企业简称",
      icon: "→",
      msg: "企业简称"
    },
    {
      name: "员工规模",
      icon: "→",
      msg: "员工规模"
    },
    {
      name: "手机",
      icon: "→",
      msg: "手机"
    },
    {
      name: "职位",
      icon: "→",
      msg: "职位"
    },
    {
      name: "传真",
      icon: "→",
      msg: "传真"
    },
    {
      name: "网址",
      icon: "→",
      msg: "网址"
    },
    {
      name: "地址",
      icon: "→",
      msg: "地址"
    },
    {
      name: "facebook",
      icon: "→",
      msg: "facebook"
    },
    {
      name: "twitter",
      icon: "→",
      msg: "twitter"
    },
    {
      name: "linkedin",
      icon: "→",
      msg: "linkedin"
    },
    {
      name: "google+",
      icon: "→",
      msg: "google+"
    },
    {
      name: "tumblr",
      icon: "→",
      msg: "tumblr"
    },
    {
      name: "instagram",
      icon: "→",
      msg: "instagram"
    },
    {
      name: "vk",
      icon: "→",
      msg: "vk"
    },
    {
      name: "made in china",
      icon: "→",
      msg: "made in china"
    },
    {
      name: "alibaba",
      icon: "→",
      msg: "alibaba"
    },
    {
      name: "skype",
      icon: "→",
      msg: "skype"
    },
    {
      name: "youtube",
      icon: "→",
      msg: "youtube"
    },
    {
      name: "客户星级",
      icon: "→",
      msg: "客户星级"
    },
    {
      name: "pinterest",
      icon: "→",
      msg: "pinterest"
    },
    {
      name: "客户编号",
      icon: "→",
      msg: "客户编号"
    },
  ]

  return tableData
}

//共用的下载表头说明2
const downloadInfo2 = () => {
  let tableData = [
    {
      name: "1",
      msg: "产品编号*"
    },
    {
      name: "2",
      msg: "产品分类"
    },
    {
      name: "3",
      msg: "产品名称*"
    },
    {
      name: "4",
      msg: "产品单位"
    },
    {
      name: "5",
      msg: "产品规格"
    },
    {
      name: "6",
      msg: "起订量"
    },
    {
      name: "7",
      msg: "产品单价"
    },
    {
      name: "8",
      msg: "库存数量"
    },
    {
      name: "9",
      msg: "成本价格"
    },
    {
      name: "10",
      msg: "单个重量"
    },
    {
      name: "11",
      msg: "产品简述"
    }
  ]

  return tableData
}

//判断供应商的权限(是否有操作权限)
const supplierPower=()=>{
    //用户控制参数加载
  if (store.state.userparamlist == "") {
    store.commit('getUserParamList');
  }
  if (store.state.userparamlist.param238 !== undefined && store.state.userparamlist.param238 !== null && store.state.userparamlist.param238 > 0) {
    if(store.state.userparamlist.param238<2){
      Message({
        message:'暂无操作权限，可联系系统管理员开启此权限 ( 团队 -> 组织架构 )！',
        type:'warning'
      })
      return false
    }
    return true
  }else{
    Message({
      message:'暂无操作权限，可联系系统管理员开启此权限 ( 团队 -> 组织架构 )！',
      type:'warning'
    })
    return false
  }
}

// 将跟进内容中的 邮件模块IMAP发信，|邮件模块POP发信，|营销模块发信，替换为 空 ""
const replaceText = (old_text) =>{
  let new_text = '';
  if(old_text.length > 0){
    new_text =  old_text.replace(/邮件模块IMAP发信，|邮件模块POP发信，|营销模块发信，/g, "");
  }
  return new_text;
}

// 清除 html 标签
const clearHtml = (targetHtml) => {
  let new_text = '';
  if(targetHtml.length > 0){
    new_text = targetHtml.replace(/<\/?.+?>/g,"");
    new_text = new_text.replace(/&nbsp;/g,"");
  }
  return new_text;
}

//邮件模块 显示可隐藏标签
const showNav=(event)=>{
  let more = $(event.currentTarget).children('.it-select');
  let more2 = $(event.currentTarget).children('.smt-select');
      more.show();
      more2.show();
}

//邮件模块 隐藏可hover的标签
const hideNav=(event)=>{
  let more = $(event.currentTarget).children('.it-select');
  let more2= $(event.currentTarget).children('.smt-select')
      more.hide();
      more2.hide();
}


export default {
  judgeFileType,
  getOrderDates,
  verifyTime,
  getFileType,
  viewFileWay,
  orderExportInfo,
  getAddrInfo,
  modifyNumber,
  modifyNumber2,
  verifyReNumber,
  verifyText,
  verifyNumber,
  getProductNum,
  verifyPrice,
  verifyPriceTo2,
  verifyPriceTo3,
  verifyPriceReturn,
  verifyProductPrice,
  verifyProductNum,
  userShowInfo,
  getUserPower,
  getUserPower2,
  getUserName,
  userName,
  getShowClassInfo,
  getUserClassName,
  uploadFileType,

  verifyCountry,
  mailPageValue,
  verifyEmail,
  verifyTel,
  userErp,
  hasErp,
  hasErpProduct,
  countryCode,
  time,
  modifyTime,
  toWrite,
  toLookEmail,
  closeWindow,
  closeWindowFun,
  returnString,
  downloadInfo,
  downloadInfo2,
  supplierPower,
  showNav,
  hideNav,

  replaceText,
  clearHtml,
}
