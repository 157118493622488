// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：导航·公共变量
// 开始时间：2021-01-19
// 开发人员：刘巍骏
// 最后修改：2021-01-19
// 备注说明：如需修改请联系开发人员

import actions from "./actions.js"
import mutations from "./mutations.js"

const state = {
  // 控制导航栏是否展开
  showNavbar_left: false, //左侧导航栏
  showNavbar_top: true, //顶部导航栏

  //顶部导航栏内容
  crumbs_name: ['首页'],

  menu_config: [],

  // 菜单栏内容
  mainNavData: [{
      name: '日常工作',
      shortName: '工作',
      // shortName:`$t(menu.ls)`,
      id: 0,
      classfi: [{
          name: '收发邮件',
          child: [{
              name: '写信',
              id: 0.1
            },
            {
              name: '收信',
              id: 0.1
            },
            {
              name: '未读邮件',
              id: 0.1
            },
            {
              name: '待办邮件',
              id: 0.1
            },
          ]
        },
        {
          name: '订单管理',
          child: [{
              name: '报价单',
              id: 'bjdnew'
            },
            {
              name: '入库单',
              id: 0.1
            }
          ]
        },
        {
          name: '工作审批',
          child: [{
              name: '邮件',
              id: 0.1
            },
            {
              name: '单据',
              id: 0.1
            },
            {
              name: '工作',
              id: 0.1
            },
            {
              name: '个人',
              id: 0.1
            },
          ]
        },
        {
          name: '工作汇报',
          child: [{
              name: '日报',
              id: 0.1
            },
            {
              name: '周报',
              id: 0.1
            },
            {
              name: '月报',
              id: 0.1
            },
            {
              name: '总结',
              id: 0.1
            },
          ]
        },
      ]
    },
    {
      name: '销售管理',
      shortName: '销售',
      id: 1,
      classfi: [{
          name: '单据',
          child: [{
              name: '报价单',
              id: 'bjd1'
            },
            {
              name: '订单',
              id: 'dd'
            },
            {
              name: '发票',
              id: 0.1
            },
            {
              name: '收款单',
              id: 'skd'
            },
            {
              name: '退货单',
              id: 'thd'
            }
          ]
        },
        {
          name: '销售工作台',
          child: [{
              name: '日清统计',
              id: 0.1
            },
            {
              name: '日清对帐',
              id: 0.1
            }
          ]
        },
        {
          name: '报表',
          child: [{
              name: '订单统计表',
              id: 0.1
            },
            {
              name: '订单执行表',
              id: 0.1
            },
            {
              name: '销货单统计表',
              id: 0.1
            },
            {
              name: '毛利分析表',
              id: 0.1
            },
            {
              name: '销售排行榜',
              id: 0.1
            },
          ]
        },
      ]
    },
    {
      name: '客户管理',
      shortName: '客户',
      id: 2,
      classfi: [{
        name: '客户管理',
        child: [{
            name: '新建客户',
            id: 'xjkh'
          },
          {
            name: '新建跟进',
            id: 0.1
          },
          {
            name: '公海客户',
            id: 0.1
          },
          {
            name: '客户查重',
            id: 0.1
          },
          {
            name: '将入公海',
            id: 0.1
          },
          {
            name: '我的共享',
            id: 0.1
          },
          {
            name: '同事共享',
            id: 0.1
          },
        ]
      }, ]
    },
    {
      name: '生产管理',
      shortName: '生产',
      id: 2,
      classfi: [{
          name: '看板',
          child: [{
              name: '生产进度',
              id: 0.1
            },
            {
              name: '出货进度',
              id: 0.1
            }
          ]
        },
        {
          name: '单据',
          child: [{
              name: '生产计划单(MRP)',
              id: 'scjhd'
            },
            {
              name: '领料出库单',
              id: 'llckd'
            },
            {
              name: '退料入库单',
              id: 'tlrkd'
            },
            {
              name: '委外付款单',
              id: "wwfkd"
            },
            {
              name: '补料单',
              id: "bld"
            },
            {
              name: '生产报价',
              id: 0.1
            }
          ]
        },
        {
          name: '生产',
          child: [{
              name: '工序管理',
              id: 'gxgl'
            },
            {
              name: 'BOM管理',
              id: 'bom'
            },
            {
              name: '成本分摊',
              id: 0.1
            },
            {
              name: '计件工资',
              id: 0.1
            },
            {
              name: '计时工资',
              id: 0.1
            }
          ]
        },
        {
          name: '统计',
          child: [{
              name: '工序汇报',
              id: 0.1
            },
            {
              name: '成本明细',
              id: 0.1
            }
          ]
        },
      ]
    },
    {
      name: '库存采购',
      shortName: '库存',
      id: 2,
      classfi: [{
          name: '采购',
          child: [{
              name: '采购申请单',
              id: 'cgsqd'
            },
            {
              name: '采购订单',
              id: 'cgdd'
            },
            {
              name: '采购入库单',
              id: 'cgrkd'
            },
            {
              name: '采购退货单',
              id: 'cgthd'
            },
            {
              name: '采购付款单',
              id: 'cgfkd'
            },
            {
              name: '采购对帐单',
              id: 'cgdzd'
            }
          ]
        },
        {
          name: '仓库',
          child: [{
              name: '入库单',
              id: 'rkd'
            },
            {
              name: '出库单',
              id: 'ckd'
            },
            {
              name: '掉拔单',
              id: 'dbd'
            },
            {
              name: '报损单',
              id: 'bsd'
            },
            {
              name: '组装单',
              id: 'zzd'
            },
            {
              name: '拆卸单',
              id: 'cxd'
            },
            {
              name: '调价单',
              id: 'tjd'
            },
            {
              name: '盘点单',
              id: 0.1
            }
          ]
        },
        {
          name: '统计',
          child: [{
              name: '库存',
              id: 0.1
            },
            {
              name: '序列号',
              id: 0.1
            },
            {
              name: '保质期',
              id: 0.1
            },
            {
              name: '在途货品',
              id: 0.1
            },
            {
              name: '采购订单明细',
              id: 0.1
            },
            {
              name: '采购单明细',
              id: 0.1
            },
            {
              name: '调拨单明细',
              id: 0.1
            },
            {
              name: '出入库流水',
              id: 0.1
            }
          ]
        },
      ]
    },
    {
      name: '营销管理',
      shortName: '营销',
      id: 2,
      classfi: [{
        name: '营销管理',
        child: [{
            name: '群发',
            id: 0.1
          },
          {
            name: '模板',
            id: 0.1
          },
          {
            name: '自动化',
            id: 0.1
          },
          {
            name: '规则',
            id: 0.1
          },
          {
            name: '追踪',
            id: 0.1
          }
        ]
      }, ]
    },
    {
      name: '人力资源',
      shortName: '人力',
      id: 2,
      classfi: [{
          name: '组织架构',
          child: [{
              name: '角色管理',
              id: 0.1
            },
            {
              name: '部门管理',
              id: 0.1
            },
            {
              name: '员工管理',
              id: 0.1
            }
          ]
        },
        {
          name: '工资管理',
          child: [{
              name: '月薪管理',
              id: 0.1
            },
            {
              name: '计时工资',
              id: 0.1
            },
            {
              name: '计件工资',
              id: 0.1
            },
            {
              name: '销售提成',
              id: 0.1
            },
            {
              name: '绩效工资',
              id: 0.1
            },
            {
              name: '奖金分红',
              id: 0.1
            },
            {
              name: '违规扣薪',
              id: 0.1
            }
          ]
        },
        {
          name: '绩效管理',
          child: [{
              name: '目标设定',
              id: 0.1
            },
            {
              name: '绩效设定',
              id: 0.1
            },
            {
              name: '积分管理',
              id: 0.1
            }
          ]
        },
        {
          name: '员工关怀',
          child: [{
              name: '关怀例表',
              id: 0.1
            },
            {
              name: '触发规则',
              id: 0.1
            },
            {
              name: '模板设置',
              id: 0.1
            }
          ]
        },
        {
          name: '人事表单',
          child: [{
              name: '招聘申请单',
              id: 0.1
            },
            {
              name: '录用通知单',
              id: 0.1
            },
            {
              name: '上岗（复职）申请单',
              id: 0.1
            },
            {
              name: '离职申请单',
              id: 0.1
            },
            {
              name: '转岗通知单',
              id: 0.1
            },
            {
              name: '事件通知单',
              id: 0.1
            }
          ]
        },
      ]
    },
    {
      name: '文件管理',
      shortName: '文件',
      id: 2,
      classfi: [{
        name: '文件管理',
        child: [{
            name: '上传文件',
            id: 0.1
          },
          {
            name: '最新文件',
            id: 0.1
          },
          {
            name: '个人文件',
            id: 0.1
          },
          {
            name: '客户文件',
            id: 0.1
          },
          {
            name: '共享文件',
            id: 0.1
          }
        ]
      }, ]
    },
    {
      name: '资金管理',
      shortName: '资金',
      id: 2,
      classfi: [{
          name: '往来帐',
          child: [{
              name: '应付款',
              id: 0.1
            },
            {
              name: '付款单',
              id: 'fkd'
            },
            {
              name: '应收款',
              id: 0.1
            },
            {
              name: '收款单',
              id: 'skd'
            }
          ]
        },
        {
          name: '资金',
          child: [{
              name: '支出单',
              id: 0.1
            },
            {
              name: '收入单',
              id: 0.1
            },
            {
              name: '预付单',
              id: 0.1
            },
            {
              name: '预收单',
              id: 0.1
            },
            {
              name: '固定资产',
              id: 0.1
            },
            {
              name: '帐户互转',
              id: 0.1
            }
          ]
        },
        {
          name: '报销',
          child: [{
              name: '报销申请',
              id: 'bxsqd'
            },
            {
              name: '费用报销',
              id: 'fybxd'
            },
            {
              name: '报销审批',
              id: 0.1
            }
          ]
        },
        {
          name: '查询',
          child: [{
              name: '资金账户',
              id: 0.1
            },
            {
              name: '资金流水',
              id: 0.1
            }
          ]
        },
        {
          name: '其它',
          child: [{
              name: '支出类别',
              id: 0.1
            },
            {
              name: '收入类别',
              id: 0.1
            }
          ]
        },
      ]
    },
    {
      name: '财务管理',
      shortName: '财务',
      id: 2,
      classfi: [{
          name: '凭证',
          child: [{
              name: '凭证管理',
              id: 0.1
            },
            {
              name: '凭证汇总表',
              id: 0.1
            },
            {
              name: '待开凭证管理',
              id: 0.1
            }
          ]
        },
        {
          name: '账簿',
          child: [{
              name: '总帐',
              id: 0.1
            },
            {
              name: '明细帐',
              id: 0.1
            },
            {
              name: '科目余额表',
              id: 0.1
            },
            {
              name: '辅助核算明细',
              id: 0.1
            }
          ]
        },
        {
          name: '结帐',
          child: [{
            name: '期未结转',
            id: 0.1
          }]
        },
        {
          name: '资产',
          child: [{
            name: '固定资产',
            id: 0.1
          }]
        },
        {
          name: '资料',
          child: [{
              name: '会计科目',
              id: 0.1
            },
            {
              name: '凭证模板',
              id: 0.1
            },
            {
              name: '单据科技目关联',
              id: 0.1
            }
          ]
        },
      ]
    },
    {
      name: '报表统计',
      shortName: '报表',
      id: 2,
      classfi: [{
          name: '销售报表',
          child: [{
              name: '商品销量分析表',
              id: 0.1
            },
            {
              name: '销售汇总表',
              id: 0.1
            },
            {
              name: '销售业绩分析',
              id: 0.1
            },
            {
              name: '销售成本分析',
              id: 0.1
            },
            {
              name: '销售成本明细分析',
              id: 0.1
            },
            {
              name: '销售提成统计',
              id: 0.1
            },
            {
              name: '销售提成明细',
              id: 0.1
            }
          ]
        },
        {
          name: '生产报表',
          child: [{
              name: '生产情况统计',
              id: 0.1
            },
            {
              name: '生产成本统计',
              id: 0.1
            },
            {
              name: '委外进度跟踪',
              id: 0.1
            },
            {
              name: '订单进度跟踪',
              id: 0.1
            },
            {
              name: '未完工序统计',
              id: 0.1
            },
            {
              name: '工序预警',
              id: 0.1
            },
            {
              name: '物料追踪',
              id: 0.1
            },
            {
              name: '缺料追踪',
              id: 0.1
            },
            {
              name: '计件工资统计',
              id: 0.1
            },
            {
              name: '生产工时统计',
              id: 0.1
            },
          ]
        },
        {
          name: '库报报表',
          child: [{
              name: '采购汇总表',
              id: 0.1
            },
            {
              name: '出入库汇总表',
              id: 0.1
            },
            {
              name: '仓库统计',
              id: 0.1
            },
            {
              name: '商口出入库统计',
              id: 0.1
            }
          ]
        },
        {
          name: '资金报表',
          child: [{
              name: '供应商对帐明细',
              id: 0.1
            },
            {
              name: '客户对帐统计表',
              id: 0.1
            },
            {
              name: '客户对帐明细',
              id: 0.1
            },
            {
              name: '未收帐款统计',
              id: 0.1
            },
            {
              name: '利润统计',
              id: 0.1
            },
            {
              name: '费用支出统计',
              id: 0.1
            },
            {
              name: '其他收入统计',
              id: 0.1
            }
          ]
        }
      ]
    },
    {
      name: '基础资料',
      shortName: '资料',
      id: 2,
      classfi: [{
          name: '基本信息',
          child: [{
              name: '公司资料',
              id: 'gszl'
            },
            {
              name: '授权信息',
              id: 'sqxx'
            },
            {
              name: '授权模块',
              id: 'sqmk'
            },
            {
              name: '客户期初',
              id: 'khqc'
            }
          ]
        },
        {
          name: '货品管理',
          child: [{
              name: '货品管理',
              id: 'hpgl'
            },
            {
              name: '新增货品',
              id: 'xzhp'
            },
            {
              name: '货品分类',
              id: 'hpfl'
            },
            {
              name: '计量单位',
              id: 'jldw'
            },
            {
              name: '品牌管理',
              id: 'ppgl'
            },
            {
              name: '货品属性',
              id: 'hpsx'
            },
            {
              name: '货品来源',
              id: 'hply'
            },
            {
              name: '规格组合',
              id: 'ggzh'
            },
          ]
        },
        {
          name: '供应商管理',
          child: [{
              name: '新增供应商',
              id: 'gys'
            },
            {
              name: '分类管理',
              id: 0.1
            },
            {
              name: '供应商管理',
              id: 0.1
            }
          ]
        },
        {
          name: '设备管理',
          child: [{
              name: '新增设备',
              id: 0.1
            },
            {
              name: '设备类型',
              id: 0.1
            },
            {
              name: '设备管理',
              id: 0.1
            }
          ]
        }
      ]
    },
    {
      name: '系统配置',
      shortName: '系统',
      id: 2,
      classfi: [{
          name: '基本设置',
          child: [{
              name: '基础信息',
              id: 0.1
            },
            {
              name: '安全配置',
              id: 0.1
            },
            {
              name: '邮件配置',
              id: 0.1
            },
            {
              name: '短信配置',
              id: 0.1
            },
            {
              name: '微信配置',
              id: 'wxpz'
            },
            {
              name: '营销配置',
              id: 0.1
            }
          ]
        },
        {
          name: '控制参数',
          child: [{
              name: '客户配置',
              id: 'khpz'
            },
            {
              name: '生产配置',
              id: 'scpz'
            },
            {
              name: '货品配置',
              id: 'hppz'
            },
            {
              name: '财务配置',
              id: 'cwpz'
            },
            // {
            //   name:'单据配置',
            //   id:'djpz'
            // }
          ]
        },
        {
          name: '个性化配置',
          child: [{
              name: '条码规则',
              id: 'tmgz'
            },
            {
              name: '单据模板',
              id: 'djmb'
            },
            {
              name: '消息配置',
              id: 'xxpz'
            },
            {
              name: '菜单配置',
              id: 'cdpz'
            }
          ]
        },
        {
          name: '高级设置',
          child: [{
              name: '数据字典',
              id: 'sjzd'
            },
            {
              name: '流程设置',
              id: 'lcsz'
            },
            {
              name: '节点设置',
              id: 'jdsz'
            },
            {
              name: '审批设置',
              id: 'spsz'
            },
            {
              name: '权限设置',
              id: 'qxsz'
            }
          ]
        }
      ]
    },
    {
      name: '更多功能',
      shortName: '更多',
      id: 2,
      classfi: [{
        name: '更多',
        child: [{
            name: '询盘',
            id: 0.1
          },
          {
            name: '网站',
            id: 0.1
          },
          {
            name: '社交',
            id: 0.1
          },
          {
            name: '个人',
            id: 0.1
          },
          {
            name: '通讯录',
            id: 0.1
          },
          {
            name: '测试',
            id: 'cs'
          },
          {
            name: '测试1',
            id: 'cs1'
          },
          {
            name: '打印预览',
            id: 'dyyl'
          },
          {
            name: '单据测试',
            id: 'djcs'
          }
        ]
      }, ]
    },
  ]
}

export default {
  state,
  actions,
  mutations
}
