 <!--
    程序版本：V2.0
    版权所有：深圳市科飞时速网络科技有限公司
    技术支持：info@21gmail.com
    单元名称：icon图标公用页面
    开始时间：2021-1-20
    开发人员：刘巍骏
    最后修改：2021-1-20
    备注说明：如需修改请联系开发人员
  -->
<template>
  <span :class="classes" :style="styles" class="icon">
    <slot></slot>
  </span>
</template>

<script>
  export default {
      name: "icons",
      props: {
        icon: {
          type: String,
          default: ""
        },
        size: {
          type: Number,
          default: 29
        },
        color: {
          type: String,
          default: "#a0a0a0"
        }
      },
     computed: {
        classes () {
          return [
            'iconfont',
            `${this.icon}`
          ]
        },
        styles () {
          return {
            color: this.color,
            fontSize: `${this.size}px`
          }
        }
      }
    };
</script>

<style>
  .icon {
    position: relative;
    line-height: auto;
  }
</style>
