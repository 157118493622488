/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：系统·公共变量
 * 开始时间：2019-8-4
 * 开发人员：陈立珍
 * 最后修改：2019-8-4
 * 备注说明：如需修改请联系开发人员
 */
import actions from "./actions.js"
import mutations from "./mutations.js"

const state = {
  /* 数据字典左侧导航栏下标*/
  DictionaryLeftIndex: 0,

  /* 客户相关 */
  CustomerClassfiy: [], //客户分类
  CustomerState: [], //客户状态
  CustomerSource: [], //客户来源
  CustomerRelation: [], //客户关系
  CustomerProcess: [], //销售进程
  CustomerFollowupWay: [], //跟进方式
  CustomerMember: [], //会员等级
  CustomerTabIndex: 0, //客户字典选项卡下标

  /* 仓储相关 */
  LogisticsCompany: [], //物流公司
  LogisticsMode: [], //物流方式
  WarehouseConfig: [], //仓库配置
  DeliveryType: [], //送货方式
  QualityType: [], //质检类型
  TestWay: [], //检验方式
  DypeofDocument: [], //单据类型
  StorageTabIndex: 0, //仓储相关选项卡当前选中的下标

  /* 财务相关 */
  FinanceCurrency: [], //交易币种
  PaymentType: [], //收款类型
  FinanceCostType: [], //费用分类
  CostInvoiceType: [], //发票类型
  SysCostBankInfo: [], //收款账号
  FinanceTabIndex: 0, //财务相关选项卡当前选中的下标

  /* 生产相关 */
  ProductionBomType: [], //BOM类
  ProcessType: [], //工序类型
  CostType: [], //计费方式
  ManufactureType: [], //生产类型
  ProcessUnit: [], //加工单位
  ProductionTabIndex: 0, //生产相关选项卡当前选中的下标

  /* 供应商相关*/
  SupplierType: [], //供应商分类管理
  MemberShip: [], //会员等级
  SupplierTabIndex: 0, //供应商相关选项卡当前选中的下标

  /* 货品相关 */
  ProductClassfiy: [], //货品分类
  ProductUnit: [], //货品单位
  ProductSpecification: [], //货品规格名称
  Brand: [], //品牌管理
  ProductProperty: [], //货品属性
  ProductSource: [], //货品来源
  ProductTabIndex: 0, //货品相关选项卡当前选中的下标

  /*订单相关*/
  OrderDocumentType: [], //单据类型
  OrderType: [], //报价类型
  OrderSettlement: [], //结算方式
  PurchaseType: [], //采购方式
  PriceClause: [], //价格条款
  PaymentMode: [], //付汇方式
  SalesTabIndex: 0, //销售相关选项卡当前选中的下标

  /* 单据类型 */
  billTemplateData: [], //所有单据类型(模板)
  saleBillType: [], //销售单据类型
  purchaseBillType: [], //采购单单据类型
  productBillType: [], //生产单据类型
  depotBillType: [], //库存单据类型
  costBillType: [], //资金单据类型

  /* 节点相关 */
  NodeClassfiy: [], //节点分类数据
  NodeClassfiyData: '1', //当前节点分类Id
  ControlCommitNode: false, //控制是否提交节点界面数据(新增节点时用)

  /* 流程相关 */
  WorkFlowSetup: [], //流程数据

  //公用表格排序下标
  SortIndex: "",

  //数据字典写定死数据
  logisticsDate: [ //物流日期
    {
      id: 0,
      name: '1-5天',
      is_enable: 1,
    },
    {
      id: 1,
      name: '6-10天',
      is_enable: 1,
    },
    {
      id: 2,
      name: '10天以上',
      is_enable: 1,
    }
  ],
  invoiceTypeData: [ //发票类型数据
    {
      id: 2,
      name: '增值税票',
      is_enable: 1,
    },
    {
      id: 0,
      name: '收据',
      is_enable: 1,
    },
    {
      id: 1,
      name: '普通发票',
      is_enable: 1,
    },
    // {
    //   id:3,
    //   name:'外贸PI',
    // },
  ],
  invoicePropertyData: [ //发票属性数据
    {
      id: 0,
      name: '开出',
      is_enable: 1,
    },
    {
      id: 1,
      name: '开进',
      is_enable: 1,
    },
  ],
  accountCheckType: [ //对账类型数据
    // {
    //   id: 0,
    //   name: '订单对账',
    //   is_enable:1,
    // },
    {
      id: 1,
      name: '出库对账',
      is_enable: 1,
      classify: 5,
    },
    {
      id: 2,
      name: '入库对账',
      is_enable: 1,
      classify: 6,
    },
    {
      id: 3,
      name: '交易对账',
      is_enable: 1,
      classify: 7,
    },
  ],
  originBillType: [ //源单类型
    {
      id: 0,
      name: '生产计划单',
      is_enable: 1,
    },
    {
      id: 1,
      name: '生产执行单',
      is_enable: 1,
    },
  ],
  offerWayData: [ //报价方式
    {
      id: 0,
      name: '成品报价',
      is_enable: 1,
    },
    {
      id: 1,
      name: '生产工艺报价',
      is_enable: 1,
    },
  ],
  isRate: [ //是否含税
    {
      id: -1,
      name: '请选择',
      is_enable: 1,
    },
    {
      id: 0,
      name: '否',
      is_enable: 1,
    },
    {
      id: 1,
      name: '是',
      is_enable: 1,
    },
  ],
  orderSourse: [ //订单来源
    {
      id: 0,
      name: '手工录入',
      is_enable: 1,
    },
    {
      id: 1,
      name: '官网下单',
      is_enable: 1,
    },
    {
      id: 2,
      name: '1688',
      is_enable: 1,
    },
    {
      id: 3,
      name: 'Alibaba',
      is_enable: 1,
    },
    {
      id: 4,
      name: '亚马逊',
      is_enable: 1,
    },
  ],
  bomPropertyData: [ //bom属性数据
    {
      id: 0,
      name: '生产制造用',
      is_enable: 1,
    },
    {
      id: 1,
      name: '销售出货用',
      is_enable: 1,
    },
  ],
  periodTypeData: [ //周期类型
    {
      id: -1,
      name: '当天',
      is_enable: 1,
    },
    {
      id: 4,
      name: '上月',
      is_enable: 1,
    },
    {
      id: 0,
      name: '本周',
      is_enable: 1,
    },
    {
      id: 1,
      name: '上周',
      is_enable: 1,
    },
    {
      id: 2,
      name: '两周内',
      is_enable: 1,
    },
    {
      id: 3,
      name: '本月',
      is_enable: 1,
    },
    {
      id: 5,
      name: '最近三个月',
      is_enable: 1,
    },
    {
      id: 6,
      name: '半年',
      is_enable: 1,
    },
    {
      id: 7,
      name: '本年',
      is_enable: 1,
    },
    {
      id: 8,
      name: '上年',
      is_enable: 1,
    },
    {
      id: 9,
      name: '自定义',
      is_enable: 1,
    },
  ],
  costSurplusData: [ //收付款结余类型
    {
      id: 1,
      name: '自动结余周期内订单关联收付款',
      is_enable: 1,
    },
    {
      id: 0,
      name: '自动结余周期内所有收付款',
      is_enable: 1,
    },
  ],
  returnSurplusData: [ //退货结余类型
    {
      id: 1,
      name: '自动结余周期内订单关联退货单',
      is_enable: 1,
    },
    {
      id: 0,
      name: '自动结余周期内所有退货单',
      is_enable: 1,
    },
  ],

  //单据模块单据分类数据(写死数据)
  billingClassifyData: [
    //销售
    {
      name: '销售',
      code: 'SYS-NODE-TYPE-001',
      data: [{
          id: 0,
          name: '报价',
          value: 0,
        },
        {
          id: 1,
          name: '订单',
          value: 1,
        },
      ]
    },
    //采购
    {
      name: '采购',
      code: 'SYS-NODE-TYPE-002',
      data: [{
          id: 0,
          name: '采购申请',
          value: 0,
        },
        {
          id: 1,
          name: '采购订单',
          value: 1,
        },
        {
          id: 2,
          name: '采购询价',
          value: 2,
        },
      ]
    },
    //生产
    {
      name: '生产',
      code: 'SYS-NODE-TYPE-003',
      data: [{
        id: 0,
        name: '生产计划',
        value: 0,
      }]
    },
    //库存
    {
      name: '库存',
      code: 'SYS-NODE-TYPE-004',
      data: [{
          /* 仓库分类设置 */
          id: 0,
          name: '入库',
          value: 0,
        },
        {
          id: 1,
          name: '出库',
          value: 1,
        },
        {
          id: 2,
          name: '报损',
          value: 2,
        },
        {
          id: 3,
          name: '组装',
          value: 3,
        },
        {
          id: 4,
          name: '拆卸',
          value: 4,
        },
        {
          id: 5,
          name: '调拨',
          value: 5,
        },
        {
          id: 6,
          name: '调价',
          value: 6,
        },
        {
          id: 7,
          name: '领料',
          value: 7,
        },
        {
          id: 8,
          name: '退料',
          value: 8,
        },
        {
          id: 9,
          name: '退货',
          value: 9,
        },
        {
          id: 10,
          name: '质检',
          value: 10,
        },
        {
          id: 11,
          name: '借用',
          value: 11,
        },
        {
          id: 12,
          name: '补货',
          value: 12,
        },
      ]
    },
    //资金
    {
      name: '资金',
      code: 'SYS-NODE-TYPE-005',
      data: [{
          id: 0,
          name: '收款',
          value: 0,
        },
        {
          id: 1,
          name: '付款',
          value: 1,
        },
        {
          id: 2,
          name: '报销',
          value: 2,
        },
        {
          id: 3,
          name: '销售发票',
          value: 3,
        },
        {
          id: 4,
          name: '采购发票',
          value: 4,
        },
        {
          id: 5,
          name: '客户对账单',
          value: 5,
        },
        {
          id: 6,
          name: '供应商对账单',
          value: 6,
        },
        {
          id: 7,
          name: '生产对账单',
          value: 7,
        },
      ]
    },
  ],

  //系统使用的分隔符
  separatorData: [{
      name: '无需',
      value: ''
    },
    {
      name: '空格',
      value: ' '
    },
    {
      name: '.',
      value: '.'
    },
    {
      name: '-',
      value: '-'
    },
    {
      name: '|',
      value: '|'
    },
    {
      name: '&',
      value: '&'
    },
    {
      name: '@',
      value: '@'
    },
    {
      name: '*',
      value: '*'
    },
    {
      name: '+',
      value: '+'
    },
  ],
  //顺序号规则
  orderRule: [{
      id: 1,
      name: '日'
    },
    // {
    //   id:2,
    //   name:'周'
    // },
    {
      id: 3,
      name: '月'
    },
    {
      id: 4,
      name: '年'
    }
  ],
  //中段格式
  midpieceRule: [{
      id: 0,
      value: 'yyyyMMdd',
      label: 'yyyyMMdd'
    },
    {
      id: 1,
      value: 'yyMMdd',
      label: 'yyMMdd'
    },
    {
      id: 2,
      value: 'yyyy',
      label: 'yyyy'
    },
    {
      id: 3,
      value: 'yyyyMM',
      label: 'yyyyMM'
    },
  ],

  //各单据列表页导出字段
  sellBillsKeys: [ //销售
    'bills_no', //单号
    'bills_date', //日期
    'create_user_name', //制单人
    'billingTypeName', //单据类型
    'payName', //收款状态名称
    'payMoney', //收款金额
    'receiptName', //发票状态名称
    'depotName', //是否出库
    'depotNum', //出库数量
    'purchaseName', //采购状态名称
    'mesName', //生产状态名称
    'refundName', //退货状态名称
    'refundNum',//退货数量
    'money', //订单金额
    'discount', //成交金额
    'customer_name', //所属客户
    'give_date', //交货日期
    'check_user_name', //审核人
    'check_time', //审核日期
    'dept_name', //所属部门
    'business_name', //业务员
    'export_num', //导出次数
    'print_num', //打印次数
    'remark', //备注
  ],
  expenseBillsKeys: [ //报销
    'bills_no', //单号
    'bills_date', //日期
    'create_user_name', //制单人
    'billingTypeName', //单据类型
    'total_amount', //单据金额
    'disposeName', //结算状态
    'disposeMoney', //结算金额
    'reason', //费用原因
    'check_user_name', //审核人
    'check_time', //审核日期
    'dept_name', //所属部门
    'export_num', //导出次数
    'remark', //备注说明
  ],
  invoiceBillsKeys: [ //发票
    'billsNo', //单号
    'billsDate', //日期
    'billingTypeId', //单据类型
    // 'isSubmit', //单据状态(不使用)
    'customerId', //客户名称
    'totalAmount', //单据金额
    'createUserId', //制单人
    'chargeUserName', //审核人
    'checkTime', //审核时间
    'printNum', //打印
    'exportNum', //导出次数
    'deptId', //所属部门
    'remark', //备注
  ],
  costCheckKeys: [ //对账
    'billsNo', //单号
    'billsDate', //日期
    'billingTypeId', //单据类型
    // 'isSubmit', //单据状态(不使用)
    'customerId', //客户名称
    'totalAmount', //单据金额
    'createUserId', //制单人
    'chargeUserName', //审核人
    'checkTime', //审核时间
    'printNum', //打印
    'exportNum', //导出次数
    'deptId', //所属部门
    'remark', //备注
  ],
  costSaleCheckKeys: [ //销售对账
    'bills_no', //单号
    'bills_date', //日期
    'create_user_name', //制单人
    'billingTypeName', //单据类型
    'isName', //对帐状态
    'receiptName', //发票状态
    'total_amount', //实收金额
    'total_tax_amount', //含税金额
    'customer_name', //客户名称
    'period_start_time', //周期开始时间
    'period_end_time', //周期结束时间
    'check_user_name', //审核人
    'check_time', //审核日期
    'dept_name', //所属部门
    'business_name', //业务员
    'export_num', //导出次数
    'print_num', //打印次数
  ],
  costPurchaseCheckKeys: [ //采购对账
    'bills_no', //单号
    'bills_date', //日期
    'create_user_name', //制单人
    'billingTypeName', //单据类型
    'isName', //对帐状态
    'receiptName', //发票状态
    'total_amount', //实收金额
    'total_tax_amount', //含税金额
    'supplier_name', //客户名称
    'period_start_time', //周期开始时间
    'period_end_time', //周期结束时间
    'check_user_name', //审核人
    'check_time', //审核日期
    'dept_name', //所属部门
    'business_name', //业务员
    'export_num', //导出次数
    'print_num', //打印次数
  ],
  putpayBillsKeys: [ //收付款
    'billsNo', //单号
    'billsDate', //日期
    'billingTypeId', //单据类型
    // 'isSubmit', //单据状态(不使用)
    'customerId', //客户名称
    'supplierId', //供应商
    'realityAmount', //单据金额
    'createUserId', //制单人
    'chargeUserName', //审核人
    'checkTime', //审核时间
    'exportNum', //导出次数
    'printNum', //打印
    'deptId', //所属部门
    'remark', //备注
  ],
  putpayInBillsKeys: [ //收款单
    'bills_no', //单号
    'bills_date', //日期
    'create_user_name', //制单人
    // 'isSubmit', //单据状态(不使用)
    'billingTypeName', //单据类型
    'customerName', //供应商名称
    'reality_amount', //付款金额
    'orderBankName', //结算帐号
    'pay_date', //付款日期
    'costTypeName', //费用分类
    'check_user_name', //审核人
    'check_time', //审核日期
    'dept_name', //所属部门
    'sale_user_name', //业务员
    'export_num', //导出次数
    'print_num', //打印次数
    'remark', //备注
  ],
  putpayOutBillsKeys: [ //付款单
    'bills_no', //单号
    'bills_date', //日期
    'create_user_name', //制单人
    // 'isSubmit', //单据状态(不使用)
    'billingTypeName', //单据类型
    'supplierName', //供应商名称
    'reality_amount', //付款金额
    'orderBankName', //结算帐号
    'pay_date', //付款日期
    'costTypeName', //费用分类
    'check_user_name', //审核人
    'check_time', //审核日期
    'dept_name', //所属部门
    'sale_user_name', //业务员
    'export_num', //导出次数
    'print_num', //打印次数
    'remark', //备注
  ],
  purchaseBillsKeys: [ //采购
    'bills_no', //单号
    'bills_date', //日期
    'create_user_name', //制单人
    'billingTypeName', //单据类型
    'payName', //收款状态名称
    'payMoney', //付款金额
    'receiptName', //发票状态名称
    'depotName', //是否入库
    'depotNum', //入库数量
    'refundName', //退货状态名称
    'refundNum', //退货数量
    'money', //订单金额
    'practical_amount', //成交金额
    'supplier_name', //供应商名称
    'delivery_date', //交货日期
    'check_user_name', //审核人
    'check_time', //审核日期
    'dept_name', //所属部门
    'business_name', //业务员
    'export_num', //导出次数
    'print_num', //打印次数
    'remark', //备注说明
  ],
  warehoseBillsKeys: [ //出入库
    'billsNo', //单号
    'billsDate', //日期
    'billingTypeId', //单据类型
    // 'isSubmit', //单据状态(不使用)
    // 'qualityState', //质检状态(不使用)
    'houseId', //仓库
    'relevanceBills', //源单单号
    'totalMoney', //单据金额
    'supplierId', //供应商
    'createUserId', //制单人
    'chargeUserName', //审核人
    'checkTime', //审核时间
    'logisticsWayId', //运输方式
    'logisticsCompanyId', //物流公司
    'logisticsAmount', //物流费用
    'deptId', //所属部门
    'exportNum', //导出
    'printNum', //打印
    'remark', //备注
  ],
  depotOutBillsKeys: [ //出库单导出列表
    'bills_no', //出库单号
    'bills_date', //日期
    'create_user_name', //制单人
    'billingTypeName', //单据类型
    'customerName', //客户名称
    'delivery_date', //出货日期
    'houseName', //出库仓库
    'logisticsName', //物流公司
    'logistics_amount', //物料费用
    'relevance_bills_no', //源单据号
    'check_user_name', //审核人
    'check_time', //审核日期
    'dept_name', //所属部门
    'business_name', //业务员
    'export_num', //导出
    'print_num', //打印
    'remark', //备注
  ],
  depotInBillsKeys: [ //入库单导出列表
    'bills_no', //入库单号
    'bills_date', //日期
    'create_user_name', //制单人
    'billingTypeName', //单据类型
    'supplierName', //供应商名称
    'delivery_date', //入库日期
    'houseName', //入库仓库
    'deliverName', //送货方式
    'deliver_no', //送货单号
    'relevance_bills_no', //源单据号
    'check_user_name', //审核人
    'check_time', //审核日期
    'dept_name', //所属部门
    'business_name', //业务员
    'export_num', //导出
    'print_num', //打印
    'remark', //备注
  ],
  productBillsKeys: [ //生产计划
    'bills_no', //单号
    'bills_date', //单据日期
    'create_user_name', //制单人
    'product_code', //货品编号
    'specificationsName', //名称与规格 名称(规格)
    'materialName', //是否领料
    'purchaseName', //是否采购
    'depotName', //是否入库
    'customer_name', //归属客户
    'start_date', //开工日期
    'complete_data_plan', //计划完工日期
    'complete_date', //实际完工日期
    'plan_num', //计划数量
    'manufacture_num', //生产数量
    'check_user_name', //审核人
  ],
  productProcessKeys: [ //生产执行
    'billsNo', //单号
    'billsDate', //单据日期
    'name', //工序名称
    'totalLabourCost', //总工价
    'userName', //负责人
    'deptName', //负责部门
    'workNum', //派工数量
    'qualifiedNum', //合格数量
    'residueNum', //剩余数量
    'scrapNum', //报废数量
    'totalTime', //合计用时
    'costTypeName', //计费方式
    'manufactureTypeName', //生产类型
  ],
  productExportProcessKeys: [ //货品导出
    'product_code', //货品编号
    'name', //货品名称
    'specifications', //货品规格
    'pro_property_name', //货品属性
    'pro_source_name', //货品来源
    'brand_idName', //货品品牌
    'stockNum', //库存数
    'purchaseUnit', //计量单位
    'purchasePrice', //成本单价
    'salePrice', //销售单价
    'order_limit_num', //起订量
    'weight', //单个重量
    'offer_num', //报价数
    'order_num', //订单数
    'outsourcing_num', //外购数
    'in_production_num', //生产中数量
    'produced_num', //已生产数量
    'supplier_name', //供应商名称
    'downName', //下架状态
    'create_user_name', //创建人
    'create_time', //创建时间
    'check_user_name', //审核人
    'check_time', //审核时间
    'desciption', //简介备注
    'product_old_code', //原货品编码
  ],

  bomExportKeys: [ //BOM导出相关
    'name',//货品名称
    'specifications',//产品规格
    'product_code',//货品编号
    'version',//当前货品bom版本号
    'bom_date',//当前货品bom版本日期
    'total_cost_price',//总成本
    'total_material_cost',//总材料费用
    'total_gross_cost',//毛生产费用
    'level_alone',//独有层级-本BOM实设层级
    'level_owner',//归属层级-本BOM关联货品的BOM层级
    'assign_next_version_name',//半成品与编码(即指定下级货品名称编号)
    'bom_process_name',//bom生产工序名称,多个以英文逗号隔开
    'product_info_code',//物料编码
    'product_info_name',//物料名称
    'bomSpecifications',//物料规格型号
    'calculate_name',//计量单位名称
    'need_num',//所需数量
    'consume_rate',//报损率
    'position_num',//位置号(当前bom所在货品的位置)
    'price',//单价(成本价)
    'totalPrice',//成本费用
    'machine_price',//加工单价
    'process_unit_name',//加工单位
    'valuation_num',//计价数
    'totalMachinePrice',//加工费用
    'remark',//物料备注
    'create_time',//创建日期
    'bom_create_user_name',//创建人
    'update_time',//最后修改时间
    'bom_audit_user_name',//审核人
    'bom_audit_time',//审核时间

  ],
  bomProcessExportKeys: [ //BOM工序导出相关
    'name',//货品名称
    'specifications',//产品规格
    'product_code',//货品编号
    'version',//当前货品bom版本号
    'bom_date',//当前货品bom版本日期
    'total_cost_price',//总成本
    'total_material_cost',//总材料费用
    'total_gross_cost',//毛生产费用
    'level_alone',//独有层级-本BOM实设层级
    'level_owner',//归属层级-本BOM关联货品的BOM层级
    'assign_next_version_name',//半成品与编码(即指定下级货品名称编号)
    'processName',//工序名称
    'totalCost',//工序总成本
    'totalMaterialCost',//工序总物料费用
    'totalProcessingCost',//工序物料加工费用
    'total_labour_cost',//工序毛生产费用
    'dept_name',//负责部门
    'create_time',//创建日期
    'bom_create_user_name',//创建人
    'update_time',//最后修改时间
    'bom_audit_user_name',//审核人
    'bom_audit_time',//审核时间

  ],
}
export default {
  state,
  mutations,
  actions
}
