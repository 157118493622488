// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：用户相关请求接口
// 开始时间：2021-03-13
// 开发人员：刘巍骏
// 最后修改：2021-03-13
// 备注说明：如需修改请联系开发人员

import {request} from "./request.js";


export default{
  // 用户相关信息
  user:{
    getUserInfo(){//获取当前登录用户信息
      return request({
        method:'POST',
        url:'/company/user/getUserInfo'
      })
    },
    refreshToken(){//刷新token
      return request({
        method:'POST',
        url:'/company/user/refreshToken'
      })
    },
    findUserById(data){//根据id获取用户信息
      return request({
        method:'POST',
        url:'user/findUserById',
        data
      })
    }
  },
}
