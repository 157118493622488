<template>
  <div class="mainNavAll scollStyle" id="mainNavAll">
    <!-- 网页logo -->
    <div class="mainNavLogo" @click="toHome">
      <div class="mainNavshrink">
        <img src="../../assets/img/es.png">
      </div>
      <div class="mainNavunfold" v-show="showNavbar_left">
        <span>
          易外销<i>.</i>云
        </span>
      </div>
    </div>
    <!-- 导航栏内容 -->
    <ul class="mainNavCont scollStyle">
      <li v-for="(elem,index) in menu_config" class="mainNavItem" :class="{checked:SelNavIndex==index}"
        @click="getSelectNav(index,0)" @mouseleave="showClassfiIndex=-1" @mouseenter="getSelectNav(index,1)"
        v-show="isShowMenu(elem)">
        <!-- 收缩时显示 -->
        <div class="NavShrinkIcon" v-show="!showNavbar_left">
          <p class="icon"><i class="iconfont icon-gerenpeizhi"></i></p>
          <p class="text">{{elem.shortName}}</p>
        </div>
        <!-- 展开时显示 -->
        <div class="NavUnfoldIcon" v-show="showNavbar_left">
          <div class="NavTitle">{{elem.name}}</div>
          <p class="icon"><i class="iconfont icon-gerenpeizhi"></i></p>
        </div>

        <!-- 导航分类框 -->
        <div class="mainClassfiBox" v-show="index==showClassfiIndex" @mouseenter="showClassfiIndex=SelNavIndex"
          @mouseleave="showClassfiIndex=-1">
          <!-- 单个分类分组 -->
          <div class="classfiItem" v-for="elem_classfi in elem.child" v-if="elem_classfi.isEnable==1">
            <!-- 分组标题 -->
            <div class="classfiTitle">{{elem_classfi.name}}</div>
            <!-- 分组内容 -->
            <ul class="classfiCont searchClassfiy">
              <li :class="{disabled:!elem_classfii.url}" v-for="(elem_classfii) in elem_classfi.child"
                v-if="elem_classfii.isEnable==1" @click="toRouteInterface(elem_classfii,elem_classfi,elem)">
                {{elem_classfii.name}}
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  // 导入jquery
  import $ from 'jquery';
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  export default {
    data() {
      return {
        // 数据选中
        SelNavIndex: 0, //导航栏选中下标
        showClassfiIndex: -1, //显示分类导航栏下标

        //loading框
        loading: '', //loading框内容
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        mainNavData: state => state.mainNavbar.mainNavData, //左侧导航名数据
        showNavbar_left: state => state.mainNavbar.showNavbar_left, //是否显示展开左侧导航栏
        menu_config: state => state.mainNavbar.menu_config, //菜单配置数据
        userparamlist: state => state.userparamlist,
      }),
    },
    created() {},
    mounted() {
      //初始化界面所需数据
      this.initVuexData();
    },
    watch: {
      // 监听路由发生变化
      '$route': 'initVuexData',
    },
    methods: {
      ...mapMutations([
        'CURMBS_NAME', //头部导航面包屑名称
      ]),
      ...mapMutations({
        getUserParamList: 'getUserParamList',
      }),
      ...mapActions([
        'findAllMenuConfig', //请求菜单配置数据
      ]),

      /* 初始化菜单配置数据 */
      async initVuexData() {
        //获取用户权限
        if (this.userparamlist == "" || JSON.stringify(this.userparamlist) === '{}') { //用户权限
          await this.getUserParamList();
        }

        //获取数据
        await this.findAllMenuConfig();

        let href = window.location.href.split("#")[1]
        // let ywxCrumbsName = sessionStorage.getItem("ywxCrumbsName");
        let ywxCrumbsName;
        if ((window.location.href) != baseWebUrl + "/#/") {
          let url = href.split("?")[0];
          let paramVal;
          if (href.indexOf("?") != -1) {
            paramVal = ((href.split("?")[1]).replace("&", "@@@").replace("=", ":")).replace("=", ":");
          } else {
            paramVal = null;
          }

          for (let i = 0; i < this.menu_config.length; i++) {
            for (let x = 0; x < this.menu_config[i].child.length; x++) {
              if (this.menu_config[i].child[x].child != null) {
                for (let a = 0; a < this.menu_config[i].child[x].child.length; a++) {
                  if (paramVal != null) {
                    if (this.menu_config[i].child[x].child[a].url == url && this.menu_config[i].child[x].child[a]
                      .paramVal == paramVal) {
                      ywxCrumbsName = this.menu_config[i].name + "," + this.menu_config[i].child[x].name + "," + this
                        .menu_config[i].child[x].child[a].name;
                    }
                  } else {
                    if (this.menu_config[i].child[x].child[a].url == url) {
                      ywxCrumbsName = this.menu_config[i].name + "," + this.menu_config[i].child[x].name + "," + this
                        .menu_config[i].child[x].child[a].name;
                    }
                  }
                }
              }
            }
          }
        } else {
          ywxCrumbsName = "首页";
        }
        if (!!ywxCrumbsName) {
          this.CURMBS_NAME(ywxCrumbsName.split(","));
        }
      },

      /* 菜单权限 */
      isShowMenu(elem) {
        // return true;//开启后菜单权限将不在生效
        //是否显示菜单
        let isShow = false;
        let menusName = []; //菜单名称数组
        let menus = [{ //菜单权限数组
          isShow: this.userparamlist.param287, //是否显示(0:不显示 1:显示)
          name: '销售管理', //名称
        }, {
          isShow: this.userparamlist.param288, //是否显示(0:不显示 1:显示)
          name: '库存采购', //名称
        }, {
          isShow: this.userparamlist.param289, //是否显示(0:不显示 1:显示)
          name: '生产管理', //名称
        }, {
          isShow: this.userparamlist.param290, //是否显示(0:不显示 1:显示)
          name: '资金管理', //名称
        }, {
          isShow: this.userparamlist.param291, //是否显示(0:不显示 1:显示)
          name: '人力资源', //名称
        }, {
          isShow: this.userparamlist.param292, //是否显示(0:不显示 1:显示)
          name: '财务管理', //名称
        }, {
          isShow: this.userparamlist.param293, //是否显示(0:不显示 1:显示)
          name: '报表统计', //名称
        }, {
          isShow: this.userparamlist.param294, //是否显示(0:不显示 1:显示)
          name: '基础资料', //名称
        }, {
          isShow: this.userparamlist.param295, //是否显示(0:不显示 1:显示)
          name: '系统配置', //名称
        }]
        menus.forEach(item => {
          if (item.name == elem.name) { //通过菜单全称匹配
            if (item.isShow == '1' && elem.isEnable == 1) { //拥有权限并启用时
              isShow = true //显示
            } else { //不拥有权限或不启用时
              isShow = false //不显示
            }
          }

          //获取菜单名称数组
          menusName.push(item.name);
        })

        //不在权限控制范围内的菜单
        if (menusName.indexOf(elem.name) == -1) {
          isShow = elem.isEnable == 1 ? true : false;
        }
        return isShow;
      },

      // 返回首页
      toHome() {
        //判断当前登录用户权限
        if(this.UserInfo.user_role != 1){//非系统管理员(跳转到新手界面)
          this.$router.push({
            path: '/guideindex',
          })
        }else{//系统管理元(跳转到首页)
          this.$router.push({
            path: '/'
          })
        }
      },

      /* 控制开关 */
      // 控制收缩导航栏
      isNavCollapse() {
        let navBox = document.getElementById('mainNavAll')
        // 设置延迟获取,不然会异步
        let $this = this;
        setTimeout(function() {
          $this.getMainNavClassfi($this.SelNavIndex);
        }, 10)
      },

      // 获取点击导航栏下标 index:下标 type:(0:点击事件,1:鼠标移入事件)
      getSelectNav(index, type) {
        this.SelNavIndex = index;
        if (type == 0) {
          this.showClassfiIndex = -1;
          if (index == 0) {
            // this.$router.push({path:'/'})
          }
        } else {
          this.showClassfiIndex = index;
        }
        // console.log(this.showClassfiIndex);
        //动态设置分类框显示位置
        this.getMainNavClassfi(index);
      },

      /* 进入路由界面 (data:三级 secondData:二级 firstData:一级)*/
      toRouteInterface(data, secondData, firstData) {
        let crumbsName = [firstData.name, secondData.name, data.name]
        //判断功能是否开放(根据路由是否有参数判断)
        if (data.url) { //没有设置路径时面包屑不发生改变
          this.CURMBS_NAME(crumbsName);
          //将面包屑存入sessionStorage
          // sessionStorage.setItem('ywxCrumbsName', crumbsName);
        } else {
          this.$message({
            type: 'warning',
            message: '该功能暂未开放',
            duration: this.elDuration
          })
          return
        }
        //判断是否携带参数
        let queryArr = [] //定义路由传递的参数数组
        // 判断链接类型(内部链接还是第三方链接)
        if (data.urlType == 0) { //内部链接
          // 判断是否携带参数
          if (data.paramVal) { //有参数
            data.paramVal.split("@@@").forEach(item => { //将取得的路由传递的参数分割
              let key = item.split(":")[0] //获取参数名
              let val = item.split(":")[1] //获取参数值
              queryArr.push({
                [key]: val //传入数组中
              })
            })
            //处理数组,将一个数组中的多个对象合并成一个
            let query = queryArr.reduce(function(result, current) {
              return Object.assign(result, current);
            }, {})
            //判断界面打开方式
            if (data.openType == 0 || data.openType == null) { //当前页打开
              this.$router.push({
                path: data.url,
                query
              })
            } else if (data.openType == 1) { //新标签页打开
              const {
                href
              } = this.$router.resolve({
                path: data.url,
                query
              });
              window.open(href, "_blank");
            }
          } else { //无参数
            if (data.openType == 0 || data.openType == null) { //当前页打开
              this.$router.push({
                path: data.url
              })
            } else if (data.openType == 1) { //新标签页打开
              const {
                href
              } = this.$router.resolve({
                path: data.url
              });
              window.open(href, "_blank");
            }
          }
        } else if (data.urlType == 1) { //第三方链接
          // 判断当前界面打开还是新界面打开
          if (data.openType == 0) { //当前界面
            window.location.href = data.url
          } else if (data.openType == 1) { //新标签页
            window.open(data.url, "_blank")
          }
        }
        this.showClassfiIndex = -1;
      },

      /* 导航分类 */
      // 分类浮动位置
      getMainNavClassfi(index) {
        var mainNavWidth = $('.mainNavAll').width(); //导航栏宽度(伸缩或展开)
        var mainItemHeight = 0;
        if(!!$(".mainNavItem")[index]){
          mainItemHeight = $(".mainNavItem")[index].offsetTop; //当前li离浏览器顶部的距离(未减滚动条高度);
        }
        var mainNavScollerTop = $(".mainNavCont").scrollTop(); //导航栏滚动条已滚动高度
        var itemTop = mainItemHeight - mainNavScollerTop; //当前li离浏览器顶部juli(减去滚动条高度)
        var windowHeight = $(window).height(); //浏览器高度
        var wiodowBottom = windowHeight - (mainItemHeight - mainNavScollerTop + 52); //离浏览器底部的距离
        var classfiHeight = $('.mainClassfiBox').height(); //导航分类高度
        // 距离顶部一半时向上定位
        if (itemTop >= (windowHeight / 2)) {
          $('.mainClassfiBox').css({
            left: mainNavWidth,
            bottom: wiodowBottom,
            top: 'auto'
          })
        } else {
          $('.mainClassfiBox').css({
            left: mainNavWidth,
            top: itemTop,
            bottom: 'auto'
          })
        }
      },
    }
  }
</script>

<style>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 100vh;
  }
</style>
<style lang="less">
  // 导航栏总框
  .mainNavAll {
    height: 100%;
    // overflow: auto;
    background: #4f5469;
    color: white;
    // transition: width 0.5s;
    cursor: pointer;

    // 网站logo
    .mainNavLogo {
      height: @navbarTop_height;
      display: flex;
      align-items: center;

      // 收缩
      .mainNavshrink {
        width: 60px;
        text-align: center;

        // border: 1px solid black;
        img {
          width: 60%;
          height: 50%;
          display: inline-block;
          // border: 1px solid black;
        }
      }

      // 展开
      .mainNavunfold {
        width: 100px;
      }
    }

    // 内容
    .mainNavCont {
      // width: 20px;
      height: calc(100% - 56px);
      overflow-y: auto;
      direction: rtl;

      li {
        display: flex;
        height: 52px;

        // position: relative;
        // border: 1px solid black;
        // 收缩时显示图标
        .NavShrinkIcon {
          width: 60px;
          padding: 5px 0;

          // border: 1px solid black;
          p {
            text-align: center;
          }

          .text {
            padding: 3px 0;
            font-size: 12px;
          }
        }

        //展开时显示图标
        .NavUnfoldIcon {
          display: flex;

          p {
            width: 60px;
            line-height: 52px;
            text-align: center;
          }

          //展开时显示分类名称
          .NavTitle {
            width: 100px;
            line-height: 52px;
            text-align: left;
            font-size: 14px;
            // display: none;
            // border: 1px solid red;
          }
        }
      }

      li:hover {
        background: #0091ff;
      }

      // 选中的li
      li.checked {
        background: #0091ff;
      }
    }

    // 导航栏分类
    .mainClassfiBox {
      padding: 20px 15px;
      display: flex;
      position: absolute;
      background: white;
      z-index: 100;
      border-radius: 5px;
      box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.3);
      transform: rotateY(180deg);

      // border: 1px solid black;
      // 单个分类组
      .classfiItem {
        width: 180px;
        padding: 0 10px;
        transform: rotateY(180deg);

        // border: 1px solid black;
        //分组标题
        .classfiTitle {
          width: 100%;
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          text-align: center;
          margin: 10px 0;
          background: #eff6fe;
          color: #68a6f6;
        }

        // 分类组内容
        .classfiCont {
          width: 100%;
          // max-height: 400px;
          // overflow: auto;
          display: flex;
          flex-direction: column;
          // border: 1px solid black;

          li {
            justify-content: flex-end;
            height: 40px;
            line-height: 40px;
            font-size: 13px;
            color: #333333;
            padding-left: 10%;
          }

          li:hover {
            background: #eff6fe;
            color: #68a6f6;
          }

          //未开放的li
          .disabled {
            color: #b3b3b3;
          }
        }
      }
    }
  }
</style>
