// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：info@21gmail.com
// 单元名称：公共组件.用户相关信息
// 开始时间：2021-01-19
// 开发人员：刘巍骏
// 最后修改：2021-01-19
// 备注说明：如需修改请联系开发人员

import mutations from "./mutations.js"
import actions from "./actions.js"

const state={
  //系统相关
  loginType:-1,//系统登录方式(0:用户登录  1:客户登录  2:供应商登录)

  // 用户相关
  UserInfo:"",//当前登录用户信息
  UserSocialInfo:"",//用户社交信息
  CompanyInfo: '', //当前登录用户企业信息
  userPowerInfo:'',//当前用户的操作权限信息

  //登录信息相关
  LoginCustomerId:-1,//登录系统的客户id
  LoginCustomerInfo:{},//登录系统的客户
  LoginSupplierId:-1,//登录系统的供应商id
  LoginSupplierInfo:{},//登录系统的供应商
}


export default {
  state,
  mutations,
  actions
}
