/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：导航·异步公共方法js
 * 开始时间：2021-01-25
 * 开发人员：刘巍骏
 * 最后修改：2021-01-25
 * 备注说明：如需修改请联系开发人员
 */
import api from '@/network/system/individuation.js'
export default {
  //查询所有菜单配置
  findAllMenuConfig({
    commit
  }) {
    return new Promise((resolve, reject) => {
      api.findAllMenuConfig().then(res => {
        if (res.code == 200) {
          commit("MENU_CONFIG", res.data);
        }
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  },
}
