// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：人力相关请求接口
// 开始时间：2021-03-13
// 开发人员：刘巍骏
// 最后修改：2021-03-13
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default {
  //部门相关
  getDepartmentTree() { //获取部门相关信息
    return request({
      method: 'POST',
      url: '/company/findAllCentreDepartmentTree'
    })
  },

  //员工相关
  getAllUserData() { //获取所有员工相关信息
    return request({
      method: 'POST',
      url: '/company/findAllUser'
    })
  },

  searchUserDataByClassfiyId(data) { //根据分类id查找员工
    return request({
      method: 'POST',
      url: '/company/user/findByCondition',
      data,
    })
  },

  findFirstLevelDeptUser(data) { //获取一级部门及其内部的所有员工
    return request({
      method: 'POST',
      url: '/company/findFirstLevelDeptUser',
			data
    })
  },
}
