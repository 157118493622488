// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：系统.数据字典相关请求接口
// 开始时间：2021-03-13
// 开发人员：刘巍骏
// 最后修改：2021-03-13
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default {
  //客户相关字典
  customerDict: {
    //客户分类
    getCustomerClassfiy() { //获取客户分类数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findCrmClassifyAll'
      })
    },
    addCustomerClassfiy(data) { //新增客户分类数据
      return request({
        method: 'POST',
        url: '/system/dictionary/addClassify',
        data: data,
      })
    },
    deleteCustomerClassfiy(data) { //删除客户分类数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCrmClassify',
        data: data,
      })
    },
    updateCustomerClassfiy(data) { //修改客户分类数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateCrmClassify',
        data: data,
      })
    },
    updateCrmClassifyCodeRule(data) { //编辑客户分类编码规则
      return request({
        method: 'POST',
        url: '/system/dictionary/updateCrmClassifyCodeRule',
        data: data,
      })
    },

    //客户状态
    getCustomerState() { //获取客户状态数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findCrmStatusAll'
      })
    },
    addCustomerState(data) { //新增客户状态数据
      return request({
        method: 'POST',
        url: '/system/dictionary/addCrmStatus',
        data: data,
      })
    },
    deleteCustomerState(data) { //删除客户状态数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCrmStatus',
        data: data,
      })
    },
    updateCustomerState(data) { //修改客户状态数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateCrmStatus',
        data: data,
      })
    },
    //客户来源
    getCustomerSource() { //获取客户来源数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findSourceFromAll',
      })
    },
    addCustomerSource(data) { //新增客户来源数据
      return request({
        method: 'POST',
        url: '/system/dictionary/addCrmSourceFrom',
        data: data,
      })
    },
    deleteCustomerSource(data) { //删除客户来源数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCrmSourceFrom',
        data: data,
      })
    },
    updateCustomerSource(data) { //修改客户来源数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSourceFrom',
        data: data,
      })
    },
    //客户关系
    getCustomerRelation() { //获取客户关系数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findRelationsAll'
      })
    },
    addCustomerRelation(data) { //新增客户关系数据
      return request({
        method: 'POST',
        url: '/system/dictionary/addCrmRelations',
        data: data,
      })
    },
    deleteCustomerRelation(data) { //删除客户关系数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCrmRelations',
        data: data,
      })
    },
    updateCustomerRelation(data) { //修改客户关系数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateRelations',
        data: data,
      })
    },
    // 销售进程
    getCustomerSell() { //获取销售进程数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllCrmProcess',
      })
    },
    addCustomerSell(data) { //新增销售进程数据
      return request({
        method: 'POST',
        url: '/system/dictionary/addCrmProcess',
        data: data,
      })
    },
    deleteCustomerSell(data) { //删除销售进程数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCrmProcess',
        data: data,
      })
    },
    updateCustomerSell(data) { //修改销售进程数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateCrmProcess',
        data: data,
      })
    },
    //跟进方式
    getCustomerFollowupWay() { //获取跟进方式数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findfollowupwayAll'
      })
    },
    addCustomerFollowupWay(data) { //新增跟进方式数据
      return request({
        method: 'POST',
        url: '/system/dictionary/addFollowupway',
        data: data
      })
    },
    deleteCustomerFollowupWay(data) { //删除跟进方式数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteFollowupway',
        data: data
      })
    },
    updateCustomerFollowupWay(data) { //修改跟进方式数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateFollowupway',
        data: data
      })
    },
    upadteFollowupwaySort(data) {
      return request({
        method: 'POST',
        url: '/system/dictionary/upadteFollowupwaySort',
        data: data
      })
    },
    //会员等级
    addCrmMember(data) { //增加会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/addCrmMember',
        data: data
      })
    },
    deleteCrmMember(data) { //删除会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCrmMember',
        data: data
      })
    },
    findAllCrmMember() { //查询会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllCrmMember',
      })
    },
    updateCrmMember(data) { //更新会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/updateCrmMember',
        data: data
      })
    },
    updateSysCrmMemberSort(data) { //排序会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysCrmMemberSort',
        data: data
      })
    },
  },

  // 仓储相关数据字典
  storageDict: {
    //物流公司
    findAllSysDepotLogisticCompany() { //获取物流公司的数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllSysDepotLogisticCompany'
      })
    },
    addSysDepotLogisticCompany(data) { //新增物流公司的数据
      return request({
        method: 'POST',
        url: '/system/dictionary/addSysDepotLogisticCompany',
        data,
      })
    },
    deleteSysDepotLogisticCompany(data) { //删除物流公司的数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteSysDepotLogisticCompany',
        data,
      })
    },
    updateSysDepotLogisticCompany(data) { //修改物流公司的数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotLogisticCompany',
        data,
      })
    },
    updateSysDepotLogisticCompanySort(data) { //排序物流公司
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotLogisticCompanySort',
        data,
      })
    },

    //物流方式
    findAllSysDepotLogisticWayService() { //获取物流方式数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllSysDepotLogisticWayService',
      })
    },
    addSysDepotLogisticWayService(data) { //新增物流方式数据
      return request({
        method: 'POST',
        url: '/system/dictionary/addSysDepotLogisticWayService',
        data,
      })
    },
    deleteSysDepotLogisticWayService(data) { //删除物流方式数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteSysDepotLogisticWayService',
        data,
      })
    },
    updateSysDepotLogisticWayService(data) { //修改物流方式数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotLogisticWayService',
        data,
      })
    },
    updateSysDepotLogisticWaySort(data) { //物流方式排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotLogisticWaySort',
        data,
      })
    },

    //仓库配置
    findAllWarehouse() { //获取仓库配置数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllWarehouse',
      })
    },
    addWarehouse(data) { //新增仓库配置
      return request({
        method: 'POST',
        url: '/system/dictionary/addWarehouse',
        data,
      })
    },
    deleteWarehouse(data) { //删除仓库配置
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteWarehouse',
        data,
      })
    },
    updateWarehouse(data) { //修改仓库配置
      return request({
        method: 'POST',
        url: '/system/dictionary/updateWarehouse',
        data,
      })
    },
    updateSysDepotFormSort(data) { //仓库配置排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotFormSort',
        data,
      })
    },
    addDepotFormPlace(data) { //新增仓库位置号
      return request({
        method: 'POST',
        url: '/system/dictionary/addDepotFormPlace',
        data,
      })
    },
    updateDepotFormPlace(data) { //修改仓库位置号信息
      return request({
        method: 'POST',
        url: '/system/dictionary/updateDepotFormPlace',
        data,
      })
    },
    findDepotFormPlaceByFormId(data) { //根据仓库id查询位置号信息
      return request({
        method: 'POST',
        url: '/system/dictionary/findDepotFormPlaceByFormId',
        data,
      })
    },
    deleteDepotFormPlace(data) { //删除仓位号信息
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteDepotFormPlace',
        data,
      })
    },

    // 送货方式
    findAllDeliveryType() { //查询所有送货方式
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllDeliveryType',
      })
    },
    addDeliveryType(data) { //添加送货方式
      return request({
        method: 'POST',
        url: '/system/dictionary/addDeliveryType',
        data,
      })
    },
    deleteDeliveryType(data) { //删除送货方式
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteDeliveryType',
        data,
      })
    },
    updateDeliveryType(data) { //更新送货方式
      return request({
        method: 'POST',
        url: '/system/dictionary/updateDeliveryType',
        data,
      })
    },
    updateSysDepotDeliveryTypeSort(data) { //排序送货方式
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotDeliveryTypeSort',
        data,
      })
    },

    findAllTestWay() { //查询所有检验方式
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllDepotVerifyWay',
      })
    },
    addTestWay(data) { //添加检验方式
      return request({
        method: 'POST',
        url: '/system/dictionary/addSysDepotVerifyWay',
        data,
      })
    },
    deleteTestWay(data) { //删除检验方式
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteSysDepotVerifyWay',
        data,
      })
    },
    updateTestWay(data) { //更新检验方式
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotVerifyWay',
        data,
      })
    },
    updateSysDepotTestWaySort(data) { //排序检验方式
      return request({
        method: 'POST',
        url: '/system/dictionary/updatesysDepotVerifyWaySort',
        data,
      })
    },


    findAllQualityType() { //查询所有质检类型
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllysDepotQualityType',
      })
    },
    addQualityType(data) { //添加质检类型
      return request({
        method: 'POST',
        url: '/system/dictionary/addSysDepotQualityType',
        data,
      })
    },
    deleteQualityType(data) { //删除质检类型
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteSysDepotQualityType',
        data,
      })
    },
    updateQualityType(data) { //更新质检类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotQualityType',
        data,
      })
    },
    updateSysDepotQualityTypeSort(data) { //排序质检类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotQualityTypeSort',
        data,
      })
    },


    //单据类型
    addDocumentType(data) { //添加单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/addDocumentType',
        data,
      })
    },
    deleteDocumentType(data) { //删除单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteDocumentType',
        data,
      })
    },
    findAllDocumentType() { //查询单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllDocumentType',
      })
    },
    updateDocumentType(data) { //更新单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateDocumentType',
        data,
      })
    },
    updateSysDepotDocumentTypeSort(data) { //排序单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysDepotDocumentTypeSort',
        data
      })

    },

  },
  // 财务相关数据字典
  financeDict: {
    //收款账号
    findAllSysCostBankInfo() { //查询收款账号
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllSysCostBankInfo',
      })
    },
    findAllBankLog(data) { //查询日志
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllBankLog',
        data
      })
    },
    updateSysCostBankInfo(data) { //修改收款账号
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysCostBankInfo',
        data
      })
    },
    addSysCostBankInfo(data) { //增加收款账号
      return request({
        method: 'POST',
        url: '/system/dictionary/addSysCostBankInfo',
        data
      })
    },
    deleteSysCostBankInfo(data) { //删除收款账号
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteSysCostBankInfo',
        data
      })
    },
    //交易币种
    getFinanceCurrency() { //获取交易币种的数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllCostCurrency',
      })
    },
    addCurrency(data) { //增加交易币种
      return request({
        method: 'POST',
        url: '/system/dictionary/addCostCurrency',
        data
      })
    },
    deleteCrmCurrency(data) { //删除交易币种
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCostCurrency',
        data
      })
    },
    updateCrmCurrency(data) { //修改交易币种
      return request({
        method: 'POST',
        url: '/system/dictionary/updateCostCurrency',
        data
      })
    },
    updateSysCostCurrencySort(data) { //排序交易币种
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysCostCurrencySort',
        data
      })
    },
    findAllPaymentType(data) { //查询收款类型
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllCostDocumentType',
        data
      })
    },
    deletePaymentType(data) { //删除收款类型
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCostDocumentType',
        data
      })
    },
    addPaymentType(data) { //增加收款类型
      return request({
        method: 'POST',
        url: '/system/dictionary/addCostDocumentType',
        data
      })
    },
    updatePaymentType(data) { //修改收款类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateCostDocumentType',
        data
      })
    },
    updateSysCostDocumentTypeSort(data) { //排序收款类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysCostDocumentTypeSort',
        data
      })
    },
    findAllCostType() { //查询所有费用分类
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllCostType',
      })
    },
    findCostTypeByCondition(data) { //条件查询所有费用分类
      return request({
        method: 'POST',
        url: '/system/dictionary/findCostTypeByCondition',
        data
      })
    },
    addCostType(data) { //增加费用分类
      return request({
        method: 'POST',
        url: '/system/dictionary/addCostType',
        data
      })
    },
    updateCostType(data) { //更新费用分类
      return request({
        method: 'POST',
        url: '/system/dictionary/updateCostType',
        data
      })
    },
    deleteCostType(data) { //删除费用分类
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCostType',
        data
      })
    },
    updateSysCostTypeSort(data) { //排序费用类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysCostTypeSort',
        data
      })
    },
    findAllCostInvoiceType() { //查询发票类型
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllCostInvoiceType',
      })
    },
    addCostInvoiceType(data) { //增加发票类型
      return request({
        method: 'POST',
        url: '/system/dictionary/addCostInvoiceType',
        data
      })
    },
    updateCostInvoiceType(data) { //更新发票类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateCostInvoiceType',
        data
      })
    },
    deleteCostInvoiceType(data) { //删除发票类型
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteCostInvoiceType',
        data
      })
    },
    updateSysCostInvoiceTypeSort(data) { //排序发票类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysCostInvoiceTypeSort',
        data
      })
    },
  },
  // 生产相关数据字典
  production: {
    // bom分类
    findAllBomType() { //查询bom类
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllBomType',
      })
    },
    addBomType(data) { //增加bom类
      return request({
        method: 'POST',
        url: '/system/dictionary/addBomType',
        data: data
      })
    },
    deleteteBomType(data) { //删除bom类
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteBomType',
        data: data
      })
    },
    updateBomType(data) { //修改bom类
      return request({
        method: 'POST',
        url: '/system/dictionary/updateBomType',
        data: data
      })
    },
    updateSysMesBomTypeSort(data) { //排序bom类
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysMesBomTypeSort',
        data: data
      })
    },
    // 工序类型
    findAllProcessType() { //查询工序类型
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllProcessType',
      })
    },
    addProcessType(data) { //添加工序类型
      return request({
        method: 'POST',
        url: '/system/dictionary/addProcessType',
        data: data
      })
    },
    deleteProcessType(data) { //删除工序类型
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteProcessType',
        data: data
      })
    },
    updateProcessType(data) { //更新工序类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateProcessType',
        data: data
      })
    },
    //计费方式
    findAllMesCostType() { //查询计费方式
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllMesCostType',
      })
    },
    deleteMesCostType(data) { //删除计费方式
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteMesCostType',
        data: data
      })
    },
    updateMesCostType(data) { //更新计费方式
      return request({
        method: 'POST',
        url: '/system/dictionary/updateMesCostType',
        data: data
      })
    },
    addMesCostType(data) { //添加计费方式
      return request({
        method: 'POST',
        url: '/system/dictionary/addMesCostType',
        data: data
      })
    },
    updateSysMesCostTypeSort(data) { //排序计费方式
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysMesCostTypeSort',
        data: data
      })
    },
    //生产类型
    findAllManufactureType() { //查询生产类型
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllManufactureType',
      })
    },
    updateManufactureType(data) { //修改生产类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateManufactureType',
        data: data
      })
    },
    deleteManufactureType(data) { //删除生产类型
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteManufactureType',
        data: data
      })
    },
    addManufactureType(data) { //增加生产类型
      return request({
        method: 'POST',
        url: '/system/dictionary/addManufactureType',
        data: data
      })
    },
    updateMesManufactureTypeSort(data) { //排序生产类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateMesManufactureTypeSort',
        data: data
      })
    },
    //加工单位
    findAllMesProcessUnit() { //查询加工单位
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllMesProcessUnit',
      })
    },
    updateMesProcessUnit(data) { //修改加工单位
      return request({
        method: 'POST',
        url: '/system/dictionary/updateMesProcessUnit',
        data: data
      })
    },
    deleteMesProcessUnit(data) { //删除加工单位
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteMesProcessUnit',
        data: data
      })
    },
    addMesProcessUnit(data) { //增加加工单位
      return request({
        method: 'POST',
        url: '/system/dictionary/addMesProcessUnit',
        data: data
      })
    },
    updateMesProcessUnitSort(data) { //排序加工单位
      return request({
        method: 'POST',
        url: '/system/dictionary/updateMesProcessUnitSort',
        data: data
      })
    },
    //不良品项相关
    findAllProcessRejects(){//查询企业不良品项
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllProcessRejects',
      })
    },
    addProcessRejects(data){//新增不良品项
      return request({
        method: 'POST',
        url: '/system/dictionary/addProcessRejects',
        data
      })
    },
    updateProcessRejects(data){//新增不良品项
      return request({
        method: 'POST',
        url: '/system/dictionary/updateProcessRejects',
        data
      })
    },
    deleteProcessRejects(data){//新增不良品项
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteProcessRejects',
        data
      })
    },
    
  },
  // 供应商相关
  supplierDict: {
    getSupplierClassfiy() { //获取所有供应商分类管理
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllSupplierType',
      })
    },
    deleteSupplierType(data) { //删除供应商分类管理
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteSupplierType',
        data
      })
    },
    addSupplierType(data) { //添加供应商分类管理
      return request({
        method: 'POST',
        url: '/system/dictionary/addSupplierType',
        data
      })
    },
    updateSupplierType(data) { //修改供应商分类管理
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSupplierType',
        data
      })
    },
    updateSysSupplierTypeSort(data) { //排序供应商分类管理
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysSupplierTypeSort',
        data
      })
    },
    findAllSupplierMember() { //查询所有会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllSupplierMember',
      })
    },
    deleteSupplierMember(data) { //删除会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteSupplierMember',
        data
      })
    },
    addSupplierMember(data) { //增加会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/addSupplierMember',
        data
      })
    },
    updateSupplierMember(data) { //更新会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSupplierMember',
        data
      })
    },
    updateSysSupplierMemberSort(data) { //排序会员等级
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysSupplierMemberSort',
        data
      })
    },
  },

  // 货币相关
  productDict: {
    //计量单位
    getProductUnit() { //获取计量单位数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllProductUnit',
      })
    },
    addProductUnit(data) { //新增计量单位数据
      return request({
        method: 'POST',
        url: '/system/dictionary/addProductUnit',
        data
      })
    },
    deleteProductUnit(data) { //删除计量单位数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteProductUnit',
        data
      })
    },
    updateProductUnit(data) { //修改计量单位数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateProductUnit',
        data
      })
    },
    updateSysProductUnitSort(data) { //计量单位排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysProductUnitSort',
        data
      })
    },
    findAllProductUnitName(){//查询所有的多计量单位
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllProductUnitName',
      })
    },
    findProductUnitByProductType(data){//根据分类查询设置的计量单位
      return request({
        method: 'POST',
        url: '/system/dictionary/findProductUnitByProductType',
        data
      })
    },
    addProductUnitName(data){//添加多计量单位
      return request({
        method: 'POST',
        url: '/system/dictionary/addProductUnitName',
        data
      })
    },
    updateProductUnitName(data){//修改多计量单位
      return request({
        method: 'POST',
        url: '/system/dictionary/updateProductUnitName',
        data
      })
    },
    deleteProductUnitName(data){//删除多计量单位
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteProductUnitName',
        data
      })
    },

    //货品分类相关
    getAllProductClassfiy() { //获取所有货品分类数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllProductTypeNoChild'
      })
    },
    getProductClassfiy() { //获取货品分类数据
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllProductType'
      })
    },
    addProductClassfiy(data) { //新增货品分类
      return request({
        method: 'POST',
        url: '/system/dictionary/addProductType',
        data,
      })
    },
    deleteProductClassfiy(data) { //删除货品分类数据
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteProductType',
        data,
      })
    },
    updateProductClassfiy(data) { //修改货品分类数据
      return request({
        method: 'POST',
        url: '/system/dictionary/updateProductType',
        data,
      })
    },
    updateProClassifySort(data) { //修改货品分类排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSort',
        data,
      })
    },

    // 品牌相关
    findAllBrand() { //查询品牌管理
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllProductBrand',
      })
    },
    updateBrand(data) { //更新品牌
      return request({
        method: 'POST',
        url: '/system/dictionary/updateProductBrand',
        data,
      })
    },
    deleteBrand(data) { //删除品牌
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteProductBrand',
        data,
      })
    },
    addBrand(data) { //增加品牌
      return request({
        method: 'POST',
        url: '/system/dictionary/addProductBrand',
        data,
      })
    },
    updateSysProductBrandSort(data) { //品牌排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysProductBrandSort',
        data,
      })
    },

    //货品属性相关
    findAllProductProperty(data) { //查询货品属性
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllProductProperty',
        data,
      })
    },
    deleteProductProperty(data) { //删除货品属性
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteProductProperty',
        data,
      })
    },
    addProductProperty(data) { //增加货品属性
      return request({
        method: 'POST',
        url: '/system/dictionary/addProductProperty',
        data,
      })
    },
    updateProductProperty(data) { //修改货品属性
      return request({
        method: 'POST',
        url: '/system/dictionary/updateProductProperty',
        data,
      })
    },
    updateSysProductPropertySort(data) { //排序货品属性
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysProductPropertySort',
        data,
      })
    },

    //货品来源相关
    findAllProductSource() { //查询货品来源
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllProductSource',
      })
    },
    addProductSource(data) { //增加货品来源
      return request({
        method: 'POST',
        url: '/system/dictionary/addProductSource',
        data,
      })
    },
    updateProductSource(data) { //修改货品来源
      return request({
        method: 'POST',
        url: '/system/dictionary/updateProductSource',
        data,
      })
    },
    deleteProductSource(data) { //删除货品来源
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteProductSource',
        data,
      })
    },
    updateSysProductSourceSort(data) { //修改货品来源排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysProductSourceSort',
        data,
      })
    },

    //货品规格
    updateProdoctClassfiy(data) { //更新分类
      return request({
        method: 'POST',
        url: '/system/dictionary/updateProduct',
        data,
      })
    },
    getProductSpecification() { //获取规格信息
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllSysProductSpecsName',
      })
    },
    addProductSpecification(data) { //添加规格信息
      return request({
        method: 'POST',
        url: '/system/dictionary/addSysProductSpecsName',
        data
      })
    },
    delectProductSpecification(data) { //删除规格信息
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteSysProductSpecsName',
        data
      })
    },
    updateProductSpecification(data) {
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysProductSpecsName',
        data,
      })
    },
    updateProductSpecificationSort(data) { //规格排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysProductSpecsNameSort',
        data
      })
    },
  },
  //销售相关
  sales: {
    findAllSupplierSettlement() { //查询结算方式
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllSupplierSettlement',
      })
    },
    addSupplierSettlement(data) { //添加结算方式
      return request({
        method: 'POST',
        url: '/system/dictionary/addSupplierSettlement',
        data: data
      })
    },
    deleteSupplierSettlement(data) { //删除结算方式
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteSupplierSettlement',
        data: data
      })
    },
    updateSupplierSettlement(data) { //更新结算方式
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSupplierSettlement',
        data: data
      })
    },
    updateSysSupplierSettlementSort(data) { //排序结算方式
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysSupplierSettlementSort',
        data: data
      })
    },
    addOrderDocumentType(data) { //增加单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/addOrderDocumentType',
        data: data
      })
    },
    deleteOrderDocumentType(data) { //删除单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteOrderDocumentType',
        data: data
      })
    },
    findAllOrderDocumentType() { //查询单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllOrderDocumentType',
      })
    },
    updateOrderDocumentType(data) { //更新单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateOrderDocumentType',
        data: data
      })
    },
    updateSysOrderDocumentTypeSort(data) { //排序单据类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysOrderDocumentTypeSort',
        data: data
      })
    },
    addOrderType(data) { //增加报价类型
      return request({
        method: 'POST',
        url: '/system/dictionary/addOrderType',
        data: data
      })
    },
    deleteOrderType(data) { //删除报价类型
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteOrderType',
        data: data
      })
    },
    findAllOrderType() { //查询报价类型
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllOrderType',
      })
    },
    updateOrderType(data) { //更新报价类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateOrderType',
        data: data
      })
    },
    updateSysOrderTypeSort(data) { //排序报价类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysOrderTypeSort',
        data: data
      })
    },
    getPurchaseType() { //查询采购类型
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllPurchaseType',
      })
    },
    addPurchaseType(data) { //新增采购类型
      return request({
        method: 'POST',
        url: '/system/dictionary/addPurchaseType',
        data,
      })
    },
    delectPurchaseType(data) { //删除采购类型
      return request({
        method: 'POST',
        url: '/system/dictionary/deletePurchaseType',
        data,
      })
    },
    updatePurchaseType(data) { //修改采购类型
      return request({
        method: 'POST',
        url: '/system/dictionary/updatePurchaseType',
        data,
      })
    },
    updatePurchaseTypeSort(data) { //采购类型排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysPurchaseTypeSort',
        data,
      })
    },
    getPriceClause() { //查询价格条款
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllOrderPriceTerms',
      })
    },
    addPriceClause(data) { //新增价格条款
      return request({
        method: 'POST',
        url: '/system/dictionary/addOrderPriceTerms',
        data
      })
    },
    delectPriceClause(data) { //删除价格条款
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteOrderPriceTerms',
        data
      })
    },
    updatePriceClause(data) { //修改价格条款
      return request({
        method: 'POST',
        url: '/system/dictionary/updateOrderPriceTerms',
        data
      })
    },
    updatePriceClauseSort(data) { //价格条款排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysOrderPriceTermsSort',
        data
      })
    },
    getPaymentMode() { //查询收汇方式
      return request({
        method: 'POST',
        url: '/system/dictionary/findAllOrderPayExchange',
      })
    },
    addPaymentMode(data) { //新增收汇方式
      return request({
        method: 'POST',
        url: '/system/dictionary/addOrderPayExchange',
        data
      })
    },
    delectPaymentMode(data) { //删除收汇方式
      return request({
        method: 'POST',
        url: '/system/dictionary/deleteOrderPayExchange',
        data
      })
    },
    updatePaymentMode(data) { //修改收汇方式
      return request({
        method: 'POST',
        url: '/system/dictionary/updateOrderPayExchange',
        data
      })
    },
    updatePaymentModeSort(data) { //收汇方式排序
      return request({
        method: 'POST',
        url: '/system/dictionary/updateSysOrderPayExchangeSort',
        data
      })
    },

  }
}
