// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：客户相关请求接口
// 开始时间：2021-05-5
// 开发人员：刘巍骏
// 最后修改：2021-05-5
// 备注说明：如需修改请联系开发人员

import {request} from "../request.js";
import qs from 'qs';

export default{
  //联系人相关
  findLinkManByCustomerid(data){//根据客户id查询联系人信息
    return request({
      method:'POST',
      url:'/crm/findLinkManByCustomerid',
      data
    })
  },
  updateLinkManByLinkmanId(data){//根据联系人id修改联系人信息
    return request({
      method:'POST',
      url:'/crm/updateLinkManByLinkmanId',
      data
    })
  },
  findCustomerByCondition(data){//条件查询客户期初列表信息
    return request({
      method:'POST',
      url:'/crm/crmDefault/findCustomerByCondition',
      data
    })
  },
  setCustomerOpenning(data){//设置客户期初信息
    return request({
      method:'POST',
      url:'/crm/crmDefault/setCustomerOpenning',
      data
    })
  },
  findCustomerById(data){//根据客户id获取客户信息
    return request({
      method:'POST',
      url:'/crm/crmCustomer/findCustomerById',
      data
    })
  },
  generateCustoimerCode(data){//生成客户编码
    return request({
      method:'POST',
      url:'/crm/crmCustomer/generateCustoimerCode',
      data
    })
  },
  loginByCustomer(data){//客户账号密码登录系统
    return request({
      method:'POST',
      url:'/crm/crmDefault/loginByCustomer',
      data
    })
  },
}
