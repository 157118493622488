/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：新增项目全局的公用函数js
 * 开始时间：2021-01-29
 * 开发人员：刘巍骏
 * 最后修改：2021-01-29
 * 备注说明：如需修改请联系开发人员
 */
// 导入element ui Loadig服务
import {
  Loading,
  Message
} from 'element-ui'

//导入拖拽js
import Sortable from "sortablejs";
//导入vuex
import store from '../vuex/store';

export default {
  /* ****************控制dom结构相关方法**************** */
  // 使div可动态拉伸 left:左边盒子 right:右边盒子 handle:中间拉伸盒子
  getDunamicWidth(data) {
    let left, right, hander, minWidth;
    // 判断有无传参
    if (!!data) { //有传数据
      // 判断是否传入dom信息
      if (!data.left) {
        left = 'AppLeftBox';
        right = 'AppRightBox';
        hander = 'AppHandelBox';
      } else {
        left = data.left;
        right = data.right;
        hander = data.hander;
      }
      //判断是否传入宽度信息
      if (!data.minWidth) {
        minWidth = 200;
      } else {
        minWidth = data.minWidth;
      }
    } else { //未传数据
      // console.log("未传数据");
      left = 'AppLeftBox';
      right = 'AppRightBox';
      hander = 'AppHandelBox';
      minWidth = 200;
    }
    // 初始化拉伸
    var doc = $(document),
      dl = $("div." + left),
      dc = $("div." + right);
    var sum = dl.width() + dc.width();
    $("div." + hander).mousedown(function(e) {
      var me = $(this);
      var deltaX = e.clientX -
        (parseFloat(me.css("left")) || parseFloat(me.prop("clientLeft")));
      doc.mousemove(function(e) {
        var lt = e.clientX - deltaX;
        lt = lt < minWidth ? minWidth : lt; //最小宽度200px
        lt = lt > sum - me.width() ? sum - me.width() : lt;
        me.css("left", lt + "px");
        dl.width(lt);
        dc.width(sum - lt - me.width());
      });
    }).width();
    doc.mouseup(function() {
      doc.unbind("mousemove");
    });
    //内容文本不可选中
    // doc[0].ondragstart = doc[0].onselectstart = function() {
    //   return false;
    // };
  },

  /*
    使表格可动态拉伸列宽
    id:需要拉伸功能的表格id
   */
  table_DunamicWidth(id) {
    var i,
      self,
      table = document.getElementById(id),
      header = table.rows[0],
      tableX = header.clientWidth,
      length = header.cells.length;
    for (i = 0; i < length; i++) {
      header.cells[i].onmousedown = function() {
        self = this;
        if (event.offsetX > self.offsetWidth - 10) {
          self.mouseDown = true;
          self.oldX = event.x;
          self.oldWidth = self.offsetWidth;
        }
      };
      header.cells[i].onmousemove = function() {
        if (event.offsetX > this.offsetWidth - 10) {
          this.style.cursor = 'col-resize';
        } else {
          this.style.cursor = 'default';
        }
        if (self == undefined) {
          self = this;
        }
        if (self.mouseDown != null && self.mouseDown == true) {
          self.style.cursor = 'default';
          if (self.oldWidth + (event.x - self.oldX) > 0) {
            self.width = self.oldWidth + (event.x - self.oldX);
          }
          self.style.width = self.width;
          table.style.width = tableX + (event.x - self.oldX) + 'px';
          self.style.cursor = 'col-resize';
        }
      };
      table.onmouseup = function() {
        if (self == undefined) {
          self = this;
        }
        self.mouseDown = false;
        self.style.cursor = 'default';
        tableX = header.clientWidth;
      };
    }
  },

  /* 自定义loading加载框 name:需要遮盖的容器class名或id名 type:哪种操作 title:提示信息(可为空)*/
  customLoading(name, type, title) {
    // console.log(name);
    // console.log(document.querySelector(name));
    // 当未找到dom对象时不加载loading框
    // if(!document.querySelector(name)){
    //   return
    // }
    // 判断是哪种操作(0:新增 1:修改 2:删除 3:查询 4:自定义)
    let titleMsg;
    if (type == 0) { //新增
      titleMsg = "数据更新中,请稍后...";
    } else if (type == 1) { //修改
      titleMsg = "数据修改中,请稍后...";
    } else if (type == 2) { //删除
      titleMsg = "数据删除中,请稍后...";
    } else if (type == 3) { //查询
      titleMsg = "数据加载中,请稍后...";
    } else if (type == 4) { //自定义
      //判断自定义信息是否存在
      if (title == undefined) {
        return;
      } else {
        titleMsg = title;
      }
    }
    //定义加载框相关数据     //修改的时候注意有些地方需要透明的(如单据列表关联其它单据)
    const loading = Loading.service({ // 声明一个loading对象
      lock: true, // 是否锁屏
      text: titleMsg, // 加载动画的文字
      spinner: 'el-icon-loading', // 引入的loading图标
      background: 'rgba(0, 0, 0, 0.8)', // 背景颜色
      target: document.querySelector(name)
    })
    return loading;
  },

  /* 自定义element ui表格排序拖动方法(行拖动) dom:需要操作的dom结构 result:返回移动前数据下标和移动后位置下标*/
  customDragrow(dom) {
    const el = document.querySelector(dom);
    Sortable.create(el, {
      disabled: false, // 拖拽不可用? false启用
      animation: 150, // 拖拽延时，效果更好看
      handle: ".my-handle",
      onEnd: (e) => { //结束时触发事件
        //定义返回值
        let result = {
          oldIndex: e.oldIndex,
          newIndex: e.newIndex
        }
        //将数据存到Vuex
        store.commit('SORTINDEX', result)
      }
    })
  },

  /* ***************获取日期*************** */
  //获取日期 date:传入的国际标准日期   type:0(年月日) 1(时分秒)  2(年月日时分秒)
  getDateTime(date, type) {
    let year = date.getFullYear().toString()
    let mon = date.getMonth() + 1
    mon = mon.toString().length === 2 ? mon : ('0' + mon)
    let day = date.getDate()
    day = day.toString().length === 2 ? day : ('0' + day)
    let hours = date.getHours()
    hours = hours.toString().length === 2 ? hours : ('0' + hours)
    let min = date.getMinutes()
    min = min.toString().length === 2 ? min : ('0' + min)
    let se = date.getSeconds()
    se = se.toString().length === 2 ? se : ('0' + se)
    if (type == 0) {
      return `${year}-${mon}-${day}`
    } else if (type == 1) {
      return `${hours}:${min}:${se}`
    } else if (type == 2) {
      return `${year}-${mon}-${day} ${hours}:${min}:${se}`
    }
  },

  //日期转换 date需要转化的日期
  transformDate(date) {
    let now = new Date() //当前时间
    let year = ''; //年
    let month = ''; //月
    let day = ''; //日
    let yCount = 0; //y字符个数
    let mCount = 0; //M字符个数
    let dCount = 0; //d字符个数
    if (date) { //当date不为空时
      yCount = date.split('y').length - 1 //y字符个数
      mCount = date.split('M').length - 1 //M字符个数
      dCount = date.split('d').length - 1 //d字符个数
    }
    if (yCount != 0) { //当y字符个数不为0时
      year = now.getFullYear().toString().slice(-yCount) //截取字符串
    }
    if (mCount != 0) { //当M字符个数不为0时
      let monthNow = now.getMonth() + 1 //当前月份
      month = monthNow //截取字符串
    }
    if (dCount != 0) { //当d字符个数不为0时
      let dayNow = now.getDate() //当前日
      day = dayNow //截取字符串
    }
    //返回结果
    return year + month + day;
  },

  //根据周期类型获取周期日期(type=>类型)
  getDateByPeriodType(type) {
    //定义相关日期参数
    let date = new Date();
    let year = date.getFullYear().toString(); //本年
    let mon = date.getMonth() + 1; //本月
    let lastmonnum = new Date(year, mon - 1, 0).getDate(); //上月天数
    let monnum = new Date(year, mon, 0).getDate(); //本月天数
    let weekDay = date.getDay(); //本周几
    let day = date.getDate(); //当天(多少号)
    let oneday = 1000 * 60 * 60 * 24; //一天的时间
    let daynum = Math.ceil((new Date() - new Date(year)) / oneday); //当天在本年的天数
    let lastyearnum = 0; //去年的天数
    let nowtime = date.getTime(); //现在的时间
    //定义返回值
    let startDate = ""; //开始日期
    let endDate = ""; //结束日期
    // 判断周期类型
    if (type == -1) { //当天
      startDate = nowtime;
      endDate = nowtime;
    } else if (type == 0) { //本周
      startDate = nowtime - (weekDay - 1) * oneday;
      // endDate = nowtime + (7 - weekDay) * oneday;//本周结束日期
      endDate = nowtime - oneday;
    } else if (type == 1) { //上周
      startDate = nowtime - (weekDay - 1 + 7) * oneday;
      endDate = nowtime - (weekDay) * oneday;
    } else if (type == 2) { //两周内
      startDate = nowtime - (14) * oneday;
      endDate = nowtime - oneday;
    } else if (type == 3) { //本月
      startDate = nowtime - (day - 1) * oneday;
      // endDate = nowtime + (monnum - day) * oneday;//本月结束日期
      endDate = nowtime - oneday; //当天
    } else if (type == 4) { //上月
      startDate = nowtime - (day + lastmonnum - 1) * oneday; //今天号数+上月天数
      endDate = nowtime - (day) * oneday; //减去当前号数
    } else if (type == 5) { //最近三个月
      let nearThreeDayNum = 0; //最近三个月天数
      for (let i = 1; i < 4; i++) {
        nearThreeDayNum = nearThreeDayNum + new Date(year, mon - i, 0).getDate();
      }
      startDate = nowtime - (nearThreeDayNum) * oneday; //(需调整)
      endDate = nowtime - oneday; //当天
    } else if (type == 6) { //半年内
      let nearThreeDayNum = 0; //最近半年的天数
      for (let i = 1; i < 7; i++) {
        nearThreeDayNum = nearThreeDayNum + new Date(year, mon - i, 0).getDate();
      }
      startDate = nowtime - (nearThreeDayNum) * oneday; //(需调整)
      endDate = nowtime - oneday; //当天
    } else if (type == 7) { //本年
      startDate = nowtime - (daynum - 1) * oneday; //今天本年第几天数减一天
      endDate = nowtime - oneday; //当天
    } else if (type == 8) { //去年
      //获取去年的天数
      let lastYearNum = 0;
      for (let i = 1; i < 13; i++) {
        lastYearNum = lastYearNum + new Date(year - 1, i, 0).getDate();
      }
      startDate = nowtime - (lastYearNum + daynum - 1) * oneday; //(需调整)
      endDate = nowtime - (daynum) * oneday; //当前时间减今天的第几天
    } else if (type == 9) { //自定义
      startDate = "";
      endDate = "";
    }
    return {
      startDate: this.getDateTime(new Date(startDate), 0),
      endDate: this.getDateTime(new Date(endDate), 0)
    }
  },

  // 获取时间差startTime:开始时间  endTime:结束时间
  getDifferTime(startTime, endTime) {
    //判断日期区间
    let today = new Date(); //今天日期
    today.setHours(0, 0, 0, 0);
    if (startTime == '' || endTime == '') { //起止时间其中一个为空
      this.$message({
        type: 'warning',
        message: '起止日期不能为空!',
        duration: this.elDuration
      })
      return false;
    } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
      this.$message({
        type: 'warning',
        message: '结束日期不能早于开始日期!',
        duration: this.elDuration
      })
      return false;
    } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime
      .getTime()) { //所选择的日期不能早于当前日期
      this.$message({
        type: 'warning',
        message: '所选择的日期不能早于当前日期!',
        duration: this.elDuration
      })
      return false;
    } else { //限制日期选择区间
      let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
      if (duringTime > 90) {
        this.$message({
          type: 'warning',
          message: '日期区间不能超过90天!',
          duration: this.elDuration
        })
        return false;
      }
    }
    return true;
  },


  /************************ 获取企业员工,部门等弹框信息转换 ************************/
  /* 获取员工弹框选择后的名称字符串和id字符串*/
  getPersonnelMsg(data) {
    //定义最小长度
    let minLength = 3;
    // 定义接受参数
    let idStr = "",
      nameStr = "",
      nameShowStr = "";
    // 获取id字符串
    data.forEach((item, index) => {
      idStr = idStr + "," + item.user_id;
      nameStr = nameStr + "," + item.user_name;
    })
    // 判断选择的人员有多少
    if (data.length > minLength) {
      data.forEach((item, index) => {
        if (index < minLength) {
          nameShowStr = nameShowStr + "," + item.user_name;
        }
      })
      nameShowStr = nameShowStr.substring(1, nameShowStr.length);
      // 超过定义信息显示提示信息
      nameShowStr = nameShowStr + "...等" + data.length + "人";
    } else {
      data.forEach((item, index) => {
        nameShowStr = nameShowStr + "," + item.user_name;
      })
      nameShowStr = nameShowStr.substring(1, nameShowStr.length);
    }
    // 定义返回参数
    let msg = {
      idStr: idStr.substring(1, idStr.length),
      nameStr: nameStr.substring(1, nameStr.length),
      nameShowStr: nameShowStr
    }
    return msg;
  },

  /* 获取权限用户字符串分割方法 */
  getPowerUserIdArr(UserStr) {
    //获取设置的用户数据
    let userArr = UserStr.split(",");
    //获取用户id数组
    let userIdArr = [];
    userArr.forEach((item, index) => {
      let userItemArr = item.split("@@@");
      userIdArr.push(parseInt(userItemArr[0]));
    })
    return userIdArr;
  },

  /* 获取部门弹框选择后的名称字符串和id字符串*/
  getDeptMsg(data) {
    //定义最小长度
    let minLength = 3;
    // 定义接受参数
    let idStr = "",
      nameStr = "",
      nameShowStr = "";
    // 获取id字符串
    data.forEach((item, index) => {
      idStr = idStr + "," + item.dept_id;
      nameStr = nameStr + "," + item.dept_name;
    })
    // 判断选择的人员有多少
    if (data.length > minLength) {
      data.forEach((item, index) => {
        if (index < minLength) {
          nameShowStr = nameShowStr + "," + item.dept_name;
        }
      })
      nameShowStr = nameShowStr.substring(1, nameShowStr.length);
      // 超过定义信息显示提示信息
      nameShowStr = nameShowStr + "...等" + data.length + "个部门";
    } else {
      data.forEach((item, index) => {
        nameShowStr = nameShowStr + "," + item.dept_name;
      })
      nameShowStr = nameShowStr.substring(1, nameShowStr.length);
    }
    // 定义返回参数
    let msg = {
      idStr: idStr.substring(1, idStr.length),
      nameStr: nameStr.substring(1, nameStr.length),
      nameShowStr: nameShowStr
    }
    return msg;
  },

  /* ****************驼峰字段转换**************** */
  // 下划线转换驼峰
  toHump(name) {
    return name.replace(/\_(\w)/g, function(all, letter) {
      return letter.toUpperCase();
    });
  },
  // 驼峰转换下划线
  toLine(name) {
    return name.replace(/([A-Z])/g, "_$1").toLowerCase();
  },

  /* ****************树形结构转换**************** */
  //数组转换为树形结构(data: 需要转换成树形结构的数组,parentId:父级id区分字段)
  toTree(data, parentId) {
    let result = []
    if (!Array.isArray(data)) {
      return result
    }
    data.forEach(item => {
      delete item.children;
    });
    let map = {};
    data.forEach(item => {
      map[item.id] = item;
    });
    data.forEach(item => {
      let parent = map[item[parentId]];
      if (parent) {
        (parent.children || (parent.children = [])).push(item);
      } else {
        item.children = []
        result.push(item);
      }
    });
    return result;
  },

  // 树形结构转换为数组(data:树形结构数据  arr:空数组)
  toNoTree(data, arr) {
    data.forEach((item, index) => {
      let obj = {}
      for (let i in item) {
        if (i != 'children') {
          obj[i] = item[i]
        }
      }
      if (JSON.stringify(arr).indexOf(JSON.stringify(obj)) == -1) {
        arr.push(obj)
      }
      //如果有子级,使用回调
      if (item.children && item.children.length > 0) {
        this.toNoTree(item.children, arr)
      }
    })
    return arr;
  },

  /* *************数字相关************* */
  // 获取小数位数(decimals:需要处理的数据,len小数位长度)
  getDecimalsLen(decimals, len) {
    var lens = Math.pow(10, len);
    return Math.round(decimals * lens) / lens;
  },


  /* *************数据字典相关************* */
  //根据id获取数据字典数据(id:需要匹配的id  data:被匹配的数据)
  getDictMatchingData(id, data) {
    let result = {}
    data.forEach((item, index) => {
      if (item.id == id) {
        result = item;
      }
    })
    return result;
  },

  //根据名称获取数据字典数据(name:需要匹配的名称  data:被匹配的数据)
  getDictMatchingDataByName(name, data) {
    let result = {}
    data.forEach((item, index) => {
      if (item.name == name) {
        result = item;
      }
    })
    return result;
  },

  //根据名称获取数据字典数据(code:需要匹配的Code值  data:被匹配的数据)
  getDictMatchingDataByCode(code, data) {
    let result = {}
    data.forEach((item, index) => {
      if (item.code == code) {
        result = item;
      }
    })
    return result;
  },

  //根据特定条件获取单据类型数据(type=>0:id 1:名称 2:code params:查询条件 data:被匹配的数据)
  getBillsDataByType(type, params, data) {
    let result
    let tempArr = ['id', 'bills_type', 'code']
    data.forEach((itemI, indexI) => {
      if (itemI.children) {
        itemI.children.forEach((itemJ, indexJ) => {
          if (itemJ[tempArr[type]] == params) {
            result = itemJ;
          }
        })
      } else {
        result = {};
      }
    })
    return result;
  },

  //根据特定条件获取单据分类数据(type=>0:id 1:名称 2:code params=>查询条件 data=>被匹配的数据)
  getBillsClassifyDataByType(type, params, data) {
    let result;
    let tempArr = ['temp_id', 'temp_name', 'temp_code']
    data.forEach((item, index) => {
      if (item[tempArr[type]] == params) {
        result = item;
      }
    })
    return result;
  },

  /* 根据标识获取单据类型数据sign 模块分类  classify:子分类 data:数据源*/
  getBillsClassifyBySign(sign, classify, data) {
    let result = [];
    let typeCode = "";
    // 根据标识判断code
    if (sign == 0) { //销售
      typeCode = "SYS-NODE-TYPE-001";
    } else if (sign == 1) { //生产计划
      typeCode = "SYS-NODE-TYPE-003";
    } else if (sign == 2) { //库存
      typeCode = "SYS-NODE-TYPE-004";
    } else if (sign == 3) { //采购
      typeCode = "SYS-NODE-TYPE-002";
    } else if (sign == 5) { //收付款
      typeCode = "SYS-NODE-TYPE-005";
    } else if (sign == 6) { //报销
      typeCode = "SYS-NODE-TYPE-005";
    } else if (sign == 7) { //生产执行
      typeCode = "SYS-NODE-TYPE-003";
    } else if (sign == 8) { //发票
      typeCode = "SYS-NODE-TYPE-005";
    }
    data.forEach((item, index) => {
      if (item.temp_code == typeCode) {
        //判断是否有子级
        if (item.children) {
          item.children.forEach((itemJ, indexJ) => {
            if (!!classify) {
              if (itemJ.type_classify == classify) {
                result.push(itemJ);
              }
            } else {
              result.push(itemJ);
            }
          })
        }
      }
    })
    return result;
  },

  //根据员工ID获取员工名称
  getPersonnelNameById(idStr) {
    store.dispatch('getAllUserData');
  },

  /* *************单据相关************ */
  // 权限判断 (parentLevel:一级(模块) childrenLevel:二级(子分类) power:增删改查等权限 name:权限名称 isShowMessage:0 不显示提示信息)
  isHaveThePower(parentLevel, childrenLevel, power, name, isShowMessage) {
    // return false;//开启后权限将不在生效
    let ishave = false;
    let userPowerInfo = store.state.user.userPowerInfo;
    let filterPower = userPowerInfo.filter(item => item.parentLevel == parentLevel && item.childrenLevel ==
      childrenLevel && item.power == power);
    if (filterPower.length == 0) {
      if (isShowMessage !== 0) {
        Message({
          message: '您没有' + name + '权限!',
          type: 'warning',
          duration: 2000
        });
      }
      ishave = true;
    }
    return ishave;
  },

  //匹配单据信息所需名称字段
  getBaseNameData(iddata, nameData) {
    let resData = iddata;
    //定义需要名称的字段信息
    let suitedData = {
      //员工信息(三种形式)
      business_id: 'business_name', //员工信息(业务员)
      sale_user_id: 'sale_user_name', //员工信息(业务员)
      principal_id: 'principal_name', //负责人(生产单使用)
      user_id: 'user_name', //员工信息(业务员)
      //部门信息
      dept_id: 'dept_name', //部门名称
      //客户信息
      customer_id: 'customer_name', //客户名称
      //供应商信息
      supplier_id: 'supplier_name', //供应商名称
      //数据字典
      //其他信息
      relevance_bills: 'relevance_bills_no', //关联单据id(源单单号)
      customer_id: 'customer_name', //客户
    }
    //循环获取名称信息
    for (let i in suitedData) {
      if (resData.hasOwnProperty(i)) {
        resData[suitedData[i]] = nameData[i];
      }
    }
    return resData;
  },

  // 根据sign标识返回分类code值
  findNodeTypeCodeBySign(sign) {
    let nodeTypeCode = "";
    if (sign == 0) { //销售
      nodeTypeCode = 'SYS-NODE-TYPE-001'; //设置单据分类code为订单
    } else if (sign == 1) { //生产计划
      nodeTypeCode = 'SYS-NODE-TYPE-003'; //设置单据分类code为生产
    } else if (sign == 2) { //库存
      nodeTypeCode = 'SYS-NODE-TYPE-004'; //设置单据分类code为出入库
    } else if (sign == 3) { //采购
      nodeTypeCode = 'SYS-NODE-TYPE-002'; //设置单据分类code为采购
    } else if (sign == 5) { //收付款
      nodeTypeCode = 'SYS-NODE-TYPE-005'; //设置单据分类code为资金
    } else if (sign == 6) { //报销
      nodeTypeCode = 'SYS-NODE-TYPE-005'; //设置单据分类code为资金
    } else if (sign == 7) { //生产执行
      nodeTypeCode = 'SYS-NODE-TYPE-003'; //设置单据分类code为生产
    } else if (sign == 8) { //发票
      nodeTypeCode = 'SYS-NODE-TYPE-005'; //设置单据分类code为资金
    }
    return nodeTypeCode;
  },

  //根据sign标识判断不同模块查询权限
  judgeBillsSelPowerBySign(sign, classify) {
    //定义返回值
    let result = false;
    //判断模块
    if (sign == 0) { //销售
      if (classify == 0) { //报价
        result = this.isHaveThePower(14, 1, 1, '查询', 0);
      } else if (classify == 1) { //订单
        result = this.isHaveThePower(14, 2, 1, '查询', 0);
      }
    } else if (sign == 1) { //生产计划
      result = this.isHaveThePower(16, 1, 1, '查询', 0);
    } else if (sign == 2) { //库存
      if (classify == 0) { //入库单
        result = this.isHaveThePower(15, 1, 1, '查询', 0);
      } else if (classify == 1) { //出库单
        result = this.isHaveThePower(15, 2, 1, '查询', 0);
      } else if (classify == 2) { //报损单
        result = this.isHaveThePower(15, 4, 1, '查询', 0);
      } else if (classify == 3) { //组装单
        result = this.isHaveThePower(15, 5, 1, '查询', 0);
      } else if (classify == 4) { //拆卸单
        result = this.isHaveThePower(15, 6, 1, '查询', 0);
      } else if (classify == 5) { //调拨单
        result = this.isHaveThePower(15, 3, 1, '查询', 0);
      } else if (classify == 6) { //调价单
        result = this.isHaveThePower(15, 7, 1, '查询', 0);
      } else if (classify == 10) { //质检单
        result = this.isHaveThePower(15, 10, 1, '查询', 0);
      }
    } else if (sign == 3) { //采购
      if (classify == 0) { //申请
        result = this.isHaveThePower(23, 1, 1, '查询', 0);
      } else if (classify == 1) { //订单
        result = this.isHaveThePower(23, 2, 1, '查询', 0);
      } else if (classify == 2) { //询价
        result = this.isHaveThePower(23, 7, 1, '查询', 0);
      }
    } else if (sign == 5) { //收付款
      if (classify == 0) { //收款单
        result = this.isHaveThePower(17, 2, 1, '查询', 0);
      } else if (classify == 1) { //付款单
        result = this.isHaveThePower(17, 1, 1, '查询', 0);
      }
    } else if (sign == 6) { //报销
      result = this.isHaveThePower(17, 3, 1, '查询', 0);
    } else if (sign == 7) { //生产执行
      result = this.isHaveThePower(16, 9, 1, '查询', 0);
    } else if (sign == 8) { //发票
      result = this.isHaveThePower(17, 5, 1, '查询', 0);
    }
    return result;
  },

  /* *************日志相关************ */
  //日志信息拼接 (sourceData:更改前数据, editData:更改后数据, name:模块名称)
  mergeLogContent(sourceData, editData, name) {
    let keys = Object.keys(editData) //获取字段名
    let logContent = '' //日志内容
    let objFiled = { //定义字典
      //通用
      name: '名称',
      is_enable: '状态',
      remark: '说明',
      //结算方式
      assign_day: '指定天数',
      //仓库配置
      encoding: '编码',
      department: '所属部门',
      departmentId: '所属部门id',
      warehouseman: '仓管员',
      warehousemanId: '仓管员id',
      address: '详细地址',
      phone: '电话',
      //收款类型
      assign_currency: '指定货币',
      //财务相关
      currency_symbol: '货币符号',
      exchange_rate: '与人民币汇率',
      outin_sign: '收支标识',
      company_telephone: '联系电话',
      company_address: '公司地址',
      company_account: '开户账号',
      company_currency: '币种',
      taxes_number: '纳税编号',
      company_name: '公司名称',
      company_bank: '开户银行',
      subjectName: '会计科目',
      toolAccount: '收款账号',
      AccountName: '账号名称',
      account_name: '开户人姓名',
      bank_name: '开户银行',
      account_num: '开户账号',
      currency: '币种',
      balance: '余额',
      //货品相关
      visible_sell: '销售报价可见',
      visible_material: 'BOM配料可见',
      visible_product: '是否生产自由添加',
      state: '状态',
      product_type: '货品分类',
      product_typeId: '货品分类id',
      userNameStr: '可见员工',
      is_depot: '不参与库存',
      is_depot: '不参与成本核算',
      is_depot: 'BOM合成物料',
      //货品管理
      product_old_code: '原物料编码',
      specifications: '规格信息',
      // descrt_name:'',
      pro_source_id: '货品来源', //改为名称
      brand_id: '品牌', //改为名称
      pro_property_id: '', //改为名称
      desciption: '商品简介',
      supplier_id: '供应商', //改为名称
      weight: '重量',
      weight_unit: '重量单位',
      order_limit_num: '起订量',
      is_group_product: '是否可组装',
      is_disassembly: '是否可拆卸',
    }
    let filterIds = ['departmentId', 'product_typeId', 'warehousemanId', 'userId', 'subject'] //指定需要过滤的字段
    keys.forEach(item => {
      if (editData[item] + '' !== sourceData[item] + '') { //匹配出修改的字段
        logContent += '更改了【' + name + '】中的【' + objFiled[item] + '】,更改前:'
        if (item == 'is_enable' || item == 'visible_sell' || item == 'visible_material' || item ==
          'state') { //当字段为布尔值时 (true:启用 false:不启用)
          logContent += (sourceData[item] ? '启用' : '不启用') + ",更改后:" + (editData[item] ? '启用' : '不启用') + ';' //拼接日志信息
        } else if (item == 'outin_sign') { //当字段为收支标识时 (0:收 1:支 2:无)
          logContent += (sourceData[item] == 0 ? '收' : (sourceData[item] == 1 ? '支' : '无')) + ",更改后:" + (editData[
            item] == 0 ? '收' : (editData[item] == 1 ? '支' : '无')) + ';' //拼接日志信息
        } else { //当为其他字段时
          if (filterIds.indexOf(item) == -1) { //过滤id
            logContent += sourceData[item] + ",更改后:" + editData[item] + ';' //拼接日志信息
          }
        }
      }
    })
    // console.log(logContent)
    return logContent
  },

  /* null 转 空字符串 data:需要转换的数组或对象*/
  nullToStr(data) {
    for (let itemI in data) {
      if (data[itemI] === null) { // 如果是null 把直接内容转为 ''
        data[itemI] = '';
      } else {
        if (Array.isArray(data[itemI])) { // 是数组遍历数组 递归继续处理
          data[itemI] = data[itemI].map(itemJ => {
            return null2str(itemJ);
          });
        }
        if (typeof(data[itemI]) === 'object') { // 是json 递归继续处理
          data[itemI] = null2str(data[itemI])
        }
      }
    }
    return data;
  },

  /*********************** 图片压缩 ************************/
  /* 限制图片上传 file:文件 companyparamlists:公共参数 type:是否限制只可传图片(0:文件不做限制 1:只能传图片)*/
  beforeUpload(file, companyparamlists, type) {
    //判断未传参数默认为0
    if (!!type) {
      type == 0;
    }
    let _this = this;
    let suffix = _this.getFileType(file.name) //获取文件后缀名
    let suffixArray = ['jpg', 'png', 'jpeg', 'gif', 'jfif', 'bmp'] //限制的文件类型
    let limitWidth = 1280; //默认文件宽度
    if (companyparamlists) { //判空
      companyparamlists.forEach(item => {
        if (item.para_id == '298') {
          limitWidth = item.para_value //获取文件宽度
        }
      })
    }
    if (suffixArray.indexOf(suffix) === -1) { //不为符合条件的图片进行压缩
      //判断是否只能传图片
      if (type == 1) { //是
        Message({
          type: 'warning',
          message: '该处只能上传图片文件',
          duration: 2000
        })
        return false;
      } else {
        return file
      }
    } else { //为符合条件的图片进行压缩
      return new Promise((resolve, reject) => {
        let image = new Image(),
          resultBlob = '';
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          // 调用方法获取blob格式
          resultBlob = _this.compressUpload(image, file, limitWidth);
          resolve(resultBlob)
        }
        image.onerror = () => {
          reject()
        }
      })
    }
  },

  /* 获取文件后缀名 */
  getFileType(name) {
    let startIndex = name.lastIndexOf('.')
    if (startIndex !== -1) {
      return name.slice(startIndex + 1).toLowerCase()
    } else {
      return ''
    }
  },

  /* 图片压缩方法-canvas压缩(image:原图信息  file:原文件 limitWidth:限制宽度) */
  compressUpload(image, file, limitWidth) {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    // 获取原图比例
    let {
      width,
      height
    } = image
    let scale = width / height; //图片比例
    let ctxHeight = limitWidth / scale; //图片高度
    // 创建属性节点
    let nodeWidth = document.createAttribute("width");
    nodeWidth.nodeValue = limitWidth;
    let nodeHeight = document.createAttribute("height");
    nodeHeight.nodeValue = ctxHeight;
    canvas.setAttributeNode(nodeWidth);
    canvas.setAttributeNode(nodeHeight);
    ctx.drawImage(image, 0, 0, limitWidth, ctxHeight)
    // 进行压缩
    let compressData = canvas.toDataURL(file.type || 'image/jpeg', 1.0)
    // 压缩后进行base64转Blob
    let byteString;
    if (compressData.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(compressData.split(',')[1])
    } else {
      byteString = unescape(compressData.split(',')[1])
    }
    let mimeString = compressData.split(',')[0].split(':')[1].split(';')[0];
    let ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i)
    }
    let blobImg = new Blob([ia], {
      type: mimeString
    })
    return blobImg
  },


  /* ***************************单据列表相关*************************** */
  // 初始化列表显示字段数据(cookieName:cookie名称 listData:列表总字段)
  initListShowFiledData(cookieName, listData) {
    //设置默认字段
    let showListFiledArr = []; //cookie中显示的字段
    let showListFiledData = []; //需要显示的字段
    //获取cookie的值
    let cookieStr = getCookie(cookieName);
    // 判断cookie是否有值
    if (cookieStr) {
      //分割成字符串数组
      let cookieStrArr = cookieStr.split(",");
      //将数组转换为int数组
      showListFiledArr = cookieStrArr.map((item) => {
        return +item;
      })
    } else {
      //默认全部加载
      listData.forEach((item, index) => {
        showListFiledArr.push(item.value);
      })
    }
    //设置可显示字段
    showListFiledData = listData;
    //判断是否默认勾选
    showListFiledData.forEach((item, index) => {
      if (showListFiledArr.includes(item.value)) {
        item.is_show = true;
      }
    })
    //定义返回值
    let resData = {
      showListFiledArr: showListFiledArr,
      showListFiledData: showListFiledData
    }
    return resData;
  },

  /* 获取当前需要显示的列表字段 */
  getCurShowFiledArr(cookieName, showListFiledData) {
    //循环前清空数据
    let showListFiledArr = [];
    //循环获取已勾选的数据
    showListFiledData.forEach((item, index) => {
      //判断是否勾选
      if (item.is_show) {
        showListFiledArr.push(item.value);
      }
    })
    //将数据存储到cookie
    setCookie(cookieName, showListFiledArr, 15);
    return showListFiledArr;
  },

  /* ***************************货品相关*************************** */
  /* 转换为货品基础计量数量 (number:转换的数量  priceData:当前计量信息 curUnitId:当前计量单位id) */
  getProductBaseNumber(number, priceData, curUnitId) {
    let resnum = number;

    //获取当前计量单位id
    let curUnitDataArr = priceData.filter(item => item.calculate_type == curUnitId);
    let curUnitData = "";
    if (curUnitDataArr.length > 0) {
      curUnitData = curUnitDataArr[0];
    }

    //换算成基础计量单位
    resnum = this.getDecimalsLen(number / curUnitData.exchange_base, 3);

    return resnum;
  },

  /* 转换采购计量单位数量 (number:转换的数量 priceData:多计量数据)*/
  getProductPurchaseNumber(number, priceData) {
    let resnum = number;

    //定义相关数据
    let purchaseData = ""; //采购计量数据

    //循环获取数据
    priceData.forEach((item, index) => {
      if (item.is_purchase_default == 1) {
        purchaseData = item;
      }
    })

    //换算成采购计量单位
    if (purchaseData != '') {
      resnum = this.getDecimalsLen(number / purchaseData.exchange_base, 3);

      //拼接计量单位
      resnum = resnum + purchaseData.calculateName;
    }

    return resnum;
  },

  /* 将指定计量单位换算成采购计量单位 */
  /* number:当前换算的数量   productPriceList:多计量列表数据 curCalculateName:当前计量单位*/
  getPurchaseChangeNum(number, productPriceList,curCalculateName) {
    //定义返回结果
    let resnum = number;

    // 获取当前计量单位
    let curCalculate = null;
    let curCalculateArr = productPriceList.filter(item => item.calculateName == curCalculateName);
    if (curCalculateArr.length > 0) {
      curCalculate = curCalculateArr[0].exchange_base;
    }

    //获取采购计量单位
    let purchaseCalculate = null;
    let purchaseCalculateArr = productPriceList.filter(item => item.is_purchase_default == 1);
    if (purchaseCalculateArr.length > 0) {
      purchaseCalculate = purchaseCalculateArr[0].exchange_base;
    }

    //转换计量单位
    if (curCalculate != null && purchaseCalculate != null) {
      let curcxchaangebase = 1;
      //判断转换关系
      if (curCalculate > purchaseCalculate) { //大转小
        //获取换算计量
        curcxchaangebase = (curCalculate / purchaseCalculate);
      } else if (curCalculate < purchaseCalculate) { //小转大
        //获取换算计量
        curcxchaangebase = (purchaseCalculate / curCalculate);
      }

      //计算数据
      resnum = resnum / curcxchaangebase;

      //处理小数
      resnum = this.getDecimalsLen(resnum,3);
    }

    return resnum;
  },

  /* 将指定单位换算成基础计量单位 */
  getBaseChangeNumber(number, productPriceList,curCalculateName){
    //定义返回结果
    let resnum = number;

    // 获取当前计量单位
    let curCalculate = null;
    let curCalculateArr = productPriceList.filter(item => item.calculateName == curCalculateName);
    if (curCalculateArr.length > 0) {
      curCalculate = curCalculateArr[0].exchange_base;
    }

    //获取基础计量单位
    let baseCalculate = null;
    let baseCalculateArr = productPriceList.filter(item => item.is_base == 1);
    if (baseCalculateArr.length > 0) {
      baseCalculate = baseCalculateArr[0].exchange_base;
    }

    //转换计量单位
    if (curCalculate != null && baseCalculate != null) {
      let curcxchaangebase = 1;
      //获取换算计量
      curcxchaangebase = (curCalculate / baseCalculate);

      //计算数据
      resnum = resnum * curcxchaangebase;

      //处理小数
      resnum = this.getDecimalsLen(resnum,3);
    }

    return resnum;
  },

  /* 将基础计量单位换算成采购计量单位 */
  getPurchaseNumByBase(number, productPriceList){
    //定义返回结果
    let resnum = number;

    //获取采购计量单位
    let purchaseCalculate = null;
    let purchaseCalculateArr = productPriceList.filter(item => item.is_purchase_default == 1);
    if (purchaseCalculateArr.length > 0) {
      purchaseCalculate = purchaseCalculateArr[0].exchange_base;
    }

    if(purchaseCalculate!=null){
      //计算数据
      resnum = resnum / purchaseCalculate;
    }

    //转换小数
    resnum = this.getDecimalsLen(resnum, 3);

    return resnum;
  },
}
